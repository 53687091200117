import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AddEditNotificationSetting from './AddEditNotificationSetting'
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from '../common/FiltersModal'
import ConfirmationModal from '../common/ConfirmationModal'
import ClickOutside from "../common/ClickOutside"
import SimpleButton from '../common/SimpleButton'
import EmptyPageResults from '../common/EmptyPageResults'
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import { toast } from "../common/Toast"
import {useWindowSize} from "../../libs/hooks"
import {
  DisabledIcon,
  EnabledIcon,
  CaretDownIcon,
  MenuKebabVerticalIcon,
  MenuKebabVerticalBackgroundIcon
} from "../../icons"
import {
  loadNotificationSettings,
  loadEventGroups,
  loadNotificationDeliveryMethods,
  partiallyUpdateNotificationSetting,
  removeNotificationSetting,
  clearNotificationsMessages
} from "../../actions/notifications"

const orderingFields = [
  {
    field: "name",
    order: "asc",
    key: "name_asc"
  },
  {
    field: "name",
    order: "desc",
    key: "name_desc"
  },
  {
    field: "type",
    order: "asc",
    key: "type_asc"
  },
  {
    field: "type",
    order: "desc",
    key: "type_desc"
  },
  {
    field: "eventGroup",
    order: "asc",
    key: "eventGroup_asc"
  },
  {
    field: "eventGroup",
    order: "desc",
    key: "eventGroup_desc"
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc"
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc"
  },
]

const NotificationTypes = (props) => {
  const { t } = useTranslation();
  const isMobile = useWindowSize()[0] <= 768
  const statuses = [ "active", "inactive" ]
  const [settingsTableData, setSettingsTableData] = useState([])
  const [selectedNotificationSetting, setSelectedNotificationSetting] = useState(null)
  const [notificationTypes, setNotificationTypes] = useState([])
  const [groupEvents, setGroupEvents] = useState([])
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([])
  const [selectedFilterGroupEvents, setSelectedFilterGroupEvents] = useState([])
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showAddEditNotificationModal, setShowAddEditNotificationModal] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDeleteNotificationConfirmModal, setShowDeleteNotificationConfirmModal] = useState(false)
  const [deviceNotificationIdToDelete, setDeviceNotificationIdToDelete] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedDeviceNotification, setExpandedDeviceNotification] = useState(0)
  const [showActionsMenu, setShowActionsMenu] = useState(null)

  useEffect(() => {
    if (props.eventGroups.length === 0) {
      props.actions.loadEventGroups()
    }
    
    if (props.deliveryMethods.length === 0) {
      props.actions.loadNotificationDeliveryMethods()
    }
    
    if (props.notificationSettings.length === 0) {
      props.actions.loadNotificationSettings()
        .then(() => setIsFirstLoad(false))
    }
  }, [props.actions])

  useEffect(() => {
    setGroupEvents(props.eventGroups)
  }, [props.eventGroups])

  useEffect(() => {
    const tableData = props.notificationSettings?.map((setting, index) => {
      return {
        identifier: setting.id,
        name: setting.name,
        deliveryMethod: setting.deliveryMethod,
        description: setting.description,
        type: t([`filters.${setting.type}`, setting.type]),
        eventSource: t([`events.${getEventSourceById(setting.eventGroupId)}`, getEventSourceById(setting.eventGroupId)]),
        status: setting.isEnabled,
        actions: [
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); handleOpenEditDeviceNotification(setting.id) },
          },
          {
            label: setting.isEnabled ? t("labels.deactivate") : t("labels.activate"),
            onActionClick: (e) => { e.stopPropagation(); handleChangeStatusDeviceNotification(setting.id, setting.isEnabled) },
          },
          {
            label: t("labels.delete"),
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(setting.id) },
            isDisabled: setting.isEnabled,
          },
        ]
      }
    })

    setSettingsTableData(tableData)
  }, [props.notificationSettings, props.eventGroups])


  useEffect(() => {
    setNotificationTypes(props.notificationTypes)
  }, [props.notificationTypes])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearNotificationsMessages()
    }    
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearNotificationsMessages()
    }    
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadDeviceNotifications(false)
    }
  }, [selectedPage])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (!isFirstLoad) {
          setSelectedPage(1)
          handleLoadDeviceNotifications(true)
        }
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, selectedFilterTypes, selectedFilterGroupEvents, selectedFilterStatus, orderBy])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadDeviceNotifications = (withReset) => {
    let queryParams = {
      startPage: withReset ? 1 : selectedPage
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterTypes.length > 0) { 
      queryParams = { ...queryParams, type: selectedFilterTypes}
    }

     if (selectedFilterGroupEvents.length > 0) { 
      const filterGroupEvents = groupEvents.filter((e) => selectedFilterGroupEvents.includes(e.key))
      queryParams = { ...queryParams, eventGroupId: filterGroupEvents?.map((e) => e.id)}
    }

     if (selectedFilterStatus) { 
      queryParams = { ...queryParams, isEnabled: selectedFilterStatus === "active"}
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order}
    }

    props.actions.loadNotificationSettings(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedFilterTypes([])
    setSelectedFilterGroupEvents([])
    setSelectedFilterStatus(null)
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {orderBy ? t(`events.${orderBy.key}`) : t("events.select_ordering")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")} 
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`events.${orderingField.key}`)}
        </div>
      ))
      }
    </div>
  )

  const getEventSourceById = (id) => {
    const eventGroup = groupEvents?.find((eventGroup) => eventGroup.id === id)
    if(!eventGroup) return ""
    return eventGroup?.isDeviceEvent ?  "device" : "event-website"
  }

  const getUnusedGroupEvents = (groupEvents) => {
    const usedGroupEventIds = props.notificationSettings.map(e => e.eventGroupId)
    return groupEvents.filter( e => !usedGroupEventIds.includes(e.id))
  }

  const handleOpenEditDeviceNotification = (deviceNotificationId) => {
    const notification = props.notificationSettings.find((notification) => notification.id === deviceNotificationId)
    
    if (notification) {
      setSelectedNotificationSetting(notification)
      setShowAddEditNotificationModal(true)
    }
  }

  const handleChangeStatusDeviceNotification = (id, isEnabled) => {
    props.actions.partiallyUpdateNotificationSetting(id, { isEnabled :!isEnabled })
  }

  const showConfirmationModal = (id) => {
    setDeviceNotificationIdToDelete(id)
    setShowDeleteNotificationConfirmModal(true)
  }

  const handleDeleteDeviceNotification = () => {
    setShowDeleteNotificationConfirmModal(false)
    props.actions.removeNotificationSetting(deviceNotificationIdToDelete)
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <Fragment>
      <div className="device-notifications-content-wrapper">
        <div className="add-notification-button">
          <SimpleButton
            className="submit-button"
            onClick={() => setShowAddEditNotificationModal(true)}
            disabled={getUnusedGroupEvents(groupEvents)?.length === 0}
          >
            {t('events.add-notification-setting')}
          </SimpleButton>
        </div>
        {!isMobile && settingsTableData.length > 0 &&
          <GenericTable
            className="device-notifications-table"
            data={settingsTableData}
            headers={[
              {
                title: t('events.notification-type')
              },
              {
                title: t('events.description')
              },
              {
                title: t('events.delivery-method')
              },
              {
                title: t('events.event-source')
              },
              {
                title: t('events.status')
              },
              {
                title: ''
              },
            ]}
            keys={[
              'name',
              'description',
              'type',
              'eventSource',
              'status',
              'action',
            ]}
            keyRenderer={{
              status: (item) => {
                return (
                  <div className="status-wrapper">
                    {item.status ?
                      <div className="status-active">
                        <EnabledIcon /> 
                        <p>{t("events.active")}</p>
                      </div>
                    : 
                      <div className="status-inactive">
                        <DisabledIcon /> 
                        <p>{t("events.inactive")}</p>
                      </div>
                    }
                  </div>
                )
              },
              action: (item) => {
                return (
                  <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                    {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === item["identifier"] &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={item.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                )
              }
            }}
            onRowClick={(deviceNotification) => handleOpenEditDeviceNotification(deviceNotification.identifier)}
          />
        }
        {isMobile && settingsTableData?.map((setting, index) =>
          <div 
            key={index}
            className={"card" + (index === 0 ? " with-margin-top" : "")} 
            onClick={() => setExpandedDeviceNotification(index)}
          >
            <div className={"card-item" + (expandedDeviceNotification !== index ? " align-center" : "")}>
              <div className="card-item-title">{t('events.notification-type')}</div>
              <div className={"card-item-body" + (expandedDeviceNotification !== index ? " align-center" : "")}>
                <div>{setting.name}</div>
                <div 
                  className="card-actions"
                  onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                >
                  {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === index &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={setting.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              </div>
            </div>
            {expandedDeviceNotification === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t('events.description')}</div>
                  <div className="card-item-body">{setting.description}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('events.delivery-method')}</div>
                  <div className="card-item-body">{setting.type}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('events.event-source')}</div>
                  <div className="card-item-body">{setting.eventSource}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t('events.status')}</div>
                  <div className="card-item-body">
                    <div className="card-item-body-status">
                      {setting.status ? 
                        <div className="status-active">
                          <EnabledIcon /> 
                          <p>{t("events.active")}</p>
                        </div>
                      : 
                        <div className="status-inactive">
                          <DisabledIcon /> 
                          <p>{t("events.inactive")}</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && settingsTableData.length === 0 && (
          <EmptyPageResults
            className="with-margin-top"
            title={t("events.no-notification-settings-found")}
            subtitle={t("events.no-device-notifications-found-extra")}
          />)
        }
        {/*Pagination functionality remains hidden until further notice */}
        {/* {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t('buttons.load_more')}
            </SimpleButton>
          </div>)
        } */}
      </div>
      {showAddEditNotificationModal && (
        <AddEditNotificationSetting
          groupEvents={groupEvents}
          UnusedGroupEvents={getUnusedGroupEvents(groupEvents)}
          methods={props.deliveryMethods}
          selectedNotificationSetting={selectedNotificationSetting}
          closeModal={() => { 
            setShowAddEditNotificationModal(false); 
            setSelectedNotificationSetting(null) 
          }}
          showSpinner={() => setShowSpinner(true)}
          hideSpinner={() => setShowSpinner(false)}
          isLoading={showSpinner}
        />)
      }
      {showFiltersModal && (
        <FiltersModal 
          filters = {[
            {
              name: t('events.type'),
              type: FilterType.multiSelect,
              dataset: notificationTypes,
              input: selectedFilterTypes,
              output: (filterTypes) => { setSelectedFilterTypes(filterTypes) }
            },
            {
              name: t('events.device-event'),
              type: FilterType.multiSelect,
              dataset: groupEvents?.map((deviceEvent) => deviceEvent.key),
              input: selectedFilterGroupEvents,
              output: (filterGroupEvents) => { setSelectedFilterGroupEvents(filterGroupEvents) }
            },
            {
              name: t('events.status'),
              type: FilterType.singleSelect,
              dataset: statuses,
              input: selectedFilterStatus,
              output: (filterStatus) => { setSelectedFilterStatus(filterStatus) }
            }
          ]}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />)
      }
      {showDeleteNotificationConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteNotificationConfirmModal(false)}
          onAccept={() => handleDeleteDeviceNotification()}
          onCancel={() => setShowDeleteNotificationConfirmModal(false)}
          message={t("events.confirm-delete-notification-setting")}
          acceptButtonText={t("labels.delete")}
          isWarning
        />)
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </Fragment>
  )
}

function stateToProps({ notifications }) {
  return {
    notificationSettings: notifications?.notificationSettings || [],
    isLoading: notifications?.isLoading,
    canLoadMore: notifications?.canLoadMore,
    eventGroups: notifications?.eventGroups || [],
    errorMessage: notifications?.errorMessage,
    successMessage: notifications?.successMessage,
    deliveryMethods: notifications?.deliveryMethods || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadNotificationSettings,
        loadEventGroups,
        loadNotificationDeliveryMethods,
        partiallyUpdateNotificationSetting,
        removeNotificationSetting,
        clearNotificationsMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(NotificationTypes)