import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import PageModal from '../common/PageModal'
import SimpleButton from '../common/SimpleButton'
import './template-name-setup-modal.scss'

const TemplateNameSetupModal = (props) => {
  const { t } = useTranslation()
  const { currentTemplateName, showSpinner, handleChangeTemplateName, closeModal } = props
  const [newTemplateName, setNewTemplateName] = useState(currentTemplateName || "")
  
  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }

  return (
    <div className="template-name-setup-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
      >
        <div className="title">
          {t("template.change_template_name")}
        </div>
        <input 
          type="text" 
          className="template-name-input"
          value={newTemplateName || ""}
          onChange={(e) => setNewTemplateName(e.target.value)}
        />
        <div className="buttons-wrapper">
          <SimpleButton 
            className="cancel-button"
            onClick={() => handleCloseModal()} 
          >
            {t("labels.cancel")}
          </SimpleButton>
          <SimpleButton
            className="submit-button" 
            onClick={() => { handleChangeTemplateName(newTemplateName); handleCloseModal() }}
            disabled={showSpinner || newTemplateName.length === 0 || newTemplateName === currentTemplateName}
          >
            {t("labels.save")}
          </SimpleButton>
        </div>
      </PageModal>
    </div>
  )
}

export default TemplateNameSetupModal
