import { toast as toastify } from 'react-toastify'
import { CheckMarkSimpleIcon, ErrorCircleIcon, InformationIcon } from "../../../icons"
import { useWindowSize } from "../../../libs/hooks"
import './toast.scss'

const getToastIcon = (type) => {
  switch (type) {
    case 'info':
      return <InformationIcon className={`${type}-svg`} />
    case 'success':
      return <CheckMarkSimpleIcon />
    case 'warning':
      return <ErrorCircleIcon className={`${type}-svg`} />
    case 'error':
      return <ErrorCircleIcon className={`${type}-svg`} />
    default:
      return null
  }
}

const getActionButton = (actionText, onAction) => {
  return (actionText && onAction) && (
    <button className="action" onClick={(e) => {e.stopPropagation(); onAction()}}>
      {actionText}
    </button>
  )
}

const Toast = ({ message, type, actionText, onAction }) => {
  return (
    <div className="toast-wrapper">
      <div className={`${type}-status status`}/>
      {getToastIcon(type)}
      <div className="text">
        {message}
        {". "}
        {getActionButton(actionText, onAction)}
      </div>
    </div>
  )
}

const toast = {
  error: (message, options = {}) => {
    toastify.dismiss()
    toastify.error(<Toast message={message} type="error" />, options)
  },

  info: (message, options = {}, actionText, onAction) => {
    toastify.dismiss()
    toastify.info(<Toast message={message} type="info" actionText={actionText} onAction={onAction} />, options)
  },

  success: (message, options = {}) => {
    toastify.dismiss()
    toastify.success(<Toast message={message} type="success" />, options)
  },

  warning: (message, options = {}) => {
    toastify.dismiss()
    toastify.warning(<Toast message={message} type="warning" />, options)
  },

  dismiss: toastify.dismiss,
}

export { toast }