import * as yup from "yup"

/**
 * get user validation schema.
 * @param {object} errorMsg {firstname:'',lastname:'',language:'',country:'',phone:'',email:'',password:'', confirmpassword:'', privacy:''}
 */
const getUserValidationShema = (errorMsg) => {
  return yup.object().shape({
    isUserGroup: yup.boolean(),
    usergroup: yup.string().when("isUserGroup", {
      is: true,
      then: yup.string().required(errorMsg.usergroup),
      otherwise: yup.string(),
    }),
    // .string()
    // .required(errorMsg.usergroup),
    hasCompanyType: yup.boolean(),
    companyType: yup.string().when("hasCompanyType", {
      is: true,
      then: yup.string().required(errorMsg.companyType),
      otherwise: yup.string(),
    }),
    firstname: yup.string().required(errorMsg.firstname),
    lastname: yup.string().required(errorMsg.lastname),
    language: yup.string().required(errorMsg.language),
    country: yup.string().when('fromInvitation', {
      is: false,
      then: yup.string().required(errorMsg.country),
      otherwise: yup.string(),
    }),
    address: yup.string().when('fromInvitation', {
      is: false,
      then: yup.string().required(errorMsg.address),
      otherwise: yup.string(),
    }),
    company: yup.string().when('fromInvitation', {
      is: false,
      then: yup.string().required(errorMsg.company),
      otherwise: yup.string(),
    }),
    phone: yup
      .string()
      .required(errorMsg.phone)
      .matches(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, errorMsg.phone)
      .min(10, errorMsg.phone),
    email: yup.string().email(errorMsg.email).required(errorMsg.email),
    password: yup
      .string()
      .required(errorMsg.password)
      .matches(
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        errorMsg.password
      ),
    confirmpassword: yup
      .string()
      .required(errorMsg.confirmpassword)
      .when("password", {
        is: (password) => (password && password.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("password")], errorMsg.confirmpassword),
      }),
    privacy: yup.bool().oneOf([true], errorMsg.privacy).required(errorMsg.privacy),
  })
  };

/**
 * get user validation schema.
 * @param {object} errorMsg {firstname:'',lastname:'',language:'',country:'',phone:'',email:'',password:'', confirmpassword:'', privacy:''}
 */
const getPasswordValidationSchema = (errorMsg) => {
  return yup.object().shape({
    email: yup.string().email(errorMsg.email).required(errorMsg.email),
    password: yup
      .string()
      .required(errorMsg.password)
      .matches(
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        errorMsg.password
      ),
    newpassword: yup
      .string()
      .required(errorMsg.password)
      .matches(
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        errorMsg.newpassword
      ),
    confirmpassword: yup
      .string()
      .required(errorMsg.confirmpassword)
      .when("newpassword", {
        is: (password) => (password && password.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("newpassword")], errorMsg.confirmpassword),
      }),
  })
}

/**
 * get user validation schema.
 * @param {object} errorMsg {email:''}
 */
const getEmailValidationShema = (errorMsg) => {
  return yup.object().shape({
    email: yup.string().email(errorMsg.email).required(errorMsg.email),
  })
}

/**
 * get password validation schema for password reset.
 * @param {object} errorMsg {password:'', confirmpassword:''}
 */
const getPasswordResetValidationShema = (errorMsg) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(errorMsg.password)
      .matches(
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        errorMsg.password
      ),
    confirmpassword: yup
      .string()
      .required(errorMsg.confirmpassword)
      .when("password", {
        is: (password) => (password && password.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("password")], errorMsg.confirmpassword),
      }),
  })
}

const getDealerSalesInformationValidationSchema = (errorMsg) => {
  return yup.object().shape({
    dealer_email: yup.string().email(errorMsg.dealer_email),
    dealer_phone_1: yup.string().matches(/^$|^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, errorMsg.dealer_phone_1),
    dealer_phone_2: yup.string().matches(/^$|^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, errorMsg.dealer_phone_2),
    sales_rep_email: yup.string().email(errorMsg.sales_rep_email),
    sales_rep_phone_1: yup.string().matches(/^$|^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, errorMsg.sales_rep_phone_1),
    sales_rep_phone_2: yup.string().matches(/^$|^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, errorMsg.sales_rep_phone_2),
  })
}

const getServiceValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().trim().required(),
    policyId: yup.string().required(),
  })
}

const getTestDriveValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().trim().required(),
    finishDate: yup.string().required(),
    startDate: yup.string().required(),
  })
}

const getTrialValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().trim().required(),
    duration: yup.number().integer().min(1).required(),
  })
}

const getDiscountValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().trim().required(),
    discountPercentage: yup.number().integer().required(),
  })
}

const getAccountValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().trim().required(),
  })
}

const getRoleValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().trim().required(),
    account: yup.object().required(),
  })
}

/**
 * validates form and returns an object of errors
 * @param {Schema Object} schema
 * @param {object} formData
 * @returns object
 */
const validateForm = async (schema, formData) => {
  try {
    await schema.validate(formData, { abortEarly: false })
    return {}
  } catch (err) {
    const errors = await processErrorMessage(err)
    return errors
  }
}

/**
 * Process error from ValidationError to a key/value pair
 * @param {ValidationError} e
 * @returns object
 */
const processErrorMessage = async (e) => {
  let err = {}
  const errors = e.inner

  for (let i in errors) {
    err[errors[i].path] = errors[i].message
  }
  return err
}

/**
 *
 * @param {*} errorMsg
 * @returns
 */
const getSerialValidationSchema = (errorMsg) => {
  return yup.string().test("len", errorMsg, (val) => val.length <= 12)
}

const getStoreProductDetailsValidationSchema = () => {
  return yup.object().shape({
    partNumber: yup.string().trim().min(1).max(250).required(),
    title: yup.string().trim().min(1).max(100).required(),
    subtitle: yup.string().trim().min(1).max(100).required(),
    isDigital: yup.bool().required(),
    categoryId: yup.number().integer().required(),
    serviceId: yup.mixed().when("isDigital", {
      is: (isDigital) => isDigital,
      then: yup.number().integer().required(),
      otherwise: yup.object().nullable().notRequired(),
    }),
    planType: yup.mixed().when("isDigital", {
      is: (isDigital) => isDigital,
      then: yup.string().oneOf(['day', 'month', 'year']).required(),
      otherwise: yup.object().nullable().notRequired(),
    }),
  })
}

const getStoreProductImagesValidationSchema = () => {
  return yup.array().of(yup.object().shape({ 
    order: yup.number().integer().min(1).required(), 
    url: yup.string().min(1).max(1000).required() 
  })).optional()
}

const getStoreProductDescriptionValidationSchema = () => {
  const vaadinRichTextHtmlEmptyState = '<p><br></p>'

  return yup.object().shape({
    description: yup.string().trim().max(2500).optional(),
    specification: yup.string().trim().max(2000).optional(),
    features: yup.array().of(
      yup.object().shape({
        order: yup.number().integer().min(1).required(), 
        title: yup.string().min(1).max(255).required(), 
        details: yup.string().notOneOf([vaadinRichTextHtmlEmptyState]).min(1).max(1000).required()
      })).optional(),
    relatedProductIds: yup.array().of(
      yup.number().integer().required()
    ).optional(),
  })
}

const getStoreProductPricesValidationSchema = (isDigital) => {
  return yup.array().of(yup.object().shape({
    amount: yup.number().min(1).required(), 
    currencyCode: yup.string().min(1).max(10).required(),
    countryCode: yup.string().min(1).max(10).required(),
    recurrenceInterval: yup.mixed().when("isDigital", {
      is: (isDigital) => isDigital,
      then: yup.string().oneOf(['day', 'week', 'month', 'year']).required(),
      otherwise: yup.object().nullable().notRequired(),
    }),
    recurrenceIntervalCount: yup.mixed().when("isDigital", {
      is: (isDigital) => isDigital,
      then: yup.number().integer().min(1).required(),
      otherwise: yup.object().nullable().notRequired(),
    }),
    trialBusinessRuleId: yup.number().nullable().min(1).optional()
  })).test("pricesLength", "", (val) => val.length > 0)
}

const alphanumericValidationSchema = () => {
  return yup.string().matches(/^[a-zA-Z0-9]+$/, "error");
} 

export {
  getUserValidationShema,
  getEmailValidationShema,
  getPasswordResetValidationShema,
  getTrialValidationSchema,
  getTestDriveValidationSchema,
  getDiscountValidationSchema,
  getServiceValidationSchema,
  getAccountValidationSchema,
  getRoleValidationSchema,
  validateForm,
  getSerialValidationSchema,
  getPasswordValidationSchema,
  getDealerSalesInformationValidationSchema as getDealerInformationValidationSchema,
  getStoreProductDetailsValidationSchema,
  getStoreProductImagesValidationSchema,
  getStoreProductDescriptionValidationSchema,
  getStoreProductPricesValidationSchema,
  alphanumericValidationSchema
}
