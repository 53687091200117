import { Pages } from "./pages";

export const manageAdminRoutes = [
  Pages.users,
  Pages.accountsManagement,
  Pages.members,
  Pages.companies,
  Pages.policies,
  Pages.events,
  Pages.adminStore,
  Pages.adminOrderHistory,
  Pages.services,
  Pages.businessRules,
  Pages.recycleBin,
  Pages.myColteneTemplates,
]
