import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import Legend from "./Legend"
import Table from "./Table"
import TableMobile from "./TableMobile"
import AddNoteModal from "./AddNoteModal"
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from '../common/SearchWithFilter'
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SimpleButton from "../common/SimpleButton"
import { toast } from "../common/Toast"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { getSterilizationReport, getSterilizationReportsCsv } from "../../utils/requests/usersAPI"
import { hasInstrumentTrackingPermission } from "../../utils/permissionValidation"
import { downloadFile } from '../../utils/functions'
import { useWindowSize } from "../../libs/hooks"
import "./reports.scss";
import { RefreshIcon } from "../../icons"
import ReactTooltip from "react-tooltip"
import { keyboardEvents } from "../../utils/keyboardEvents"

function Sterilization({ userProducts, apertures, ...props }) {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const canAccessInstrumentTracking = hasInstrumentTrackingPermission(props.userPermissions)
  const [reports, setReports] = useState([])
  const [totalReports, setTotalReports] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [modelsWithSerialNumbers, setModelsWithSerialNumbers] = useState([])
  const [selectedFilterStartDate, setSelectedFilterStartDate] = useState(null)
  const [selectedFilterEndDate, setSelectedFilterEndDate] = useState(null)
  const [selectedFilterModelsWithSerialNumbers, setSelectedFilterModelsWithSerialNumbers] = useState([])
  const [addNoteIdentifier, setAddNoteIdentifier] = useState(null)
  const [isTableVisible, setTableVisibility] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (userProducts && Object.keys(userProducts).length > 0) {
      const modelsWithSerialNumbers = Object.values(userProducts)
        .filter((item) => item.model && item.model.trim().length > 0)
        .reduce((acc, item) => {
          if (!acc[item.model]) {
            acc[item.model] = {
              id: item.model,
              name: item.model,
              children: [],
            }
          }

          acc[item.model].children.push({
            id: item.serial_number,
            name: item.serial_number,
          })

          return acc
        }, {})

      const mappedModelsWithSerialNumbers = Object.values(modelsWithSerialNumbers)
        .map((item) => { return { ...item } })

      setModelsWithSerialNumbers(mappedModelsWithSerialNumbers)
    }
  }, [userProducts])

  useEffect(() => {
    handleLoadReports()
  }, [currentPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          if (currentPage === 1 && !isFirstLoad) {
            handleLoadReports()
          } else {
            setCurrentPage(1)
          }
        }

        setShouldApplyFilters(true)
      },
      300
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [selectedFilterStartDate, selectedFilterEndDate, selectedFilterModelsWithSerialNumbers])

  const handleLoadReports = () => {
    setShowSpinner(true)
    const cognitoSub = props.authedUser.cognitoSub;

    let reportQueryParams = {
      page: currentPage,
      limit: 20,
    }

    if (selectedFilterStartDate) {
      reportQueryParams = { ...reportQueryParams, start_date: convertDate(selectedFilterStartDate) }
    }

    if (selectedFilterEndDate) {
      reportQueryParams = { ...reportQueryParams, end_date: convertDate(selectedFilterEndDate) }
    }

    if (selectedFilterModelsWithSerialNumbers.length > 0) {
      const models = []
      const serialNumbers = []
      for (let idx = 0; idx < selectedFilterModelsWithSerialNumbers.length; idx++) {
        models.push(selectedFilterModelsWithSerialNumbers[idx].id)

        const currentModelSerialNumbers = selectedFilterModelsWithSerialNumbers[idx].children?.map((serialNumber) => serialNumber) ?? []
        serialNumbers.push(...currentModelSerialNumbers)
      }

      reportQueryParams = { ...reportQueryParams, model: models, serialNumber: serialNumbers }
    }

    getSterilizationReport(cognitoSub, reportQueryParams)
      .then((result) => {
        setReports(result.data || []);
        setTotalReports(result.totalResults);

        setShowSpinner(false);
        setTableVisibility(true);
        setIsFirstLoad(false);
      })
      .catch(() => {
        setShowSpinner(false);
        setIsFirstLoad(false)
      })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleResetFilters = () => {
    setSelectedFilterModelsWithSerialNumbers([])
    setSelectedFilterStartDate(null)
    setSelectedFilterEndDate(null)
  }

  const convertDate = (date) => {
    return date?.split("T")[0]?.replace(/-/g, "") || ""
  }

  const countSelectedModelsWithSerialNumbers = () => {
    let count = 0

    for (let idx = 0; idx < selectedFilterModelsWithSerialNumbers.length; idx++) {
      count += selectedFilterModelsWithSerialNumbers[idx].children?.length + 1 ?? 1
    }

    return count
  }

  const handleOpenAddNote = (serialNumber, cycleFault, isG4Plus, fileName, date, id, modelId, pedSource) => {
    setAddNoteIdentifier({
      serialNumber,
      cycleFault,
      isG4Plus,
      fileName,
      date,
      pedSource,
      modelId,
      id
    })
  }

  const handleSucessfullAddNote = (identifier, note) => {
    const updatedReports = reports.map((report) => {
      if (report.id === identifier?.id) {
        return { ...report, notes: [ ...report.notes, note] }
      }

      return report
    })

    setReports(updatedReports)
  }

  const handleDownloadReports = () => {
    let queryParams = {
      limit: 1000,
      page: 1,
    }

    if (selectedFilterStartDate) {
      queryParams = { ...queryParams, start_date: convertDate(selectedFilterStartDate) }
    }

    if (selectedFilterEndDate) {
      queryParams = { ...queryParams, end_date: convertDate(selectedFilterEndDate) }
    }

    if (selectedFilterModelsWithSerialNumbers.length > 0) {
      const models = []
      const serialNumbers = []
      for (let idx = 0; idx < selectedFilterModelsWithSerialNumbers.length; idx++) {
        models.push(selectedFilterModelsWithSerialNumbers[idx].id)

        const currentModelSerialNumbers = selectedFilterModelsWithSerialNumbers[idx].children?.map((serialNumber) => serialNumber) ?? []
        serialNumbers.push(...currentModelSerialNumbers)
      }

      queryParams = { ...queryParams, model: models, serialNumber: serialNumbers }
    }

    setShowSpinner(true)

    getSterilizationReportsCsv(props.authedUser?.cognitoSub, queryParams)
      .then((res) => {
        const date = new Date().toISOString().split('T')
        const dateNow = date[0].split('-').reverse().join('_')
        const timeNow = date[1].split('.')[0].split(':').join('_')
        
        downloadFile({
          data: res,
          fileName: `sterilization-reports_${dateNow}_${timeNow}.csv`,
          fileType: 'text/csv',
        })

        setShowSpinner(false)

        toast.success(t("reports.download_success"))
      })
      .catch(() => {
        setShowSpinner(false)

        toast.error(t("error.failure_msg"))
      })
  }

  return (
    <div className="reports-wrapper">
      <div className="actions-wrapper">
        <SearchWithFilter
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterStartDate || selectedFilterEndDate || selectedFilterModelsWithSerialNumbers.length > 0}
          counterBadge={countSelectedModelsWithSerialNumbers() + (selectedFilterStartDate || selectedFilterEndDate ? 1 : 0 )}
        />
        <div className="buttons-wrapper">
          <div 
            tabIndex={0}
            role="button"
            className="refresh-icon"
            onClick={handleLoadReports}
            onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleLoadReports()}
          >
            <RefreshIcon  data-tip={t("enhanced_event_log.refresh")} />
            <ReactTooltip place="top" type="dark" effect="solid" />
          </div>
          <SimpleButton
            className="action-button"
            onClick={() => handleDownloadReports()}
            disabled={showSpinner}
          >
            {t("download.download")}
          </SimpleButton>
        </div>
      </div>

      {isTableVisible && (
        <>
          {reports?.length > 0 && 
            <Legend/>
          }
          {!isMobile ? 
            <Table 
              reports={reports}
              userProducts={userProducts}
              totalReports={totalReports}
              currentPage={currentPage}
              canAccessInstrumentTracking={canAccessInstrumentTracking}
              onChangeCurrentPage={handlePageChange}
              handleOpenAddNote={handleOpenAddNote}
              setShowSpinner={setShowSpinner}
              isLoading={showSpinner}
            />
          :
            <TableMobile
              reports={reports}
              userProducts={userProducts}
              totalReports={totalReports}
              currentPage={currentPage}
              canAccessInstrumentTracking={canAccessInstrumentTracking}
              onChangeCurrentPage={handlePageChange}
              handleOpenAddNote={handleOpenAddNote}
              setShowSpinner={setShowSpinner}
              isLoading={showSpinner}
            />
          }
        </>
      )}
      {addNoteIdentifier &&
        <AddNoteModal
          userUuid={props.authedUser.cognitoSub}
          identifier={addNoteIdentifier}
          onSuccesfullAddNote={handleSucessfullAddNote}
          closeModal={() => setAddNoteIdentifier(null)}
          setShowSpinner={setShowSpinner}
        />
      }
      {showFiltersModal &&
        <FiltersModal
          filters={[
            {
              name: t("reports.date"),
              type: FilterType.dateRange,
              dataset: null,
              input: { startDate: selectedFilterStartDate, endDate: selectedFilterEndDate },
              output: (startDate, endDate) => {
                  setSelectedFilterStartDate(startDate); 
                  setSelectedFilterEndDate(endDate) 
                },
              withStartDateOnly: true,
              hasMinDate: true
            },
            {
              name: t("reports.model_and_serial_number"),
              type: FilterType.multiSelectWithChildren,
              dataset: modelsWithSerialNumbers,
              input: selectedFilterModelsWithSerialNumbers,
              output: (filterModelsWithSerialNumbers) => {
                setSelectedFilterModelsWithSerialNumbers(filterModelsWithSerialNumbers)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </div>
  );
}

function mapStateToProps({ authedUser, userProducts, apertures, userPermissions }) {
  return {
    authedUser,
    userProducts: userProducts?.products || {},
    apertures: apertures?.apertures || [],
    userPermissions
  };
}

export default connect(mapStateToProps)(Sterilization);
