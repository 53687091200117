export function compareVersions(versionA, versionB) {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) {
      continue;
    }

    if (a > b || isNaN(b)) {
      return 1;
    }

    return -1;
  }

  return 0;
}