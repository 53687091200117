import React, { useEffect, useState, useLayoutEffect } from 'react'
import {useTranslation} from "react-i18next"
import { useHistory, useLocation } from 'react-router-dom'
import {connect} from "react-redux"
import { bindActionCreators } from "redux"
import { Storage } from "aws-amplify"
import ProductDetailsModal from './ProductDetailsModel'
import ProductActivationStepsModal from './ProductActivationStepsModal'
import NicknameSetupModal from './NicknameSetupModal'
import NotificaitonSettings from "../NotificationSettings"
import NotificationSettings from '../MyAccount/Notifications/NotificationSettings'
import Legend from "../Dashboard/Legend"
import Modal from "../common/Modal"
import Search from "../common/Search"
import ClickOutside from "../common/ClickOutside"
import Breadcrumb from "../common/Breadcrumb"
import LoadingSpinner from "../common/LoadingSpinner"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import SimpleButton from "../common/SimpleButton"
import DataPlaceholder from "../common/DataPlaceholder"
import EmptyPageResults from "../common/EmptyPageResults"
import VideoModal from "../common/VideoModal"
import TooltipedIcon from "../common/TooltipedIcon"
import CycleDetailsModal from "../common/CycleDetailsModal"
import ConfirmationModal from "../common/ConfirmationModal"
import { toast } from "../common/Toast"
import RegisterProductModern from "../RegisterProductModern"
import Documents from "../Documents"
import Videos from "../Videos"
import OnlineAccess from "../OnlineAccess";
import CycleModal from '../InstrumentTracking/CycleModal'
import { useWindowSize } from "../../libs/hooks"
import { ApertureIcon, CaretDownIcon, UpdateIcon, DownloadModernIcon, PdfModernIcon, VerifiedIcon, VideoPlayIcon, CheckDoubleIcon, WasherSmallIcon, SterilizerSmallIcon, InformationIcon } from "../../icons"
import { getImageByModel } from '../../utils/functions'
import { getDocuments } from "../../utils/requests/documentsApi"
import { getVideosList } from "../../utils/requests/videosAPI"
import { getEndUserProductSummary } from "../../utils/requests/productsAPI"
import { getLatestProductCycles, getProductCycleDetails, getProductCyclePrintout } from "../../utils/requests/productsAPI"
import { getCycleDetails, getMQTTCycles, getTrackingDta } from '../../utils/requests/trackingAPI'
import { putApertureNickname, updateCustomFields } from '../../utils/requests/usersAPI'
import { combineUserProductsAndApertures } from '../../utils/aperture'
import { publishApertureNicknameUpdate } from '../../utils/requests/mqttPublishAPI'
import { handleSetUserProducts } from "../../actions/userProducts"
import { convertToSimpleDateWithFullTime, formatDate, formatTime, formatVal } from "../../utils/filters/date"
import { useAppContext } from '../../libs/contextLib'
import { handleSetApertures } from '../../actions/aperture'
import { setBackNavigationState } from '../../actions/backNavigationState'
import { checkPermission, deviceNotificationsReadPermissions, hasInstrumentTrackingPermission, userNotificationWritePermission, userProductsWritePermission } from '../../utils/permissionValidation'
import './my-products.scss'
import { keyboardEvents } from '../../utils/keyboardEvents'

const MyProducts = (props) => {
  const {t} = useTranslation()
  const { dispatch } = props
  const history = useHistory()
  const location = useLocation()
  const isMobile = useWindowSize()[0] <= 768
  const { showFeatureDev, supportsColteneStore } = useAppContext()
  const canAccessInstrumentTracking = hasInstrumentTrackingPermission(props.userPermissions)
  const model = (location.state && location.state?.model) || props.backNavigationState?.model || window.history.state?.model
  const serialNumber = (location.state && location.state?.serialNumber) || props.backNavigationState?.serialNumber || window.history.state?.serialNumber
  const [products, setProducts] = useState([])
  const [rawSerials, setRawSerials] = useState([])
  const [serials, setSerials] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedSerial, setSelectedSerial] = useState(null)
  const [combinedProductsAndApertures, setCombinedProductsAndApertures] = useState({})
  const [lastCycles, setLastCycles] = useState([])
  const [documents, setDocuments] = useState([])
  const [videos, setVideos] = useState([])
  const [relatedProducts, setRelatedProducts] = useState([])
  const [productSerialNickname, setProductSerialNickname] = useState(null)
  const [hasOnlineAccess, setHasOnlineAccess] = useState(false)
  const [hasPrivacyPolicyChecked, setHasPrivacyPolicyChecked] = useState(false)
  const [hasUpdateAvailable, setHasUpdateAvailable] = useState(false)
  const [productIsAperture, setProductIsAperture] = useState(false)
  const [cycleDetails, setCycleDetails] = useState({})
  const [cycleDetailsDeviceData, setCycleDetailsDeviceData] = useState({})
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isBravoProduct, setIsBravoProduct] = useState(false)
  const [isICTrackProduct, setIsICTrackProduct] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showCycleDetails, setShowCycleDetails] = useState(false)
  const [showRegisterProduct, setShowRegisterProduct] = useState(false)
  const [showVideosModal, setShowVideosModal] = useState(false)
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [showProductActivationStepsModal, setShowProductActivationStepsModal] = useState(false)
  const [showNicknameSetupModal, setShowNicknameSetupModal] = useState(false)
  const [showDeleteNicknameModal, setShowDeleteNicknameModal] = useState(false)
  const [cyclesLoading, setCyclesLoading] = useState(true)
  const [lastCycleDate, setLastCycleDate] = useState(null)
  const [modelId, setModelId] = useState(null)
  const [showLimitedLikedDevice, setShowLimitedLikedDevice] = useState(true)
  const [showBravoOnlineAccess, setShowBravoOnlineAccess] = useState(false)
  const [showInstrumentTrackingDetails, setShowInstrumentTrackingDetails] = useState(false)
  const cyclesLimit = process.env.REACT_APP_CYCLES_LIMIT || 10
  const documentsLimit = process.env.REACT_APP_DOCUMENTS_LIMIT || 4
  const videosLimit = process.env.REACT_APP_VIDEOS_LIMIT || 3
  const linkedDevicesLimit = 4
  const isG4PlusAPIEnabled = process.env.REACT_APP_G4PLUS_API_TOGGLE === "on"
  
  useLayoutEffect(() => {
    dispatch(handleSetUserProducts({cognitoSub: props.authedUser?.cognitoSub}))

    if (canAccessInstrumentTracking) {
      dispatch(handleSetApertures(props.authedUser.cognitoSub))
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      window.history.replaceState({
        model: selectedProduct?.model,
        serialNumber: selectedSerial?.serial_number
      }, '')
    })

    return () => {
      window.removeEventListener('beforeunload', () => {})
    }
  }, [selectedProduct?.model, selectedSerial?.serial_number])

  useEffect(() => {
    if(props.userProducts) {
      setShowSpinner(true)

      const groupedProducts = Object.values(props.userProducts)?.reduce((acc, userProduct) => {
        const model = userProduct.model
        const hasUpdateAvailable = userProduct.update_available && showFeatureDev

        const modelIndex = acc.findIndex((item) => item.model === model)
        const modelFound = modelIndex !== -1

        if(!modelFound) {
          acc.push ({
            model,
            products: [ userProduct ],
            hasUpdateAvailable
          })
        } else {
          acc[modelIndex].products.push(userProduct)
          acc[modelIndex].hasUpdateAvailable = acc[modelIndex].hasUpdateAvailable || hasUpdateAvailable
        }

        return acc
      }, [])

      groupedProducts?.sort((a,b) => { 
        return a.model?.toLowerCase()?.localeCompare(b.model?.toLowerCase())
      })

      const userProductsCombinedWithApertures = combineUserProductsAndApertures(props.userPermissions, props.userProducts, props.apertures, t)
      setCombinedProductsAndApertures(userProductsCombinedWithApertures)

      const userProductsSerialNumbers = Object.values(props.userProducts)?.map((userProduct) => userProduct.serial_number)

      const groupedAperturesByType = props.apertures?.reduce((acc, aperture) => {
        if (aperture.devices?.length > 0) {
          const typeIndex = acc.findIndex((item) => item.type === aperture.type)
          const typeFound = typeIndex !== -1

          if(!typeFound) {
            acc.push ({
              type: aperture.type,
              devices: [ ...aperture.devices ],
            })
          } else {
            acc[typeIndex].devices.push.apply(acc[typeIndex].devices, aperture.devices)
          }
        }

        return acc
      }, [])

      const aperturesAsProducts = groupedAperturesByType.map((aperture) => {
        return {
          model: aperture.type,
          products: aperture.devices.map((device) => {
            return {
              serial_number: device.serial_number,
              model_id: device.model_id,
              unit_nickname: device.nickname,
              isSciCanProduct: userProductsSerialNumbers.includes(device.serial_number)
            }
          }),
          hasUpdateAvailable: false,
          isICTrackProduct: true
        }
      })

      if (canAccessInstrumentTracking) {
        groupedProducts.push(...aperturesAsProducts)
      }

      setProducts(groupedProducts)
      setShowSpinner(false)

      if (model || selectedProduct) {
        const preselectedProduct = groupedProducts.find((product) => product.model === model || product.model === selectedProduct?.model)
        
        if (preselectedProduct) {
          setSelectedProduct(preselectedProduct)

          if (!preselectedProduct.isICTrackProduct) {
            setSerials(preselectedProduct?.products)
            setRawSerials(preselectedProduct?.products)

            if (serialNumber || selectedSerial) {
              const preselectedSerial = preselectedProduct.products?.find((product) => product.serial_number === serialNumber || product.serial_number === selectedSerial?.serial_number)
              setSelectedSerial(preselectedSerial)
            }
          }

          setIsICTrackProduct(preselectedProduct.isICTrackProduct)

          window.history.replaceState({}, '')
        }
      }
    }    
  }, [props.userProducts, model, serialNumber, props.apertures])

  useEffect(() => {
    async function fetchData() {
      if (selectedProduct?.model) {
        setShowSpinner(true)
  
        setIsBravoProduct(selectedProduct.model?.toLowerCase().includes("bravo"))
  
        const documentsQueryParams = {
          page: 1,
          model: selectedProduct.model,
          exclude: "category:technical service bulletin"
        }
  
        const videosQueryParams = {
          page: 1,
          model: selectedProduct.model,
          limit: videosLimit
        }
  
        let instrumentTrackingQueryParams = {}
  
        if (selectedProduct.isICTrackProduct) {
          const todayTime = new Date();
          const today = new Date(new Date(todayTime).setHours(23, 59, 59));
          let startDate = new Date(today)
          startDate = new Date(startDate.setDate(today.getDate() - 6))
  
          instrumentTrackingQueryParams = {
            cycleStart: `${startDate.getFullYear()}${formatVal(startDate.getMonth() +1)}${formatVal(startDate.getDate())}`,
            cycleEnd: `${today.getFullYear()}${formatVal(today.getMonth() + 1)}${formatVal(today.getDate())}`
          }
        }
  
        const [documentsData, videosData, instrumentTrackingCyclesData] = await Promise.all([
          getDocuments(documentsQueryParams, documentsLimit), 
          getVideosList(videosQueryParams),
          ...(selectedProduct.isICTrackProduct ? [getTrackingDta(instrumentTrackingQueryParams)] : [])
        ])
        
        if (documentsData?.data) {
          setDocuments(documentsData.data)
        }
  
        if (videosData?.data) {
          setVideos(videosData.data)
        }
  
        if (instrumentTrackingCyclesData?.length > 0) {
          mapInstrumentTrackingCycles(instrumentTrackingCyclesData)
        }
  
        setShowSpinner(false)
      }
    }
    fetchData()
  }, [selectedProduct?.model])

  useEffect(() => {
    if (selectedSerial?.serial_number) {
      setShowSpinner(true)

      let currentHasOnlineAccess = selectedSerial.association_active
      let currentProductSerialNickname = selectedSerial.unit_nickname
      let currentProductIsAperture = combinedProductsAndApertures[selectedSerial.serial_number]?.isAperture
      let currentHasUpdateAvailable = selectedSerial.update_available

      getEndUserProductSummary(props.authedUser?.cognitoSub, selectedSerial.serial_number, {
        model_id: selectedSerial.model_id ?? ""
      })
        .then((response) => {
          const data = response.data

          currentHasOnlineAccess = data.association_active === 1
          currentProductSerialNickname = data.product_nickname
          currentProductIsAperture = data.isAperture
          currentHasUpdateAvailable = data.update_available
          setHasPrivacyPolicyChecked(data.privace_policy_checked === 1)
          data.model_id && setModelId(data.model_id)
          
          if (showFeatureDev && selectedSerial.is_g4_plus && isG4PlusAPIEnabled) {
            getMQTTCycles(selectedSerial.serial_number)
              .then((cycles) => {
                let eventData = []
                if(cycles.length > 0) {
                  eventData = cycles.sort((a, b) => new Date(b.cycleStartDateTime) - new Date(a.cycleStartDateTime) || b.cycleNumber - a.cycleNumber).slice(0, cyclesLimit).map((cycle) => {
                    return {
                      title: cycle.cycleFault ? `C# ${cycle.cycleNumber} CF-${cycle.cycleFault}` : `C# ${cycle.cycleNumber}`,
                      className: cycle.cycleEvent
                        ? cycle.cycleEvent?.toLowerCase().replace(/_/g, "-")
                        : "cycle-pending",
                      start: convertToSimpleDateWithFullTime(cycle.cycleStartDateTime),
                      end: convertToSimpleDateWithFullTime(cycle.cycleEndDateTime),
                      cycleDate: cycle.cycleStartDateTime,
                      serial_number: cycle.deviceSerialNumber,
                      cycle_number: cycle.cycleNumber,
                      cycle_fault: cycle.cycleFault,
                      recordsUuid: cycle.recordsUuid,
                    }
                  })
                }

                setLastCycleDate(eventData[0]?.start)
                setLastCycles(eventData)

                setCyclesLoading(false)
                setShowSpinner(false)
              })
              .catch((e) => {
                console.log("something went wrong", e.message)
                setCyclesLoading(false)
                setShowSpinner(false)
              })
          } else {
            getLatestProductCycles(props.authedUser?.cognitoSub, selectedSerial.serial_number, {
              limit: cyclesLimit,
            })
              .then((cycles) => {
                let eventData = []
                if(cycles.data) {
                  eventData = cycles.data.sort((a, b) => new Date(b.start) - new Date(a.start) || b.cycle_number - a.cycle_number).map((cycle) => {
                    return {
                      title: cycle.cycle_fault ? `C# ${cycle.cycle_number} CF-${cycle.cycle_fault}` : `C# ${cycle.cycle_number}`,
                      className: cycle.cycle_event ? cycle.cycle_event.toLowerCase().replace(/_/g, "-") : "cycle-pending",
                      cycleDate: cycle.start,
                      ...cycle,
                    }
                  })
                }

                setLastCycleDate(eventData[0]?.start)
                setLastCycles(eventData)
                
                setShowSpinner(false)
                setCyclesLoading(false)
              })
              .catch((e) => {
                console.log("something went wrong", e.message)
                setCyclesLoading(false)
                setShowSpinner(false)
              })
          }
        })
        .catch(() => {
          setShowSpinner(false)
          setCyclesLoading(false)
        })
        .finally(() => {
          setHasOnlineAccess(currentHasOnlineAccess)
          setProductSerialNickname(currentProductSerialNickname)
          setProductIsAperture(currentProductIsAperture)
          setHasUpdateAvailable(currentHasUpdateAvailable)
        })
    }
  }, [selectedSerial?.serial_number])

  const searchSerial = (value) => {
    if (value) {
      const filteredSerials = rawSerials.filter(serial => serial.serial_number?.toLowerCase()?.includes(value.toLowerCase()) || serial.unit_nickname?.toLowerCase()?.includes(value.toLowerCase()))
      setSerials(filteredSerials)
    } else {
      setSerials(rawSerials)
    }
  }

  const selectProduct = (product) => {
    if (product === selectedProduct) {
      return 
    }

    scrollToTop()

    setDocuments([])
    setIsBravoProduct(false)
    setVideos([])
    
    setIsICTrackProduct(product.isICTrackProduct)

    setSelectedProduct(product)  
    
    setSerials(product.products)
    setRawSerials(product.products)
    setSelectedSerial(null)

    setProductSerialNickname(null)
    setHasPrivacyPolicyChecked(false)
    setHasOnlineAccess(false)
    setShowLimitedLikedDevice(true)
    setLastCycles([])
    setCyclesLoading(true)
  }

  const selectSerial = (serial, toggleVisibility) => {
    setSerials(rawSerials)

    toggleVisibility(false)

    if (serial === selectedSerial) {
      return
    }

    setProductSerialNickname(null)
    setHasPrivacyPolicyChecked(false)
    setHasOnlineAccess(false)
    setLastCycles([])
    setCyclesLoading(true)
    
    setSelectedSerial(serial)
  }

  const onSelectSerialInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      setSerials(rawSerials)
    } else {
      setSerials(rawSerials)
      toggleVisibility(true)
    }
  }

  const selectSerialInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectSerialInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedSerial ? (selectedSerial.unit_nickname ? selectedSerial.unit_nickname : selectedSerial.serial_number) : t('products.no_product_selected')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectSerialOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchSerial(e)}
          placeholder={t('placeholders.search-filters')}
        />
      </div>
      {serials?.map((serial, index) => (
        <div 
          className={"option cursor-pointer no-wrap option-with-icon" + (serial === selectedSerial ? " selected-option" : "")} 
          key={index}
          onClick={() => selectSerial(serial, toggleVisibility)}
        >
          {serial.unit_nickname ? serial.unit_nickname : serial.serial_number}
          <div className="online-access">
            {serial.association_active ? <VerifiedIcon /> : <VerifiedIcon className="no-access" />}
          </div>
        </div>
      ))
      }
    </div>
  )

  const scrollToTop = () => {
    document.getElementById('page-content').scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const navigateToProduct = (serialNumber) => {
    const scicanProduct = products.find((product) => !product.isICTrackProduct && product.products?.find((product) => product.serial_number === serialNumber))
    
    if (scicanProduct) {
      setIsICTrackProduct(false)
      setSelectedProduct(scicanProduct)

      setSerials(scicanProduct.products)
      setRawSerials(scicanProduct.products)

      const sicanSerial = scicanProduct.products.find((product) => product.serial_number === serialNumber)
      setSelectedSerial(sicanSerial)
      setLastCycles([])
    }
  }

  const navigateToProductPage = (serialNumber) => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      serialNumber: selectedSerial?.serial_number || serialNumber,
      model: selectedProduct.model
    })

    history.push({
      pathname: `/products/${selectedSerial?.serial_number || serialNumber}`,
      state: {
        lastCycleDate: lastCycleDate,
        is_g4_plus: selectedSerial.is_g4_plus,
        withBackNavigation: true
      }
    })
  }

  const navigateToInstrumentTracking = () => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      model: selectedProduct.model
    })

    history.push({
      pathname: `/instrument-tracking`,
      state: {
        withBackNavigation: true
      }
    })
  }

  const navigateToInstrumentTrackingReports = () => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      model: selectedProduct.model
    })

    history.push({
      pathname: `/reports`,
      state: {
        withBackNavigation: true
      }
    })
  }

  const navigateToColteneStore = () => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      model: selectedProduct.model
    })

    history.push({
      pathname: `/coltene-store`,
      state: {
        withBackNavigation: true
      }
    })
  }

  const cycleNavigateToTroubleshooting = () => {
    props.actions.setBackNavigationState({
      backNavPage: location.pathname,
      serialNumber: selectedSerial?.serial_number,
      model: selectedProduct.model
    })
  }

  const mapInstrumentTrackingCycles = (instrumentTrackingCyclesData) => {
    const getCycleStatus = (status) => {
      if (status?.toLowerCase().includes('complete')) {
        return 'cycle-tracking-completed'
      } else if ((status?.toLowerCase().includes('fault') || status?.toLowerCase() === 'cycle_aborted' || status?.toLowerCase() === 'door_open')) {
        return 'cycle-tracking-error'
      } else if (status?.toLowerCase() === 'cycle_power_interrupt') {
        return 'cycle-tracking-interrupted'
      } else {
        return 'cycle-tracking-in-progress'
      }
    }

    const specificInstrumentTrackingCycles = instrumentTrackingCyclesData.filter((cycle) => cycle.deviceType === selectedProduct.model)

    let eventData = []
    if(specificInstrumentTrackingCycles.length > 0) {
      eventData = specificInstrumentTrackingCycles
        .sort((a, b) => new Date(b.cycleStartDateTime) - new Date(a.cycleStartDateTime) || b.cycleNumber - a.cycleNumber)
        .slice(0, cyclesLimit)
        .map((cycle) => {
          return {
            title: cycle.cycleNumber ? `C# ${cycle.cycleNumber}` : "",
            className: getCycleStatus(cycle.status),
            type: cycle.deviceType,
            start: cycle.cycleStartDateTime,
            end: cycle.cycleEndDateTime,
            cycleDate: cycle.cycleStartDateTime,
            serial_number: cycle.deviceSerialNumber,
            cycle_number: cycle.cycleNumber,
            cycle_fault: cycle.cycleFault,
            recordsUuid: cycle.recordsUuid,
            printoutFileName: cycle.printout_file_name,
            nickName: cycle.deviceNickname,
            ...cycle,
          }
        })
    }

    setLastCycles(eventData)
  }

  const handleOpenCycleDetails = (cycle) => {
    if (isICTrackProduct) {
      setCycleDetailsDeviceData(cycle)
    } else {
      setCycleDetailsDeviceData({
        model: selectedProduct.model,
        cycleNumber: cycle.cycle_number,
        cycleStartTime: cycle.cycleDate,
        deviceSerialNumber: cycle.serial_number,
        cycleFault: cycle.cycle_fault,
      })
    }

    const userProduct = props.userProducts[cycle.serial_number]

    if (isICTrackProduct && (!cycle.serial_number || !userProduct)) {
      setShowInstrumentTrackingDetails(true)
      return
    }

    let fetchCycleDetailsPromise

    if (showFeatureDev && userProduct.is_g4_plus && isG4PlusAPIEnabled) {
      fetchCycleDetailsPromise = getCycleDetails(cycle.recordsUuid, cycle.serial_number)
    } else if (cycle.printoutFileName) {
      fetchCycleDetailsPromise = getProductCyclePrintout(cycle.printoutFileName)
    } else if (cycle.cid) {
      fetchCycleDetailsPromise = getProductCycleDetails(cycle.serial_number, cycle.cid, { model_id: selectedSerial?.model_id || modelId })
    } else {
      if (isICTrackProduct) {
        setShowInstrumentTrackingDetails(true)
      }

      return
    }

    setShowSpinner(true)

    fetchCycleDetailsPromise
      .then((res) => {
        setShowSpinner(false)

        setCycleDetails(res.data || res)

        if (isICTrackProduct) {
          setShowInstrumentTrackingDetails(true)
          return
        }

        setShowCycleDetails(true)
      })
      .catch((error) => {
        setShowSpinner(false)

        toast.error(t([`error.${error?.response?.data?.code}`, "error.something_wrong"]))

        if (isICTrackProduct) {
          setShowInstrumentTrackingDetails(true)
          return
        }

        setShowCycleDetails(true)
      })
  }

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }

  const openVideoModal = (item) => {
    setSelectedVideo(item)
  }

  const closeVideoModal = () => {
    setSelectedVideo(null)
  }

  const handleChangeNickname = (newProductSerialNickname) => {
    setShowSpinner(true)

    const date = formatDate(new Date())
    const time = formatTime(new Date())
    
    const params = {
      "nickname": newProductSerialNickname,
      "nickname_last_updated": `${date} ${time}`,
    }

    updateCustomFields(props.authedUser?.cognitoSub, selectedSerial.serial_number, params)
      .then(() => {
        setProductSerialNickname(newProductSerialNickname)

        dispatch(handleSetUserProducts({cognitoSub: props.authedUser.cognitoSub}))
        if (canAccessInstrumentTracking) {
          dispatch(handleSetApertures(props.authedUser.cognitoSub))
        }

        toast.success(t("product.updated"))
        setShowSpinner(false)
      })
      .catch(() => {
        toast.error(t("error.failure_msg"))
        setShowSpinner(false)
      })
    
    if(canAccessInstrumentTracking && productIsAperture) {
      putApertureNickname(props.authedUser?.cognitoSub, combinedProductsAndApertures[selectedSerial.serial_number].aperture_id, combinedProductsAndApertures[selectedSerial.serial_number].device_uuid, {
        "device_nickname": newProductSerialNickname
      }).then(() => {
        const units = Object.values(combinedProductsAndApertures).map((value) => {
          const unit = {
            serial_number: value?.serial_number,
            device_nickname: value?.serial_number === selectedSerial.serial_number ? newProductSerialNickname : value?.unit_nickname,
            device_model: value?.model,
          }

          if (value?.isAperture) {
            unit.device_uuid = value?.device_uuid
            unit.manufacturer = value?.manufacturer
            unit.aperture_serial_number = value?.aperture_serial_number
          } else {
            unit.online_access = value?.association_active ? 1 : 0
          }

          return unit
        })

        const payload = {
          "accounts": [
            {
              "account_state": "associated",
              "account_state_time_modified_utc_seconds": new Date(`${date} ${time}`).getTime(),
              "account_email": props.authedUser?.email,
              "account_company_name": props.authedUser?.company,
              "account_contact_name": props.authedUser?.firstname + " " + props.authedUser?.lastname,
              "account_phone_number": props.authedUser?.phone,
              "account_address": props.authedUser?.postalcode,
              "account_city": props.authedUser?.city,
              "account_subregion": props.authedUser?.stateprovince,
              "account_country": props.authedUser?.country,
              "account_zip_code": props.authedUser?.postalcode,
              "units": units,
            }
          ]
        }

        publishApertureNicknameUpdate(combinedProductsAndApertures[selectedSerial.serial_number].aperture_serial_number, payload)
      })
    }
  }

  const buildUserFriendlyDate = (date) => {
    if (date) {
      return new Date(date).toLocaleString("en-US", { day: "2-digit", month: "short", year: "numeric" })
    }

    return ""
  }
  const mapSerialToModel = (serialNumber) => {
    const matchedProduct = products.find(({products: subProducts}) => {
      return subProducts.find(p => p.serial_number === serialNumber)
    })
    
    return matchedProduct?.model || "aperture"
  }

  const onSuccessfulOnlineActivation = () => {
    dispatch(handleSetUserProducts({cognitoSub: props.authedUser?.cognitoSub}))
  }
  
  return (
    <div className="my-products-wrapper d-flex flex-column">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.my-products")}>
        <SimpleButton className="submit-button" onClick={() => setShowRegisterProduct(true)}>
          {t("product.register_product")}
        </SimpleButton>
      </DashboardHeader>
      <div className="separator" />
      <Legend canAccessInstrumentTracking={canAccessInstrumentTracking} isDentist />
      {!isMobile ?
        <div className="content">
          {products?.length > 0 ?
            <>
              <div className="products">
                {products.map((item, index) =>
                  <div
                    key={index}
                    className={"card d-flex flex-align-center" + (selectedProduct === item ? " selected-card" : "")}
                    onClick={() => selectProduct(item)}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => e.key === keyboardEvents.ENTER && selectProduct(item)}
                  >
                    <div className="image-wrapper d-flex flex-align-center">
                      <img
                        src={getImageByModel(item.model)}
                        className="img"
                        alt={item.model}
                      />
                    </div>
                    <div className="product-wrapper">
                      <p className="product-name">{item.isICTrackProduct ? t([`products.${item.model}`, item.model]) : item.model}</p>
                      {item.products?.length > 0 && 
                        <p className="product-serials">{`${item.products.length} ${t("products.products")}`}</p>
                      }
                    </div>
                    {item.hasUpdateAvailable && (
                      <div className="has-update"/>
                    )}
                  </div>)
                }
              </div>

              {selectedProduct && !isICTrackProduct &&
                <div className="serials-wrapper">
                  <p className="serial-select-title">{t('products.registered_products')}</p>
                  <ClickOutside 
                    itemRef="click-outside-wrapper"
                    eventItem={selectSerialInput}
                    toDisplayItem={selectSerialOptions}/>

                  {selectedSerial &&
                    <div className="product-details-wrapper">
                      {showFeatureDev && !selectedSerial.association_active &&
                        <div className="activation-box-text">
                          <div className="icon-wrapper">
                            <InformationIcon />
                          </div>
                          <div className="activation-wrapper">
                            <div className="title">{t("products.activate_online_access")}</div>
                            <span>{t("products.activation_message")}</span>
                            <span 
                              className="activation-steps" 
                              onClick={() => setShowProductActivationStepsModal(true)}
                              tabIndex={0}
                              role="button"
                              onKeyDown={(e) => e.key === keyboardEvents.ENTER && setShowProductActivationStepsModal(true)}
                            >
                              {t("products.click_to_activate_online_access")}
                            </span>
                          </div>
                        </div>
                      }
                      <div className="product-status-wrapper">
                        <div className="image-wrapper d-flex flex-align-center">
                          <img
                            src={getImageByModel(selectedProduct.model)}
                            className="img"
                            alt={selectedProduct.model}
                          />
                        </div>
                        <div className="product-serial-wrapper">
                          <div className="product-serial">
                            {`${t("sn")}: ${selectedSerial.serial_number}`}
                          </div>
                          <div className="product-serial-subtitle">
                            {productSerialNickname ?
                              <div className="nickname-wrapper">
                                <span className="product-nickname">{productSerialNickname}</span>
                                <div className="nickname-actions-wrapper">
                                  <span 
                                    className={"delete-nickname" + ((!hasOnlineAccess || productIsAperture) ? " disabled" : "")}
                                    onClick={() => hasOnlineAccess && !productIsAperture && setShowDeleteNicknameModal(true)}
                                    tabIndex={0}
                                    role="button"
                                    onKeyDown={(e) => e.key === keyboardEvents.ENTER && hasOnlineAccess && !productIsAperture && setShowDeleteNicknameModal(true)}
                                  >
                                      {t("labels.delete")}
                                  </span>
                                  <span
                                    className={"edit-nickname" + (!hasOnlineAccess ? " disabled" : "") }
                                    onClick={() => hasOnlineAccess && setShowNicknameSetupModal(true)}
                                    tabIndex={0}
                                    role="button"
                                    onKeyDown={(e) => e.key === keyboardEvents.ENTER && hasOnlineAccess && setShowNicknameSetupModal(true)}
                                  >
                                    {t("labels.edit")}
                                  </span>
                                </div>
                              </div>
                            :
                              <div 
                                className={"nickname-add-wrapper" + (!hasOnlineAccess ? " disabled" : "") }
                                onClick={() => hasOnlineAccess && setShowNicknameSetupModal(true)}
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e) => e.key === keyboardEvents.ENTER && hasOnlineAccess && setShowNicknameSetupModal(true)}
                              >
                                {t("products.add_nickname")}
                              </div>
                            }
                          </div>
                        </div>
                        <div className="product-status">
                          <div className="product-status-legend">
                            {canAccessInstrumentTracking && 
                              <TooltipedIcon
                                icon={<ApertureIcon className={!productIsAperture ? "unavailable" : ""}/>}
                                tooltipText={productIsAperture ? t("products.aperture_associated") : t("products.aperture_not_associated")}
                              />
                            }
                            <TooltipedIcon
                              icon={<UpdateIcon className={!hasUpdateAvailable ? "unavailable" : ""}/>}
                              tooltipText={hasUpdateAvailable ? t("products.update_avilable") : t("products.update_not_avilable")}
                            />
                            <TooltipedIcon
                              icon={<VerifiedIcon className={!hasOnlineAccess ? "unavailable" : ""}/>}
                              tooltipText={hasOnlineAccess ? t("products.active_online_access") : t("products.inactive_online_access")}
                            />
                            <TooltipedIcon
                              icon={<CheckDoubleIcon className={!hasPrivacyPolicyChecked ? "unavailable" : ""}/>}
                              tooltipText={hasPrivacyPolicyChecked ? t("products.privacy_policy_accepted") : t("products.privacy_policy_not_accepted")}
                            />
                          </div>
                          <div className="product-status-actions">
                            {((checkPermission(props.userPermissions, userNotificationWritePermission) && isBravoProduct) || (checkPermission(props.userPermissions, deviceNotificationsReadPermissions))) &&
                              <SimpleButton
                                className="action-button" 
                                disabled={!hasOnlineAccess} 
                                onClick={() => setShowNotifications(true)}
                              >
                                {t("events.device-notifications")}
                              </SimpleButton>
                            }
                            {isBravoProduct && !selectedSerial.association_active && 
                              <SimpleButton
                                className="action-button"
                                onClick={() => setShowBravoOnlineAccess(true)}
                              >
                                {t("products.activate_online_access_now")}
                              </SimpleButton>
                            }
                          </div>
                        </div>
                      </div>

                      {showFeatureDev && selectedSerial.update_available &&
                        <div className="product-software-wrapper">
                          <div className="update-wrapper">
                            <div className="info-wrapper">
                              <div className="section-title">{t("products.update_available")}</div>
                              <div className="title-version">
                                <span>{`${t("products.current_version")} `}</span>
                                <span className="current-version">{"Lorem Ipsum"}</span>
                              </div>
                              <div className="title-version">
                                <span>{`${t("products.latest_version")} `}</span>
                                <span className="latest-version">{"Dollor sin amenum"}</span>
                              </div>
                              <SimpleButton className="submit-button">
                                {t("products.view_details")}
                              </SimpleButton>
                            </div>
                            <div className="update-dot"/>
                          </div>
                        </div>
                      }

                      <div className="product-cycles-wrapper">
                        <div className="section-title">{t("products.last_cycles")}</div>
                        <div className="cycles-wrapper">
                          {lastCycles.map((cycle, index) => (
                            <div
                              key={index}
                              className={"cycle-item-wrapper " + `${cycle.className}` } 
                              onClick = {() => handleOpenCycleDetails(cycle)}
                              tabIndex={0}
                              role="button"
                              onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleOpenCycleDetails(cycle)}
                            >
                              <div className="title">{cycle.title}</div>
                              <div className="time">{buildUserFriendlyDate(cycle.start)}</div>
                            </div>))
                          }
                          {!cyclesLoading && lastCycles.length === 0 && 
                            <div className="no-cycles-wrapper">
                              <p className="disclaimer">{t("products.no_cycles_available")}</p>
                            </div>
                          }
                        </div>
                        <SimpleButton
                          className="action-button" 
                          onClick={() => navigateToProductPage()}
                        >
                          {t("products.view_cycles_calendar")}
                        </SimpleButton>
                      </div>

                      <div className="product-documents-wrapper">
                        <div className="section-title">{t("products.documents_available")}</div>
                        <div className="documents-wrapper">
                          {documents.map((item, index) => (
                            <div
                              key={index}
                              className="document-item-wrapper"
                              onClick = {() => handleDownload(item?.file_path, item?.file_name)}
                              tabIndex={0}
                              role="button"
                              onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleDownload(item?.file_path, item?.file_name)}
                            >
                              <div className="image-wrapper">
                                <DownloadModernIcon/>
                              </div>
                              <div className="image-wrapper rounded-image">
                                <PdfModernIcon/>
                              </div>
                              <div className="title-wrapper">
                                <p className="title">{item?.document_title ? item?.document_title : item?.file_name}</p>
                                <p className="section">{item?.section_name}</p>
                              </div>
                            </div>))
                          }
                          {documents.length === 0 && 
                            <div className="no-documents-wrapper">
                              <p className="disclaimer">{t("products.no_documents_available")}</p>
                            </div>
                          }
                        </div>
                        <SimpleButton 
                          className="action-button"
                          disabled={documents.length === 0}
                          onClick={() => setShowDocumentsModal(true)}
                        >
                          {t("products.view_all_documents")}
                        </SimpleButton>
                      </div>

                      <div className="product-videos-wrapper">
                        <div className="section-title">{t("products.videos")}</div>
                        <div className="videos-wrapper">
                          {videos.map((video, index) => (
                            <div
                              key={index}
                              className="video-item-wrapper"
                              onClick = {() => openVideoModal(video)}
                              tabIndex={0}
                              role="button"
                              onKeyDown={(e) => e.key === keyboardEvents.ENTER && openVideoModal(video)}
                            >
                              <div className="thumbnail-wrapper">
                                <div className="thumbnail-icon">
                                  <VideoPlayIcon />
                                </div>
                                <img
                                  src={video.thumbnail_url}
                                  alt="video"
                                />
                              </div>                
                              <p className="title">{video.title}</p>
                            </div>))
                          }
                          {videos.length === 0 && 
                            <div className="no-videos-wrapper">
                              <p className="disclaimer">{t("products.no_videos_available")}</p>
                            </div>
                          }
                        </div>
                        <SimpleButton 
                          className="action-button"
                          disabled={videos.length === 0}
                          onClick={() => setShowVideosModal(true)}
                        >
                          {t("products.view_all_videos")}
                        </SimpleButton>
                      </div>
                    </div>
                  }
                  {!selectedSerial &&
                    <EmptyPageResults
                      title={t('troubleshoot.no-product')}
                      subtitle={t('troubleshoot.select-product')}
                    />
                  }
                </div>
              }
              {selectedProduct && isICTrackProduct &&
                <div className="serials-wrapper">
                  <p className="serial-select-title">{`${t('products.linked_devices')} (${selectedProduct.products?.length})`}</p>
                  <div className="linked-products-wrapper">
                    {selectedProduct.products?.slice(0, showLimitedLikedDevice ? linkedDevicesLimit : selectedProduct.products.length)?.map((product, index) => 
                      <div
                        key={`${product.serial_number}-${index}`} 
                        className={"linked-product" + (product.isSciCanProduct ? " with-navigation" : "")}
                        onClick={() => product.isSciCanProduct && navigateToProduct(product.serial_number)}
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => e.key === keyboardEvents.ENTER && product.isSciCanProduct && navigateToProduct(product.serial_number)}
                      >
                          <img 
                            src={getImageByModel(mapSerialToModel(product.serial_number))} 
                            className="product-image"
                            alt="product"
                          />
                        <div className="product-nickname">{product.unit_nickname}</div>
                        <div className="product-serial">{product.serial_number && `${t("sn")} ${product.serial_number}`}</div>
                      </div>)
                    }
                  </div>
                  {selectedProduct.products?.length > linkedDevicesLimit && showLimitedLikedDevice &&
                    <div
                      onClick={() => setShowLimitedLikedDevice(false)}
                      className="content-limiter"
                      tabIndex={0}
                      role="button"
                      onKeyDown={(e) => e.key === keyboardEvents.ENTER && setShowLimitedLikedDevice(false)}
                    >
                      {`+${selectedProduct.products.length - linkedDevicesLimit} ${t("labels.more")}`}
                    </div>
                  }
                  {!showLimitedLikedDevice && 
                    <div
                      onClick={() => setShowLimitedLikedDevice(true)}
                      className="content-limiter"
                      tabIndex={0}
                      role="button"
                      onKeyDown={(e) => e.key === keyboardEvents.ENTER && setShowLimitedLikedDevice(true)}
                    >
                      {t("buttons.view_less")}
                    </div>
                  }

                  <div className="product-details-wrapper">
                    <div className="product-cycles-wrapper">
                      <div className="section-title with-extra-information">{t("products.instrument_tracking")}</div>
                      <div className="extra-information">{t("products.last_days")}</div>
                      <div className="cycles-wrapper ic-track-cycles-wrapper">
                        {lastCycles.map((cycle, index) => (
                          <div
                            key={index}
                            className={"cycle-item-wrapper " + `${cycle.className}` } 
                            onClick = {() => handleOpenCycleDetails(cycle)}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleOpenCycleDetails(cycle)}
                          >
                            <div className="icon-title-wrapper">
                              <div className='icon'>
                                {cycle.type === "washer" ? <WasherSmallIcon /> : <SterilizerSmallIcon />}
                                <div className="title">{cycle.serial_number ? `${t("sn")}: ${cycle.serial_number}` : cycle.nickName}</div>
                              </div>
                              <div className="cycle-num">{cycle.title}</div>
                            </div>
                          </div>))
                        }
                        {!cyclesLoading && lastCycles.length === 0 && 
                          <div className="no-cycles-wrapper">
                            <p className="disclaimer">{t("products.no_cycles_available")}</p>
                          </div>
                        }
                      </div>
                      <div className="cycles-navigation-buttons-wrapper">
                        <SimpleButton
                          className="action-button" 
                          onClick={() => navigateToInstrumentTracking()}
                        >
                          {t("products.view_instrument_tracking_calendar")}
                        </SimpleButton>
                        <SimpleButton
                          className="action-button"
                          onClick={() => navigateToInstrumentTrackingReports()}
                        >
                          {t("products.view_instrument_reprocessing_report")}
                        </SimpleButton>
                      </div>
                    </div>

                    <div className="product-documents-wrapper">
                      <div className="section-title">{t("products.documents")}</div>
                      <div className="documents-wrapper">
                        {documents.map((item, index) => (
                          <div
                            key={index}
                            className="document-item-wrapper"
                            onClick = {() => handleDownload(item?.file_path, item?.file_name)}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleDownload(item?.file_path, item?.file_name)}
                          >
                            <div className="image-wrapper">
                              <DownloadModernIcon/>
                            </div>
                            <div className="image-wrapper rounded-image">
                              <PdfModernIcon/>
                            </div>
                            <div className="title-wrapper">
                              <p className="title">{item?.document_title ? item?.document_title : item?.file_name}</p>
                              <p className="section">{item?.section_name}</p>
                            </div>
                          </div>))
                        }
                        {documents.length === 0 && 
                          <div className="no-documents-wrapper">
                            <p className="disclaimer">{t("products.no_documents_available")}</p>
                          </div>
                        }
                      </div>
                      <SimpleButton 
                        className="action-button"
                        disabled={documents.length === 0}
                        onClick={() => setShowDocumentsModal(true)}
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => e.key === keyboardEvents.ENTER && setShowDocumentsModal(true)}
                      >
                        {t("products.view_all_documents")}
                      </SimpleButton>
                    </div>

                    <div className="product-videos-wrapper">
                      <div className="section-title">{t("products.videos")}</div>
                      <div className="videos-wrapper">
                        {videos.map((video, index) => (
                          <div
                            key={index}
                            className="video-item-wrapper"
                            onClick = {() => openVideoModal(video)}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => e.key === keyboardEvents.ENTER && openVideoModal(video)}
                          >
                            <div className="thumbnail-wrapper">
                              <div className="thumbnail-icon">
                                <VideoPlayIcon />
                              </div>
                              <img
                                src={video.thumbnail_url}
                                alt="video"
                              />
                            </div>                
                            <p className="title">{video.title}</p>
                          </div>))
                        }
                        {videos.length === 0 && 
                          <div className="no-videos-wrapper">
                            <p className="disclaimer">{t("products.no_videos_available")}</p>
                          </div>
                        }
                      </div>
                      <SimpleButton 
                        className="action-button"
                        disabled={videos.length === 0}
                        onClick={() => setShowVideosModal(true)}
                      >
                        {t("products.view_all_videos")}
                      </SimpleButton>
                    </div>

                    {supportsColteneStore &&
                      <div className="product-related-products-wrapper">
                        <div className="section-title">{t("products.related_products")}</div>
                        <div className="related-products-wrapper">
                          {relatedProducts.length === 0 && 
                            <div className="no-related-products-wrapper">
                              <p className="disclaimer">{t("products.no_related_products_available")}</p>
                            </div>
                          }
                        </div>
                        <SimpleButton 
                          className="action-button"
                          onClick={() => navigateToColteneStore()}
                        >
                          {t("products.go_to_store")}
                        </SimpleButton>
                      </div>
                    }
                  </div>
                </div>
              }
              {!selectedProduct &&
                <DataPlaceholder
                  titlePlaceholder={t('troubleshoot.no-product')}
                  subtitlePlaceholder={t('troubleshoot.select-product')}
                /> 
              }
            </>
          :
            <>
              {!props.userProductsAreLoading && 
                <EmptyPageResults 
                  title={t("register.no_product_registered")}
                />
              }
            </>
          }
        </div>
      :
        <div className="content">
          {products.map((item, index) =>
            <div
              key={index}
              className="card d-flex flex-align-center"
              onClick={() => selectProduct(item)}
            >
              <div className="image-wrapper d-flex flex-align-center">
                <img
                  src={getImageByModel(item.model)}
                  className="img"
                  alt={item.model}
                />
              </div>
              <div className="product-wrapper">
                <p className="product-name">{item.isICTrackProduct ? t([`products.${item.model}`, item.model]) : item.model}</p>
                {item.products?.length > 0 && 
                  <p className="product-serials">{`${item.products.length} ${t("products.products")}`}</p>
                }
              </div>
              {item.hasUpdateAvailable && (
                <div className="has-update"/>
              )}
            </div>)
          }
          {products?.length === 0 && !props.userProductsAreLoading && 
            <EmptyPageResults title={t("register.no_product_registered")} />
          }
        </div>
      }
      {isMobile && selectedProduct &&
        <ProductDetailsModal
          canAccessInstrumentTracking={canAccessInstrumentTracking}
          selectedProduct={selectedProduct}
          rawSerials={rawSerials}
          selectedSerial={selectedSerial}
          onSelectSerial={(serial, toggleVisibility) => selectSerial(serial, toggleVisibility)}
          productSerialNickname={productSerialNickname}
          hasOnlineAccess={hasOnlineAccess}
          hasPrivacyPolicyChecked={hasPrivacyPolicyChecked}
          hasUpdateAvailable={hasUpdateAvailable}
          productIsAperture={productIsAperture}
          isBravoProduct={isBravoProduct}
          onShowNotifications={() => setShowNotifications(true)}
          onShowBravoOnlineAccess={() => setShowBravoOnlineAccess(true)}
          lastCycles={lastCycles}
          onShowCycleDetails={(cycle) => handleOpenCycleDetails(cycle)}
          handleOpenProductActivationStepsModal={() => setShowProductActivationStepsModal(true)}
          handleOpenNicknameSetupModal={() => setShowNicknameSetupModal(true)}
          handleOpenDeleteNicknameModal={() => setShowDeleteNicknameModal(true)}
          handleNavigateToProductPage={(serialNumber) => navigateToProductPage(serialNumber)}
          documents={documents}
          onDownloadDocument={(filePath, fileName) => handleDownload(filePath, fileName)}
          handleOpenDocumentsModal={() => setShowDocumentsModal(true)}
          videos={videos}
          handleOpenVideosModal={() => setShowVideosModal(true)}
          onSelectVideo={(video) => openVideoModal(video)}
          isICTrackProduct={isICTrackProduct}
          showSpinner={showSpinner}
          cyclesLoading={cyclesLoading}
          closeModal={() => setSelectedProduct(null)}
          linkedDevicesLimit={linkedDevicesLimit}
          relatedProducts={relatedProducts}
          showLimitedLikedDevice={showLimitedLikedDevice}
          onShowLimitedLinkedDevices={(shouldShow) => setShowLimitedLikedDevice(shouldShow)}
          handleNavigateToInstrumentTracking={() => navigateToInstrumentTracking()}
          handleNavigateToInstrumentTrackingReports={() => navigateToInstrumentTrackingReports()}
          handleNavigateToStore={() => navigateToColteneStore()}
          handleNavigateToProduct={(serialNumber) => navigateToProduct(serialNumber)}
          mapSerialToModel={mapSerialToModel}
        />
      }
      {showNotifications && isBravoProduct && 
        <Modal
          className="notification-settings"
          toggle={showNotifications}
          onToggle={() => setShowNotifications(false)}
        >
          <NotificaitonSettings
            serialNumber={selectedSerial.serial_number} 
            model={selectedProduct.model}/>
        </Modal>
      }
      {showNotifications && !isBravoProduct && 
        <NotificationSettings 
          serialNumber={selectedSerial.serial_number}
          model={selectedProduct.model} 
          modelImage={getImageByModel(selectedProduct.model)}
          closeModal={() => setShowNotifications(false)}
        />
      }
      {showInstrumentTrackingDetails && 
        <CycleModal
          selectedEvent={cycleDetailsDeviceData}
          selectedModel={mapSerialToModel(cycleDetailsDeviceData?.deviceSerialNumber)}
          isLoading={showSpinner}
          handleOpenCycleGraph={() => { setShowInstrumentTrackingDetails(false); setShowCycleDetails(true) }}
          closeModal={() => setShowInstrumentTrackingDetails(false)}
        />
      }
      {showCycleDetails &&
        <CycleDetailsModal
          deviceData={cycleDetailsDeviceData}
          cycleDetails={cycleDetails}
          selectedModel={mapSerialToModel(cycleDetailsDeviceData?.deviceSerialNumber)}
          closeModal={() => {
            setShowCycleDetails(false)
            setCycleDetails({})
            setCycleDetailsDeviceData({})
          }}
          handleExternalNavigationToTroubleshooting={() => cycleNavigateToTroubleshooting()}
        />
      }
      {selectedVideo && 
        <VideoModal 
          title={selectedVideo.title} 
          videoId={selectedVideo.video_id} 
          onCloseVideo={closeVideoModal} 
        />
      }
      {showRegisterProduct && (
        <RegisterProductModern
          closeModal={() => setShowRegisterProduct(false)}
        />
      )}
      {showVideosModal &&
        <Modal
          toggle
          onToggle={() => setShowVideosModal(false)}
          className="videos"
        >
          <Videos 
            //model={selectedProduct.model} 
            hideFilters
            hideBreadcrumb
          />
        </Modal>
      }
      {showDocumentsModal &&
        <Modal
          toggle 
          onToggle={() => setShowDocumentsModal(false)}
          className="documents"
        >
          <Documents
            className={"products-document-wrapper"}
            model={selectedProduct.model}
            hideBreadcrumb
            hideFiltering
          />
        </Modal>
      }
      {showProductActivationStepsModal &&
        <ProductActivationStepsModal
          closeModal={() => setShowProductActivationStepsModal(false)}
          isG4Plus={selectedSerial.is_g4_plus}
          isBravoDevice={isBravoProduct}
          model={selectedProduct.model}
        />
      }
      {showNicknameSetupModal &&
        <NicknameSetupModal
          closeModal={() => setShowNicknameSetupModal(false)}
          nickname={productSerialNickname}
          productIsAperture={productIsAperture}
          showSpinner={showSpinner}
          handleChangeNickname={(newNickname) => handleChangeNickname(newNickname)}
        />
      }
      {showDeleteNicknameModal && (
        <ConfirmationModal
          onToggle={() => { setShowDeleteNicknameModal(false)}}
          onCancel={() => { setShowDeleteNicknameModal(false)}}
          onAccept={() => { handleChangeNickname(""); setShowDeleteNicknameModal(false)}}
          message={t("products.delete_nickname_confirmation")}
          acceptButtonText={t("labels.delete")}
          isWarning
          requiredPermission={userProductsWritePermission}
        />
      )}
      {showBravoOnlineAccess &&
        <Modal
          toggle
          onToggle={() => setShowBravoOnlineAccess(false)}
          className="online-activation"
        >
          <OnlineAccess 
            toggleShowOnlineAccess={() => setShowBravoOnlineAccess(false)}
            isBravoG4OnlineAccess
            setIsSuccessOnlineActivation={() => onSuccessfulOnlineActivation()}
            serialNumber={selectedSerial.serial_number}
          />
        </Modal>
      }
      {(props.userProductsAreLoading || showSpinner) &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function mapStateToProps({ userProducts, authedUser, userPermissions, apertures, backNavigationState }) {
  return {
    userProducts: userProducts?.products || {},
    userProductsAreLoading: userProducts?.loading,
    authedUser,
    apertures: apertures?.apertures || [],
    userPermissions,
    backNavigationState
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setBackNavigationState
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(MyProducts)