import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AddEditCompany from "./AddEditCompany"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import ConfirmationModal from '../common/ConfirmationModal'
import { toast } from "../common/Toast"
import {
  MenuKebabVerticalIcon,
  CaretDownIcon,
  MenuKebabVerticalBackgroundIcon,
  EnabledIcon,
  PendingIcon,
  DisabledIcon,
} from "../../icons"
import { loadCompanies, removeCompany, putCompany, clearCompaniesMessages, loadCompaniesCountryOptions } from "../../actions/companies"
import { companiesDeletePermission, companiesWritePermission } from "../../utils/permissionValidation"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import ChangeAdminModal from "./ChangeAdminModal"

const orderingFields = [
  {
    field: "companyName",
    order: "asc",
    key: "company_asc",
  },
  {
    field: "companyName",
    order: "desc",
    key: "company_desc",
  },
  {
    field: "email",
    order: "asc",
    key: "email_asc",
  },
  {
    field: "email",
    order: "desc",
    key: "email_desc",
  },
  {
    field: "officeAddressOne",
    order: "asc",
    key: "address_asc",
  },
  {
    field: "officeAddressOne",
    order: "desc",
    key: "address_desc",
  },
  {
    field: "members",
    order: "asc",
    key: "members_asc",
  },
  {
    field: "members",
    order: "desc",
    key: "members_desc",
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc",
  }
]

const Companies = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [companiesTableData, setCompaniesTableData] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [countriesFilter, setCountriesFilter] = useState([])
  const [statusesFilter, setStatusesFilter] = useState([])
  const [searchParam, setSearchParam] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showAddEditCompanyModal, setShowAddEditCompanyModal] = useState(false)
  const [showChangeCompanyAdminModal, setShowChangeCompanyAdminModal] = useState(false)
  const [showDeleteCompanyConfirmModal, setShowDeleteCompanyConfirmModal] = useState(false)
  const [companyIdToDelete, setCompanyIdToDelete] = useState(null)
  const [expandedCompany, setExpandedCompany] = useState(0)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const companyStatuses = ['approved', 'pending', 'disabled', 'rejected']

  const buildAddress = (company) => {
    let address = ""

    if (company.officeAddressOne) {
      address += `${company.officeAddressOne} `
    }

    if (company.officeAddressTwo) {
      address += `${company.officeAddressTwo} `
    }

    if (company.city) {
      address += `${company.city} `
    }

    if (company.state) {
      address += `${company.state} `
    }

    if (company.zipCode) {
      address += `${company.zipPostalCode} `
    }

    if (company.country) {
      address += `${company.country}`
    }

    return address.trimEnd()
  }

  useEffect(() => {
    if (props.countryOptions.length === 0) {
      props.actions.loadCompaniesCountryOptions()
    }    
  }, [props.actions])

  useEffect(() => {
    const tableData = props.companies.map((company) => {
      return {
        identifier: company.id,
        company: company.companyName,
        email: company.email,
        address: buildAddress(company),
        members: company.branches?.reduce((acc, branch) => acc + branch.members, 0),
        branches: company.branches?.map((branch) => branch.branchName).join(", "),
        status: company.status,
        actions: [
          ...(company.status === "pending" ?
            [
              {
                label: t("labels.approve"),
                onActionClick: (e) => { e.stopPropagation(); handleReviewCompany(company.id, "approved") },
                requiredPermission: companiesWritePermission,
              },
              {
                label: t("labels.reject"),
                onActionClick: (e) => { e.stopPropagation(); handleReviewCompany(company.id, "rejected") },
                requiredPermission: companiesWritePermission,
              }
            ]
          :
            []
          ),
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); handleOpenEditCompany(company.id) },
          },
          ...(company.status === "approved" ? 
            [
              {
                label: t("company.change_admin"),
                onActionClick: (e) => { e.stopPropagation(); handleOpenChangeAdmin(company.id) },
              },
            ] 
          :
            []
          )
          //A.S: Company remove is disabled for now
          // {
          //   label: t("labels.remove"),
          //   onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(company.id) },
          //   isDisabled: company.status !== "disabled",
          //   requiredPermission: companiesDeletePermission,
          // },
        ]
      }
    })

    setCompaniesTableData(tableData)
  }, [props.companies])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearCompaniesMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearCompaniesMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadCompanies(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadCompanies(true)
        }

        setShouldApplyFilters(true)
      },
      searchParam ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [searchParam, countriesFilter, statusesFilter, orderBy])

  const handleLoadCompanies = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    props.actions.loadCompanies(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParam(value)
    } else if (value?.length === 0 && searchParam?.length > 0) {
      setShowSpinner(true)
      setSearchParam(value)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setCountriesFilter([])
    setStatusesFilter([])
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParam) {
      queryParams = { ...queryParams, query: searchParam }
    }

    if (countriesFilter.length > 0) {
      queryParams = { ...queryParams, country: countriesFilter }
    }

    if (statusesFilter.length > 0) {
      queryParams = { ...queryParams, status: statusesFilter }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`company.${orderBy.key}`) : t("events.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`company.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const renderCompanyStatus = (status) => {
    switch (status) {
      case companyStatuses[0]:
        return (
          <div className="status-active">
            <EnabledIcon />
            <p>{t("company.approved")}</p>
          </div>
        )
      case companyStatuses[1]:
        return (
          <div className="status-pending">
            <PendingIcon />
            <p>{t("company.pending")}</p>
          </div>
        )
      case companyStatuses[2]:
        return (
          <div className="status-inactive">
            <DisabledIcon />
            <p>{t("company.disabled")}</p>
          </div>
        )
      case companyStatuses[3]:
        return (
          <div className="status-inactive">
            <DisabledIcon />
            <p>{t("company.rejected")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const handleOpenEditCompany = (companyId) => {
    const company = props.companies.find((company) => company.id === companyId)

    if (company) {
      setShowAddEditCompanyModal(true)
      setSelectedCompany(company)
    }
  }

  const handleOpenChangeAdmin = (companyId) => {
    const company = props.companies.find((company) => company.id === companyId)

    if (company) {
      setShowChangeCompanyAdminModal(true)
      setSelectedCompany(company)
    }
  }

  const showConfirmationModal = (id) => {
    setCompanyIdToDelete(id)
    setShowDeleteCompanyConfirmModal(true)
  }

  const handleDeleteCompany = () => {
    setShowDeleteCompanyConfirmModal(false)
    props.actions.removeCompany(companyIdToDelete)
  }

  const handleReviewCompany = (companyId, newStatus) => {
    const company = props.companies.find((company) => company.id === companyId)

    const bodyParams = {
      ...company,
      status: newStatus,
    }

    if (company) {
      props.actions.putCompany(company.id, bodyParams)
    }
  }

  const closeAddEditModal = () => {
    setShowAddEditCompanyModal(false)
    setSelectedCompany(null)
  }

  const closeChangeAdminModal = () => {
    setShowChangeCompanyAdminModal(false)
    setSelectedCompany(null)
  }
 
  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <Fragment>
      <div className="companies-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={countriesFilter.length > 0 || statusesFilter.length > 0}
            counterBadge={countriesFilter.length + statusesFilter.length}
          />
          {/*A.S: Adding a company modal disabled for now */}
          {/* <SimpleButton 
            className="action-button" 
            onClick={() => setShowAddEditCompanyModal(true)}
          >
            {t('company.add_company')}
          </SimpleButton> */}
        </div>
        {isMobile && companiesTableData.length > 0 &&
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        }
        {!isMobile && companiesTableData.length > 0 && 
          <GenericTable
            data={companiesTableData}
            headers={[
              {
                title: t("company.company"),
                orderKey: 'companyName'
              },
              {
                title: t("company.admin_email"),
                orderKey: 'email'
              },
              {
                title: t("company.address"),
                orderKey: 'officeAddressOne'
              },
              {
                title: t("company.members"),
                orderKey: 'members'
              },
              {
                title: t("company.branches"),
              },
              {
                title: t("company.status"),
                orderKey: 'status'
              },
              {
                title: ''
              },
            ]}
            keys={[
              'company',
              'email',
              'address',
              'members',
              'branches',
              'status',
              'action',
            ]}
            keyRenderer={{
              address: (item) => {
                return (
                  <div className="address-table-wrapper">
                    <ExpandableText text={item.address} />
                  </div>
                )
              },
              branches: (item) => {
                return (
                  <div className="branches-table-wrapper">
                    <ExpandableText text={item.branches} />
                  </div>
                )
              },
              status: (item) => {
                return (
                  <div className="status-wrapper">
                    {renderCompanyStatus(item.status)}
                  </div>
                )
              },
              action: (item) => {
                return (
                  <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                    {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === item["identifier"] &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={item.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                )
              }
            }}
            onRowClick={(company) => handleOpenEditCompany(company.identifier)}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        }
        {isMobile && companiesTableData.map((company, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedCompany(index)}
          >
            <div className={"card-item" + (expandedCompany !== index ? " align-center" : "")}>
              <div className="card-item-title">{t("company.company")}</div>
              <div className={"card-item-body" + (expandedCompany !== index ? " align-center" : "")}>
                <div>{company.company}</div>
                <div 
                  className="card-actions"
                  onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                >
                  {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === index &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={company.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              </div>
            </div>
            {expandedCompany === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t("company.admin_email")}</div>
                  <div className="card-item-body">{company.email}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("company.address")}</div>
                  <div className="card-item-body">{company.address}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("company.members")}</div>
                  <div className="card-item-body">{company.members}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("company.branches")}</div>
                  <div className="card-item-body">{company.branches}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("company.status")}</div>
                  <div className="card-item-body card-item-body-status">
                    {renderCompanyStatus(company.status)}
                  </div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && companiesTableData.length === 0 && (
          <EmptyPageResults
            title={t("company.no-companies-found")}
            subtitle={t("company.not-found-extra")}
          />
        )}
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t("buttons.load_more")}
            </SimpleButton>
          </div>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("company.country"),
              type: FilterType.multiSelect,
              dataset: props.countryOptions,
              input: countriesFilter,
              output: (filterCountries) => {
                setCountriesFilter(filterCountries)
              },
            },
            {
              name: t("company.status"),
              type: FilterType.multiSelect,
              dataset: companyStatuses,
              input: statusesFilter,
              output: (filterStatuses) => {
                setStatusesFilter(filterStatuses)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showAddEditCompanyModal && (
        <AddEditCompany
          selectedCompany={selectedCompany}
          closeModal={closeAddEditModal}
          showSpinner={showSpinner}
        />
      )}
      {showChangeCompanyAdminModal && (
        <ChangeAdminModal
          selectedCompany={selectedCompany}
          closeModal={closeChangeAdminModal}
          showSpinner={showSpinner}
          setShowSpinner={setShowSpinner}
        />
      )}
      {showDeleteCompanyConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteCompanyConfirmModal(false)}
          onAccept={() => handleDeleteCompany()}
          onCancel={() => setShowDeleteCompanyConfirmModal(false)}
          message={t("company.confirm_delete_company")}
          acceptButtonText={t("labels.delete")}
          isWarning
          requiredPermission={companiesDeletePermission}
        />)
      }
    </Fragment>
  )
}

function stateToProps({ companies, users }) {
  return {
    companies: companies?.companies || [],
    countryOptions: companies?.countryOptions?.map(country => country.countryCode) || [],
    isLoading: companies?.isLoading || users?.usersLoading,
    canLoadMore: companies?.canLoadMore,
    errorMessage: companies?.errorMessage,
    successMessage: companies?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadCompanies,
        loadCompaniesCountryOptions,
        putCompany,
        removeCompany,
        clearCompaniesMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Companies)
