import React, { useEffect, Fragment, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../common/LoadingSpinner"
import SimpleButton from "../common/SimpleButton"
import { useWindowSize } from "../../libs/hooks"
import "./template-editor.scss"
import TemplateNameSetupModal from "./TemplateNameSetupModal"
import { keyboardEvents } from "../../utils/keyboardEvents"

const defaultUiTree = [
  {
    id: "hero-main",
    component: "hero-section",
    children: [
      {
        id: "hero-title",
        component: "text",
        style: { fontSize: "70px", fontWeight: "bold" },
        value: "Smarther Together",
      },
      {
        id: "hero-subtitle",
        component: "text",
        style: { fontSize: "30px" },
        value: "Optimize your equipment. Empower your team.",
      },
      {
        id: "hero-button",
        component: "link",
        href:'my.coltene.com',
        value: "Create an account"
      },
    ],
  },
  {
    id: "intro-section",
    component: "section",
    style: { backgroundColor: "#009AEB" },
    children: [
      {
        id: "intro-text-1",
        component: "text",
        style: { fontSize: "60px", marginTop: "125px", fontWeight: "bold" },
        value: "The myCOLTENE Advantage",
      },
      {
        id: "intro-text-2",
        component: "text",
        style: { fontSize: "22px", marginBottom: "80px", marginTop: "22px" },
        value: "Reduce anxiety and uncertainty related to new equipment and infenction control. Support your team with myCOLTENE, our digital platform that empowers staff with helpful tutorials, maintenance videos, support materials and more.",
      },
    ],
  },
  {
    id: "learning-section",
    component: "section",
    style: { backgroundColor: "#6EBDEF" },
    children: [
      {
        id: "learning-text-1",
        component: "text",
        style: { fontSize: "70px", marginTop: "125px", fontWeight: "bold" },
        value: "Learning Centre",
      },
      {
        id: "learning-text-2",
        component: "text",
        style: { fontSize: "20px", marginBottom: "84px", marginTop: "42px" },
        value: "Step-by-step tutorials on how to sign up, connect your equipment and find what you're looking for.",
      },
      {
        id: "learning-text-3",
        component: "text",
        style: { fontSize: "30px", marginTop: "54px", fontWeight: "bold" },
        value: "Searching the video archive",
      },
      {
        id: "learning-text-4",
        component: "text",
        style: { fontSize: "18px", marginBottom: "40px", marginTop: "16px" },
        value: "Need a refresher on how to use or maintain equipment? Search our video tutorials. Learn how here",
      },
    ],
  },
  {
    id: "register-section",
    component: "section",
    style: { backgroundColor: "#052C4D" },
    children: [
      {
        id: "register-text-1",
        component: "text",
        style: { fontSize: "24px", marginBottom: "48px", marginTop: "80px" },
        value: "Improve your practice with myCOLTENE digital solutions.",
      },
      {
        id: "register-button-2",
        component: "link",
        style: { marginBottom: "95px" },
        href:'my.coltene.com',
        value: "Create an account"
      },
    ],
  }
]

const TemplateEditor = (props) => {
  const { t } = useTranslation()
  const { selectedTemplateName, selectedUiTree, onToggle } = props
  const [templateName, setTemplateName] = useState(selectedTemplateName || "New Template")
  const [currentUiTree, setCurrentUiTree] = useState(selectedUiTree || defaultUiTree)
  const [selectedElement, setSelectedElement] = useState(null)
  const [showTemplateNameSetupModal, setShowTemplateNameSetupModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const editorContainerRef = useRef(null)

  const renderComponent = (componentData) => {
    const { id, component, style, children, value, href } = componentData;

    switch (component) {
      case 'hero-section':
        return (
          <div key={id} name="hero-section" className="hero-section" style={style}>
            {children && children.map((child) => renderComponent(child))}
          </div>
        )
      case 'section':
        return (
          <div key={id} name="section" className="section" style={style}>
            {children && children.map((child) => renderComponent(child))}
          </div>
        )
      case 'text':
        return (
          <div
            key={id}
            name="text"
            className="editable-text"
            style={style}
          >
            {value}
          </div>
        )
      case 'link':
        return (
          <a 
            key={id} 
            name="link" 
            className="link" 
            style={style} 
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        )
      default:
        return null
    }
  }

  const parseElement = (element) => {
    const componentName = element.getAttribute('name') || element.tagName.toLowerCase();
    const uiComponent = {
      component: componentName,
      style: {},
      children: []
    }

    const convertToCamelCase = (style) => {
      return style.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
    }

    for (const style of element.style) {
      uiComponent.style[convertToCamelCase(style)] = element.style[style]
    }

    // If the element is a text input (textarea in this case), extract the value
    if (componentName === 'text' || componentName === "link") {
      uiComponent.value = element.value
    }

    if (componentName === "link") {
      uiComponent.href = element.href
    }

    for (const child of element.children) {
      uiComponent.children.push(parseElement(child))
    }

    return uiComponent
  }
  
  const saveTemplate = () => {
    const uiTree = []
    const container = editorContainerRef.current

    if (container) {
      for (const child of container.children) {
        uiTree.push(parseElement(child))
      }
    }

    const template = {
      id: "6ef12523-9276-4cc0-9457-883733855eb3",
      name: "Template 1",
      createdAt: new Date().toISOString(),
      createdBy: "Andrei Salagean",
      status: "prod",
      uiTree: uiTree
    }

    console.log("Saved Template:", JSON.stringify(template, null, 2))
  }

  return (
    <div className="template-editor-wrapper">
      <div className="header">
        <div className="title">
          {templateName}
          <span 
            className="edit-title"
            onClick={() => setShowTemplateNameSetupModal(true)}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => e.key === keyboardEvents.ENTER && setShowTemplateNameSetupModal(true)}
          >
            {t("labels.edit")}
          </span>
        </div>
        <>
          <SimpleButton
            className="cancel-button"
            onClick={onToggle}
          >
            {"Exit"}
          </SimpleButton>
          <SimpleButton
            className="submit-button"
            onClick={() => saveTemplate()}
          >
            {"Save"}
          </SimpleButton>
          <SimpleButton
            className="action-button"
          >
            {"Preview"}
          </SimpleButton>
          <SimpleButton
            className="submit-button"
          >
            {"Save and Publish"}
          </SimpleButton>
        </>
      </div>

      <div ref={editorContainerRef} className="editor-container">
        {currentUiTree.map((componentData) =>
          renderComponent(componentData)
        )}
      </div>

      {showTemplateNameSetupModal &&
        <TemplateNameSetupModal
          closeModal={() => setShowTemplateNameSetupModal(false)}
          currentTemplateName={templateName}
          showSpinner={showSpinner}
          handleChangeTemplateName={(newTemplateName) => setTemplateName(newTemplateName)}
        />
      }
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}

    </div>
  )
}

export default TemplateEditor