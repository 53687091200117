import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Companies from "./Companies"
import Branches from "./Branches"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import WrappedTabs from "../common/WrappedTabs"
import "./companies-management.scss"

const CompaniesManagement = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(null)
  const tabs = [
    {
      key: 0,
      title: t("company.companies"),
      content: <Companies />,
    },
    {
      key: 1,
      title: t("company.branches"),
      content: <Branches />,
    },
  ]

  useEffect(() => {
    setActiveTab(tabs[0])
  }, [])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.key === tabKey)
    setActiveTab(tab)
  }

  return (
    <div className="companies-management-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.organizations-management")} />
      <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))} activeTab={activeTab?.key} changeTab={handleChangeTab} />
      {activeTab?.content && activeTab.content}
    </div>
  )
}

export default CompaniesManagement
