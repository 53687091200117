import React, {useEffect, useState, useRef} from 'react'
import '@vaadin/rich-text-editor'
import DOMPurify from 'dompurify'
import './rich-text-input.scss'

const RichTextInput = (props) => {
  const {value, onChangeValue, isReadOnly, hasError} = props
  const [isInitialMount, setIsInitialMount] = useState(true)
  const editorRef = useRef(null)
  const vaadinRichTextHtmlEmptyState = '<p><br></p>'

  useEffect(() => {
    const editor = editorRef.current

    const onChange = () => {
      onChangeValue(editor.htmlValue)
    }

    editor.addEventListener('html-value-changed', onChange)

    return () => {
      editor.removeEventListener('html-value-changed', onChange)
    }
  }, [onChangeValue])

  useEffect(() => {
    if (value && value.length > 0 && isInitialMount) {
      const editor = editorRef.current

      if (editor && (!editor.htmlValue || editor.htmlValue === vaadinRichTextHtmlEmptyState)) {
        editor.dangerouslySetHtmlValue(DOMPurify.sanitize(value))

        setIsInitialMount(false)
      }
    }
  }, [value])
 
  return (
    <div className={"rich-text-input" + (hasError ? " rich-text-error" : "")}>
      <vaadin-rich-text-editor
        ref={editorRef}
        style={{
          maxHeight: "400px"
        }}
        theme="compact"
        readonly={isReadOnly}
      />
    </div>

  )
}

export default RichTextInput
