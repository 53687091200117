import React, { useRef, useLayoutEffect } from "react"
import { connect } from "react-redux"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { checkPermission } from "../../../utils/permissionValidation"
import "./actions-menu.scss"

const ActionsMenu = (props) => {
  const { setHideMenu, isLoading } = props
  const ref = useRef()

  useLayoutEffect(() => {
    if (ref.current && !isElementInViewport(ref.current)) {
      ref.current.style.transform = "translateY(-44%) scale(1)"
    }
  },[])

  const isElementInViewport = (element) => {
    const elementArea = element.getBoundingClientRect()
  
    return (
      elementArea.top >= 0 &&
      elementArea.left >= 0 &&
      elementArea.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      elementArea.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  useOnClickOutside(ref, (e) => {
    setHideMenu(e)
  })

  const handleActionClick = (e, action) => {
    if (!isLoading) {
      setHideMenu(e)
      action.onActionClick(e)
    }
  }

  return (
    <div ref={ref} className="actions-menu-wrapper d-flex flex-column">
      <div className="actions-menu d-flex flex-column">
        {props.actions?.map((action, index) => 
          <div 
            key={index} 
            className={"menu-item" + ((action.isDisabled || (action.requiredPermission && !checkPermission(props.userPermissions, action.requiredPermission))) ? " disabled" : "")} 
            onClick={(e) => handleActionClick(e, action)}
          >
            {action.label}
          </div>
        )}
      </div>
    </div>
  )
}

function stateToProps({ userPermissions }) {
  return {
    userPermissions
  }
}

export default connect(stateToProps)(ActionsMenu)