import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TemplateEditor from "./TemplateEditor"
import LoadingSpinner from "../common/LoadingSpinner"
import Breadcrumb from "../common/Breadcrumb"
import SimpleButton from "../common/SimpleButton"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import EmptyPageResults from "../common/EmptyPageResults"
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { convertTimeWithTAndZ } from '../../utils/filters/date'
import { MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import "./my-coltene-templates.scss"

const mockTemplates = [
  {
    id: "6ef12523-9276-4cc0-9457-883733855eb3",
    name: "Template 1",
    createdAt: new Date().toISOString(),
    createdBy: "Andrei Salagean",
    status: "deployed", // deployed/preview/available
    uiTree: [
      {
        component: "section",
        style: {
          "background-color": "red"
        },
        children: [
          {
            component: "text",
            style: {
              "color": "white",
              "font-size": "24px"
            },
            children: [],
            value: "Smarter Together"
          }
        ]
      }
    ],
  }
]

const MyColteneTemplates = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(false)
  const [isEditorMode, setIsEditorMode] = useState(false)

  useEffect(() => {
    const tableData = mockTemplates.map((template) => {
      return {
        identifier: template.id,
        name: template.name,
        createdAt: convertTimeWithTAndZ(template.createdAt),
        createdBy: template.createdBy,
        uiTree: template.uiTree,
        status: template.status,
        actions: [
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); handleEditTemplate(template.id)},
          },
          {
            label: t("template.preview"),
            onActionClick: (e) => { e.stopPropagation(); },
          },
          {
            label: t("template.publish"),
            onActionClick: (e) => { e.stopPropagation(); },
            isDisabled: template.status === "deployed"
          },
          {
            label: t("template.clone"),
            onActionClick: (e) => { e.stopPropagation(); },
          },
          {
            label: t("labels.delete"),
            onActionClick: (e) => { e.stopPropagation(); },
            isDisabled: template.status !== "available"
          },
        ]
      }
    })

    setTemplates(tableData)
  }, [])

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  const handleEditTemplate = (templateId) => {
    const template = mockTemplates.find(t => t.id === templateId)

    if (!template) {
      return
    }

    setSelectedTemplate(template)
    setIsEditorMode(true)
  }

  return (
    <div className="mycoltene-templates-wrapper">
      <Breadcrumb path={window.location.pathname} />
      {!isEditorMode && (
        <>
          <DashboardHeader
            headerText={t("template.templates")}
          >
            {!isMobile &&
              <SimpleButton
                className="submit-button"
                onClick={() => setIsEditorMode(true)}
              >
                {t("template.add_template")}
              </SimpleButton>
            }
          </DashboardHeader>
          {!isMobile && templates.length > 0 &&
            <GenericTable
              data={templates}
              headers={[
                {
                  title: t("template.name"),
                },
                {
                  title: t("template.created_by")
                },
                {
                  title: t("template.created_at")
                },
                {
                  title: t("template.status")
                },
                {
                  title: ''
                },
              ]}
              keys={[
                'name',
                'createdBy',
                'createdAt',
                'status',
                'action',
              ]}
              keyRenderer={{
                name: (item) => {
                  return (
                    <div className="name-wrapper">
                      <ExpandableText text={item.name} />
                    </div>
                  )
                },
                status: (item) => t([`template.${item.status}`, item.status]),
                action: (item) => {
                  return (
                    <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                      {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                      {showActionsMenu === item["identifier"] &&
                        <ActionsMenu
                          setHideMenu={() => setShowActionsMenu(null)}
                          actions={item.actions}
                          isLoading={showSpinner}
                        />
                      }
                    </div>
                  )
                }
              }}
              onRowClick={(template) => handleEditTemplate(template.identifier)}
            />
          }
          {isMobile && 
            <EmptyPageResults
              title={t("template.not_supported")}
              subtitle={t("template.not_supported_subtitle")}
            />
          }
          {!showSpinner && !isFirstLoad && templates.length === 0 && (
            <EmptyPageResults
              title={t("template.no_templates")}
              subtitle={t("template.no_templates_subtitle")}
            />
          )}
        </>
      )}
      {isEditorMode && (
        <TemplateEditor
          onToggle={() => {
            setIsEditorMode(false)
            setSelectedTemplate(null)
          }}
          selectedUiTree={selectedTemplate?.uiTree}
        />
      )}
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default MyColteneTemplates
