import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PolicyTypeModal from "./PolicyTypeModal"
import LoadingSpinner from "../common/LoadingSpinner"
import ConfirmationModal from "../common/ConfirmationModal"
import ClickOutside from "../common/ClickOutside"
import EmptyPageResults from "../common/EmptyPageResults"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import FiltersModal from "../common/FiltersModal"
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { CaretDownIcon, MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import { loadPolicyTypes, clearPolicyTypeMessages, removePolicyType } from "../../actions/policies"
import { policyTypeDeletePermission } from "../../utils/permissionValidation"

const orderingFields = [
  {
    field: "name",
    order: "asc",
    key: "name_asc",
  },
  {
    field: "name",
    order: "desc",
    key: "name_desc",
  },
]

const PolicyTypes = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [policyTypesTableData, setPolicyTypesTableData] = useState([])
  const [selectedPolicyType, setSelectedPolicyType] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showAddEditPolicyTypeModal, setShowAddEditPolicyTypeModal] = useState({ isOpen: false, isEdit: false })
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDeletePolicyTypeConfirmModal, setShowDeletePolicyTypeConfirmModal] = useState(false)
  const [policyTypeIdToDelete, setPolicyTypeIdToDelete] = useState(null)
  const [expandedPolicyType, setExpandedPolicyType] = useState(0)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showActionsMenu, setShowActionsMenu] = useState(null)

  useEffect(() => {
    if (props.policyTypes.length === 0 && !props.isLoading) {
      props.actions.loadPolicyTypes().then(() => setIsFirstLoad(false))
    } else {
      setIsFirstLoad(false)
    }
  }, [])
  
  useEffect(() => {
    const tableData = props.policyTypes.map((policyType) => {
      return {
        identifier: policyType.id,
        name: policyType.name,
        description: policyType.description,
        actions: [
          {
            label: t("labels.edit"),
            onActionClick: (e) => { e.stopPropagation(); handleOpenEditPolicyType(policyType.id, true) },
          },
          {
            label: t("labels.delete"),
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(policyType.id) },
            requiredPermission: policyTypeDeletePermission
          },
        ]
      }
    })

    setPolicyTypesTableData(tableData)
  }, [props.policyTypes])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearPolicyTypeMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearPolicyTypeMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadPolicyTypes(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (!isFirstLoad) {
          setSelectedPage(1)
          handleLoadPolicyTypes(true)
        }
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [filterQuery, orderBy])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const handleLoadPolicyTypes = (withReset) => {
    let queryParams = {
      startPage: withReset ? 1 : selectedPage,
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    props.actions.loadPolicyTypes(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {}

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`policies.${orderBy.key}`) : t("events.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`policies.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const handleOpenEditPolicyType = (policyTypeId, isEditEnabled) => {
    const policyType = props.policyTypes.find((policyType) => policyType.id === policyTypeId)

    setSelectedPolicyType(policyType)
    setShowAddEditPolicyTypeModal({ isOpen: true, isEdit: isEditEnabled })
  }

  const showConfirmationModal = (id) => {
    setPolicyTypeIdToDelete(id)
    setShowDeletePolicyTypeConfirmModal(true)
  }

  const handleDeletePolicyType = () => {
    setShowDeletePolicyTypeConfirmModal(false)
    setShowAddEditPolicyTypeModal({ isOpen: false, isEdit: false })
    setSelectedPolicyType(null)
    props.actions.removePolicyType(policyTypeIdToDelete)
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <Fragment>
      <div className="actions-wrapper policy-types-actions">
        {/*Filters hidden until API implemented */}
        {/* <SearchWithFilter
          onSearch={(e) => handleSearch(e)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterStatuses.length > 0}
          counterBadge={selectedFilterStatuses.length}
        /> */}
        {isMobile && policyTypesTableData.length > 0 && (
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        )}
      </div>
      <div className="add-policy-button">
        <SimpleButton className="submit-button" onClick={() => handleOpenEditPolicyType(null, false)}>
          {t("policies.add-policy-type")}
        </SimpleButton>
      </div>
      {!isMobile && policyTypesTableData.length > 0 &&
        <GenericTable
          className="policy-types-table"
          data={policyTypesTableData}
          headers={[
            {
              title: t("policies.name"),
              orderKey: 'name'
            },
            {
              title: t("policies.description")
            },
            {
              title: ''
            },
          ]}
          keys={[
            'name',
            'description',
            'action',
          ]}
          keyRenderer={{
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(policyType) => handleOpenEditPolicyType(policyType.identifier, true)}
          activeSort={orderBy}
          onSort={(orderingField) => handleSort(orderingField)}
          isLoading={showSpinner}
        />
      }
      {isMobile && policyTypesTableData.map((policyType, index) =>
        <div 
          key={index}
          className="card"
          onClick={() => setExpandedPolicyType(index)}
        >
          <div className={"card-item" + (expandedPolicyType !== index ? " align-center" : "")}>
            <div className="card-item-title">{t("policies.name")}</div>
            <div className={"card-item-body" + (expandedPolicyType !== index ? " align-center" : "")}>
              <div>{policyType.name}</div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={policyType.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedPolicyType === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t("policies.description")}</div>
                <div className="card-item-body">{policyType.description}</div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && policyTypesTableData.length === 0 && (
        <EmptyPageResults 
          title={t("policies.no-device-notifications-found")}
          subtitle={t("policies.no-device-notifications-found-extra")}
        />
      )}
      {/*Pagination functionality remains hidden until further notice */}
      {/* {canLoadMore && (
        <div className="buttons-wrapper">
          <button className="load-more-button" onClick={() => handleLoadMore()}>
            {t('buttons.load_more')}
          </button>
        </div>)
      } */}
      {showAddEditPolicyTypeModal?.isOpen && (
        <PolicyTypeModal
          isEditMode={showAddEditPolicyTypeModal?.isEdit}
          selectedPolicyType={selectedPolicyType}
          handleDeletePolicyType={(e) => {
            e.stopPropagation()
            showConfirmationModal(selectedPolicyType.id)
          }}
          closeModal={() => {
            setShowAddEditPolicyTypeModal({ isOpen: false, isEdit: false })
            setSelectedPolicyType(null)
          }}
          isLoading={showSpinner}
          showSpinner={() => setShowSpinner(true)}
          hideSpinner={() => setShowSpinner(false)}
        />
      )}
      {showFiltersModal && 
        <FiltersModal 
          filters={[{}]} 
          resetFilters={() => handleResetFilters()} 
          closeFilters={() => setShowFiltersModal(false)} 
        />
      }
      {showDeletePolicyTypeConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeletePolicyTypeConfirmModal(false)}
          onAccept={() => handleDeletePolicyType()}
          onCancel={() => setShowDeletePolicyTypeConfirmModal(false)}
          message={t("policies.confirm-delete-policy-type")}
          acceptButtonText={t("labels.delete")}
          isWarning
          requiredPermission={policyTypeDeletePermission}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </Fragment>
  )
}

function stateToProps({ policies }) {
  return {
    policyTypes: policies?.policyTypes || [],
    isLoading: policies?.policiesLoading,
    errorMessage: policies?.errorMessage,
    successMessage: policies?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadPolicyTypes,
        removePolicyType,
        clearPolicyTypeMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(PolicyTypes)
