import React, { useEffect } from "react"
import { Switch, Redirect, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactGA from 'react-ga4'
import Login from "../../Login"
import Dashboard from "../../Dashboard"
import SelectUserGroup from "../../SelectUserGroup"
import CreateAccount from "../../CreateAccount"
import ForgotPassword from "../../ForgotPassword"
import ProductsModern from '../../ProductsModern'
import ProductNotes from "../../ProductNotes"
import MonitoringModern from '../../MonitoringModern'
import Reports from "../../Reports"
import FirmwareDownload from "../../FirmwareDownload"
import SparepartsModern from "../../SparepartsModern"
import Videos from "../../Videos"
import PageNotFound from "../../PageNotFound"
import ReportProblem from "../../ReportProblem"
import ReportProblemModern from "../../ReportProblemModern"
import PrivateRoute from "../PrivateRoute"
import Documents from "../../Documents"
import PriceList from "../../PriceList/index"
// import DigitalAssets from "../../DigitalAssets"
import DealerBulletins from "../../DealerBulletins"
import Troubleshoot from "../../Troubleshoot"
import PublicRoute from "../PublicRoute"
import { useAppContext } from "../../../libs/contextLib"
import { Roles } from "../../../utils/roles"
import { Pages } from "../../../utils/pages"
import RemoteAccessSession from "../../RemoteAccessSession/index"
import InstrumentTracking from "../../InstrumentTracking"
import RemoteAccess from "../../RemoteAccess"
import Events from "../../Events"
import ManageAccounts from "../../Accounts"
import PoliciesPage from "../../Policies"
import BusinessRules from "../../BusinessRules"
import Services from "../../Services"
import ColteneStore from "../../ColteneStore"
import ProductDetails from "../../ColteneStore/ProductDetails"
import AdminStore from "../../AdminStore"
import AdminOrderHistory from "../../AdminOrderHistory"
import Users from "../../Users"
import MyAccount from "../../MyAccount"
import MyProducts from "../../MyProducts"
import Members from "../../Members"
import CompaniesManagement from "../../CompaniesManagement"
import MyColteneTemplates from "../../MyColteneTemplates"
import { hasAdminModulesPermission, hasOrganizationAdminModulesPermission } from "../../../utils/permissionValidation"

function Routes(props) {
  const { isAuthenticated, showFeatureDev, supportsColteneStore } = useAppContext()
  const location = useLocation()
  const { userRole, user, userPermissions } = props

  useEffect(() => {
    const currentPage = `${location?.pathname || ""} ${location?.search || ""}`
    ReactGA.send({ hitType: 'pageview', page: currentPage })
  }, [location?.pathname])

  return showFeatureDev ? (
    <Switch>
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/register" component={SelectUserGroup} />
      <PublicRoute exact path="/register/create-account/:userGroup" component={CreateAccount} />
      {/*<PublicRoute exact path='/confirm-code' component={ConfirmationCode} />*/}
      <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
      <PrivateRoute
        path={Pages.dashbord.route}
        exact
        component={Dashboard}
        openRemoteModal={props.openRemoteModal}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.dashbord.permission}
        userPermissions={userPermissions}
        roles={Roles.dashbord.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.product.route}
        exact
        component={ProductsModern}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.product.permission}
        userPermissions={userPermissions}
        roles={Roles.product.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.productNotes.route}
        exact
        component={ProductNotes}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.productNotes.permission}
        userPermissions={userPermissions}
        roles={Roles.productNotes.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.myProducts.route}
        exact
        component={MyProducts}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.myProducts.permission}
        userPermissions={userPermissions}
        roles={Roles.myProducts.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.monitoring.route}
        component={MonitoringModern}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.monitoring.permission}
        userPermissions={userPermissions}
        roles={Roles.monitoring.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.reports.route}
        component={Reports}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.reports.permission}
        userPermissions={userPermissions}
        roles={Roles.reports.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.documents.route}
        component={Documents}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.documents.permission}
        userPermissions={userPermissions}
        roles={Roles.documents.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.videos.route}
        component={Videos}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.videos.permission}
        userPermissions={userPermissions}
        roles={Roles.videos.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.spareParts.route}
        component={SparepartsModern}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.spareParts.permission}
        userPermissions={userPermissions}
        roles={Roles.spareParts.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.firmware.route}
        component={FirmwareDownload}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.firmware.permission}
        userPermissions={userPermissions}
        roles={Roles.firmware.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.troubleshoot.route}
        component={Troubleshoot}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.troubleshoot.permission}
        userPermissions={userPermissions}
        roles={Roles.troubleshoot.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.reportProblem.route}
        exact
        component={ReportProblemModern}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.reportProblem.permission}
        userPermissions={userPermissions}
        roles={Roles.reportProblem.roles}
        userRole={userRole}
      />
      {/* A.S: Hidden until further notice */}
      {/* <PrivateRoute
        path={Pages.warranty.route}
        component={Warranty}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.warranty.permission}
        userPermissions={userPermissions}
        roles={Roles.warranty.roles}
        userRole={userRole}
      /> */}
      <PrivateRoute
        path={Roles.events.route}
        component={Events}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.events.permission}
        userPermissions={userPermissions}
        roles={Roles.events.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.priceLists.route}
        component={PriceList}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.priceLists.permission}
        userPermissions={userPermissions}
        roles={Roles.priceLists.roles}
        userRole={userRole}
      />
      {/* <PrivateRoute
        path={Pages.digitalAssets.route}
        component={DigitalAssets}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.digitalAssets.permission}
        userPermissions={userPermissions}
        roles={Roles.digitalAssets.roles}
        userRole={userRole}
      /> */}
      <PrivateRoute
        path={Pages.dealerBulletins.route}
        component={DealerBulletins}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.dealerBulletins.permission}
        userPermissions={userPermissions}
        roles={Roles.dealerBulletins.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.users.route}
        exact
        component={Users}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.users.permission}
        userPermissions={userPermissions}
        roles={Roles.users.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Roles.accountsManagement.route}
        exact
        component={ManageAccounts}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.accountsManagement.permission}
        userPermissions={userPermissions}
        roles={Roles.accountsManagement.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.companies.route}
        exact
        component={CompaniesManagement}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.companies.permission}
        userPermissions={userPermissions}
        roles={Roles.companies.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Roles.policies.route}
        exact
        component={PoliciesPage}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.policies.permission}
        userPermissions={userPermissions}
        roles={Roles.policies.roles}
        userRole={userRole}
      />

      {props.remoteSession && (
        <PrivateRoute
          path={Pages.remoteAccessSession.route}
          exact
          component={RemoteAccessSession}
          disableRemoteSession={props.disableRemoteSession}
          authedUser={isAuthenticated}
          user={user}
          permission={Pages.remoteAccess.permission}
          userPermissions={userPermissions}
          roles={Roles.remoteAccess.roles}
          userRole={userRole}
        />
      )}

      <PrivateRoute
        path={Pages.remoteAccess.route}
        exact
        component={RemoteAccess}
        setRemoteSession={props.setRemoteSession}
        disableRemoteSession={props.disableRemoteSession}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.remoteAccess.permission}
        userPermissions={userPermissions}
        roles={Roles.remoteAccessSession.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.instrumentTracking.route}
        exact
        component={InstrumentTracking}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.instrumentTracking.permission}
        userPermissions={userPermissions}
        roles={Roles.instrumentTracking.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.colteneStore.route}
        exact
        component={ColteneStore}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.colteneStore.permission}
        userPermissions={userPermissions}
        roles={Roles.colteneStore.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.adminStore.route}
        exact
        component={AdminStore}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.adminStore.permission}
        userPermissions={userPermissions}
        roles={Roles.adminStore.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.adminOrderHistory.route}
        exact
        component={AdminOrderHistory}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.adminOrderHistory.permission}
        userPermissions={userPermissions}
        roles={Roles.adminOrderHistory.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.previewProductDetails.route}
        exact
        component={ProductDetails}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.previewProductDetails.permission}
        userPermissions={userPermissions}
        roles={Roles.previewProductDetails.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.productDetails.route}
        exact
        component={ProductDetails}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.productDetails.permission}
        userPermissions={userPermissions}
        roles={Roles.productDetails.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.businessRules.route}
        exact
        component={BusinessRules}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.businessRules.permission}
        userPermissions={userPermissions}
        roles={Roles.businessRules.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.services.route}
        exact
        component={Services}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.services.permission}
        userPermissions={userPermissions}
        roles={Roles.services.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.myColteneTemplates.route}
        exact
        component={MyColteneTemplates}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.myColteneTemplates.permission}
        userPermissions={userPermissions}
        roles={Roles.myColteneTemplates.roles}
        userRole={userRole}
      />
      {/* <PrivateRoute path={Pages.pageNotFound.route} component={PageNotFound} authedUser={isAuthenticated} user={user} permission={Pages.pageNotFound.permission} userPermissions={userPermissions} /> */}
      <PrivateRoute
        path={Pages.accountDetails.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.accountDetails.permission}
        userPermissions={userPermissions}
        roles={Roles.accountDetails.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.notifications.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.notifications.permission}
        userPermissions={userPermissions}
        roles={Roles.notifications.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.paymentMethods.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.paymentMethods.permission}
        userPermissions={userPermissions}
        roles={Roles.paymentMethods.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.userSubscriptions.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.userSubscriptions.permission}
        userPermissions={userPermissions}
        roles={Roles.userSubscriptions.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.userOrders.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.userOrders.permission}
        userPermissions={userPermissions}
        roles={Roles.userOrders.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.userCompany.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.userCompany.permission}
        userPermissions={userPermissions}
        roles={Roles.userCompany.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.members.route}
        component={Members}
        authedUser={isAuthenticated}
        user={user}
        permission={hasOrganizationAdminModulesPermission(userPermissions) && Pages.members.permission}
        userPermissions={userPermissions}
        roles={Roles.members.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Roles.pageNotFound.route}
        component={PageNotFound}
        authedUser={isAuthenticated}
        userPermissions={userPermissions}
        userRole={userRole}
        user={user}
      />
      <Redirect exact from="/my-account" to={Pages.accountDetails.route} />
      <Redirect to={Pages.pageNotFound.route} />
    </Switch>
  ) : (
    <Switch>
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/register" component={SelectUserGroup} />
      <PublicRoute exact path="/register/create-account/:userGroup" component={CreateAccount} />
      {/*<PublicRoute exact path='/confirm-code' component={ConfirmationCode} />*/}
      <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
      <PrivateRoute
        path={Pages.dashbord.route}
        exact
        component={Dashboard}
        openRemoteModal={props.openRemoteModal}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.dashbord.permission}
        userPermissions={userPermissions}
        roles={Roles.dashbord.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.product.route}
        exact
        component={ProductsModern}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.product.permission}
        userPermissions={userPermissions}
        roles={Roles.product.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.productNotes.route}
        exact
        component={ProductNotes}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.productNotes.permission}
        userPermissions={userPermissions}
        roles={Roles.productNotes.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.myProducts.route}
        exact
        component={MyProducts}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.myProducts.permission}
        userPermissions={userPermissions}
        roles={Roles.myProducts.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.instrumentTracking.route}
        exact
        component={InstrumentTracking}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.instrumentTracking.permission}
        userPermissions={userPermissions}
        roles={Roles.instrumentTracking.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.monitoring.route}
        component={MonitoringModern}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.monitoring.permission}
        userPermissions={userPermissions}
        roles={Roles.monitoring.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.reports.route}
        component={Reports}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.reports.permission}
        userPermissions={userPermissions}
        roles={Roles.reports.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.documents.route}
        component={Documents}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.documents.permission}
        userPermissions={userPermissions}
        roles={Roles.documents.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.videos.route}
        component={Videos}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.videos.permission}
        userPermissions={userPermissions}
        roles={Roles.videos.roles}
        userRole={userRole}
      />
      {/* <PrivateRoute
        path={Pages.spareParts.route}
        component={Spareparts}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.spareParts.permission}
        userPermissions={userPermissions}
        roles={Roles.spareParts.roles}
        userRole={userRole}
      /> */}
      <PrivateRoute
        path={Pages.spareParts.route}
        component={SparepartsModern}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.spareParts.permission}
        userPermissions={userPermissions}
        roles={Roles.spareParts.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.firmware.route}
        component={FirmwareDownload}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.firmware.permission}
        userPermissions={userPermissions}
        roles={Roles.firmware.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.troubleshoot.route}
        component={Troubleshoot}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.troubleshoot.permission}
        userPermissions={userPermissions}
        roles={Roles.troubleshoot.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.reportProblem.route}
        exact
        component={ReportProblem}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.reportProblem.permission}
        userPermissions={userPermissions}
        roles={Roles.reportProblem.roles}
        userRole={userRole}
      />
      {/* A.S: Hidden until further notice */}
      {/* <PrivateRoute
        path={Pages.warranty.route}
        component={Warranty}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.warranty.permission}
        userPermissions={userPermissions}
        roles={Roles.warranty.roles}
        userRole={userRole}
      /> */}
      <PrivateRoute
        path={Pages.users.route}
        exact
        component={Users}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.users.permission}
        userPermissions={userPermissions}
        roles={Roles.users.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Roles.accountsManagement.route}
        exact
        component={ManageAccounts}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.accountsManagement.permission}
        userPermissions={userPermissions}
        roles={Roles.accountsManagement.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.companies.route}
        exact
        component={CompaniesManagement}
        authedUser={isAuthenticated}
        user={user}
        permission={hasAdminModulesPermission(userPermissions) && Pages.companies.permission}
        userPermissions={userPermissions}
        roles={Roles.companies.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Roles.policies.route}
        exact
        component={PoliciesPage}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.policies.permission}
        userPermissions={userPermissions}
        roles={Roles.policies.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Roles.events.route}
        component={Events}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.events.permission}
        userPermissions={userPermissions}
        roles={Roles.events.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.priceLists.route}
        component={PriceList}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.priceLists.permission}
        userPermissions={userPermissions}
        roles={Roles.priceLists.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.dealerBulletins.route}
        component={DealerBulletins}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.dealerBulletins.permission}
        userPermissions={userPermissions}
        roles={Roles.dealerBulletins.roles}
        userRole={userRole}
      />

      {props.remoteSession && (
        <PrivateRoute
          path={Pages.remoteAccessSession.route}
          exact
          component={RemoteAccessSession}
          disableRemoteSession={props.disableRemoteSession}
          authedUser={isAuthenticated}
          user={user}
          permission={Pages.remoteAccess.permission}
          userPermissions={userPermissions}
          roles={Roles.remoteAccess.roles}
          userRole={userRole}
        />
      )}
      
      <PrivateRoute
        path={Pages.remoteAccess.route}
        exact
        component={RemoteAccess}
        setRemoteSession={props.setRemoteSession}
        disableRemoteSession={props.disableRemoteSession}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.remoteAccess.permission}
        userPermissions={userPermissions}
        roles={Roles.remoteAccessSession.roles}
        userRole={userRole}
      />

      <PrivateRoute
        path={Pages.accountDetails.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.accountDetails.permission}
        userPermissions={userPermissions}
        roles={Roles.accountDetails.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.notifications.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.notifications.permission}
        userPermissions={userPermissions}
        roles={Roles.notifications.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Roles.pageNotFound.route}
        component={PageNotFound}
        authedUser={isAuthenticated}
        userPermissions={userPermissions}
        userRole={userRole}
        user={user}
      />
            <PrivateRoute
        path={Pages.colteneStore.route}
        exact
        component={ColteneStore}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.colteneStore.permission}
        userPermissions={userPermissions}
        roles={Roles.colteneStore.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.adminStore.route}
        exact
        component={AdminStore}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.adminStore.permission}
        userPermissions={userPermissions}
        roles={Roles.adminStore.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.adminOrderHistory.route}
        exact
        component={AdminOrderHistory}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.adminOrderHistory.permission}
        userPermissions={userPermissions}
        roles={Roles.adminOrderHistory.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.previewProductDetails.route}
        exact
        component={ProductDetails}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.previewProductDetails.permission}
        userPermissions={userPermissions}
        roles={Roles.previewProductDetails.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.productDetails.route}
        exact
        component={ProductDetails}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.productDetails.permission}
        userPermissions={userPermissions}
        roles={Roles.productDetails.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.businessRules.route}
        exact
        component={BusinessRules}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.businessRules.permission}
        userPermissions={userPermissions}
        roles={Roles.businessRules.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.services.route}
        exact
        component={Services}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.services.permission}
        userPermissions={userPermissions}
        roles={Roles.services.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.paymentMethods.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.paymentMethods.permission}
        userPermissions={userPermissions}
        roles={Roles.paymentMethods.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.userSubscriptions.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.userSubscriptions.permission}
        userPermissions={userPermissions}
        roles={Roles.userSubscriptions.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.userOrders.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={supportsColteneStore && Pages.userOrders.permission}
        userPermissions={userPermissions}
        roles={Roles.userOrders.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.userCompany.route}
        exact
        component={MyAccount}
        authedUser={isAuthenticated}
        user={user}
        permission={Pages.userCompany.permission}
        userPermissions={userPermissions}
        roles={Roles.userCompany.roles}
        userRole={userRole}
      />
      <PrivateRoute
        path={Pages.members.route}
        component={Members}
        authedUser={isAuthenticated}
        user={user}
        permission={hasOrganizationAdminModulesPermission(userPermissions) && Pages.members.permission}
        userPermissions={userPermissions}
        roles={Roles.members.roles}
        userRole={userRole}
      />
      <Redirect exact from="/my-account" to={Pages.accountDetails.route} />
      <Redirect to={Pages.pageNotFound.route} />
    </Switch>
  )
}

Routes.propTypes = {
  userRole: PropTypes.string,
  user: PropTypes.object,
  userPermissions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  //remoteSession: PropTypes.object,
  disableRemoteSession: PropTypes.func,
  setRemoteSession: PropTypes.func,
}

function mapStateToProps({ authedUser, userPermissions }) {
  return {
    userRole: authedUser ? authedUser.userGroup.toLowerCase() : "",
    user: authedUser ? authedUser : { cognitoSub: "" },
    userPermissions: userPermissions || {},
  }
}

export default connect(mapStateToProps)(Routes)
