import React, { Fragment, useEffect, useState} from 'react'
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";
import { toast } from "../common/Toast"
import LoadingSpinner from "../common/LoadingSpinner";
import { ArrowLeftIcon, TrashCan } from './../../icons/index'
import { useWindowSize } from "../../libs/hooks";
import {
  deleteNotification,
  getNotifications,
  postNotification,
  updateNotification
} from "../../utils/requests/notifications";
import { checkPermission, userNotificationWritePermission, userNotificationDeletePermission } from '../../utils/permissionValidation';
import './notification-settings.scss'

const NotificaitonSettings = (props) => {
  const serialNumber = props?.serialNumber
  const model = props?.model
  const {t} = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [maintenanceToggle, setMaintenanceToggle] = useState(true)
  const [sort, setSort] = useState(false)
  const [upcomingMaintenanceToggle, setUpcomingMaintenanceToggle] = useState(true)
  const [usersNotifications, setUsersNotifications] = useState([])
  const [newUserNotification, setNewUserNotification] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    if(!usersNotifications.length) {
      setShowSpinner(true)
      getAllNotifications()
    }
    if (usersNotifications && sort) {
      arrangeArr(usersNotifications)
    }
  }, [sort])

  // const toggleHandler = (currentState, setter) => {
  //   setter(!currentState)
  // }

  const fieldsHandler = (e) => {
    if (e.target.name === 'fault') {
      return setNewUserNotification({
        ...newUserNotification,
        [e.target.name]: !newUserNotification?.fault
      })
    }
    if (e.target.name === 'success') {
      return setNewUserNotification({
        ...newUserNotification,
        [e.target.name]: !newUserNotification?.success
      })
    }

    setNewUserNotification({
      ...newUserNotification,
      [e.target.name]: e.target.value.trim()
    })
  }

  const arrangeArr = (arr) => {
    if(arr.length) {
      const arrangedArr = arr.sort((a, b) => a && a.email && b && b.email ? a.email.localeCompare(b.email) : false)
      let sortedArray = sort ? arrangedArr : arrangedArr.reverse()

      if (props.currentUserBranchAdmin) {
        sortedArray = sortedArray.filter(item => item.email !== props.currentUserBranchAdmin)

        const adminItem = arr.find(item => item.email === props.currentUserBranchAdmin)

        if (adminItem) {
          sortedArray.unshift(adminItem)
        }
      }

      return sortedArray
    }
  }

  const changeSort = () => {
    setSort(!sort)
  }
  
  const isValidConfigurationEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidFormat =  emailRegex.test(String(email).toLowerCase())
    if(!isValidFormat) {
      toast.error(t("error.email"))
      return 
    }
    const doesNotExist = usersNotifications.find((config) => config.email === email) === undefined
    if(!doesNotExist) {
      toast.error(t("error.email_exists"))
      return 
    }
    return true
  }

  const addUser = () => {
    if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
      return
    }
    
    if(!isValidConfigurationEmail(newUserNotification.email)) {
      return
    }

    setShowSpinner(true)
    let body = {
      user: props.authedUser.email,
      recipient: newUserNotification && newUserNotification.email ? newUserNotification.email : '',
      sn: serialNumber,
      model: model
    }
    if(newUserNotification?.fault || newUserNotification.success) {
      body = {...body,
        cycle_notification_option: newUserNotification.fault && newUserNotification.success ? 1 : newUserNotification.fault && !newUserNotification.success ? 2 : 3
      }
    }
    
    postNotification(body)
      .then(()=>{
        getAllNotifications()
        toast.success(t("notifications.success-add-notification-settings"))
      })
    setNewUserNotification({})
  }

  const getAllNotifications = () => {
    getNotifications(serialNumber)
      .then(r => {
        setUsersNotifications(r.data)
        setShowSpinner(false)
      })
  }

  const updateUser = (notification, key) => {
    setShowSpinner(true)
    try {
      if (notification.cycle_notification_option === 1 && key === 'fault') {
        const body = {
          cycle_notification_option: 3
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      if (notification.cycle_notification_option === 1 && key === 'success') {
        const body = {
          cycle_notification_option: 2
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      if (notification.cycle_notification_option === 2 && key === 'fault') {
        const body = {
          cycle_notification_option: 2
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      if (notification.cycle_notification_option === 2 && key === 'success') {
        const body = {
          cycle_notification_option: 1
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      if (notification.cycle_notification_option === 3 && key === 'fault') {
        const body = {
          cycle_notification_option: 1
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      if (notification.cycle_notification_option === 3 && key === 'success') {
        const body = {
          cycle_notification_option: 3
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      if (notification.cycle_notification_option === 4 && key === 'fault') {
        const body = {
          cycle_notification_option: 2
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      if (notification.cycle_notification_option === 4 && key === 'success') {
        const body = {
          cycle_notification_option: 3
        }
        updateNotification(notification.id, body)
          .then(()=> {
            getAllNotifications()
          })
      }
      toast.success(t("notifications.success-edit-notification-settings"))
    } catch (error) {
      toast.error(t("error.something_wrong"))
    }
  }

  const deleteUser = (id) => {
    if (!checkPermission(props.userPermissions, userNotificationDeletePermission)) {
      return
    }

    setShowSpinner(true)
    deleteNotification(id).then(r => {
      getAllNotifications()
      toast.success(t("notifications.success-delete-notification-settings"))
    })
  }
  
  return (
    <div className="notification-wrapper d-flex flex-column">
      <h1>{t('labels.notifications')}</h1>
      {isMobile ?
        <div className="mobile d-flex flex-column">
          <div className="send-to">
            {t('notifications.send-to')}
          </div>
          <input type="email" name="email" placeholder={t('notifications.enter_email')} onChange={fieldsHandler}
                 value={newUserNotification?.email || ''}/>
          <div className="d-flex flex-justify-between cycle">
            <div>
              {t('notifications.cycle-summary')}
            </div>
            <div className="checkbox d-flex flex-align-center">
              <input type="checkbox" name="fault" onChange={fieldsHandler} checked={newUserNotification?.fault}/>
              <div>{t('notifications.fault')}</div>
              <input type="checkbox" name="success" onChange={fieldsHandler} checked={newUserNotification?.success}/>
              <div>{t('notifications.success')}</div>
            </div>
          </div>
          {/*<div className="d-flex flex-justify-between maintenance">*/}
          {/*  <div>*/}
          {/*    {t('notifications.maintenance')}*/}
          {/*  </div>*/}
          {/*  {maintenanceToggle ? (*/}
          {/*      <div onClick={() => toggleHandler(maintenanceToggle, setMaintenanceToggle)}>*/}
          {/*        {t('notifications.enable')}*/}
          {/*        <ToggleOnIcon className="on"/>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*    :*/}
          {/*    (*/}
          {/*      <div onClick={() => toggleHandler(maintenanceToggle, setMaintenanceToggle)}>*/}
          {/*        {t('notifications.disable')}*/}
          {/*        <ToggleOffIcon className="off"/>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  }*/}
          {/*</div>*/}
          {/*<div className="d-flex flex-justify-between upcoming-maintenance">*/}
          {/*  <div>*/}
          {/*    {t('notifications.maintenance')}*/}
          {/*  </div>*/}
          {/*  {upcomingMaintenanceToggle ? (*/}
          {/*      <div onClick={() => toggleHandler(upcomingMaintenanceToggle, setUpcomingMaintenanceToggle)}>*/}
          {/*        {t('notifications.enable')}*/}
          {/*        <ToggleOnIcon className="on"/>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*    :*/}
          {/*    (*/}
          {/*      <div onClick={() => toggleHandler(upcomingMaintenanceToggle, setUpcomingMaintenanceToggle)}>*/}
          {/*        {t('notifications.disable')}*/}
          {/*        <ToggleOffIcon className="off"/>*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  }*/}
          {/*</div>*/}
          <button className="add" onClick={addUser}>{t('notifications.add')}</button>
          {usersNotifications && usersNotifications.length ? arrangeArr(usersNotifications).map((user, index) => (
            <div key={index} className="users-wrapper">
              <div className="send-to">
                {user.email}
              </div>
              <div className="d-flex flex-justify-between cycle">
                <div>
                  {t('notifications.cycle-summary')}
                </div>
                <div className="checkbox d-flex flex-align-center">
                  <input type="checkbox" name="fault" onChange={fieldsHandler} checked={user?.cycle_notification_option === 1 || user?.cycle_notification_option === 2}/>
                  <div>{t('notifications.fault')}</div>
                  <input type="checkbox" name="success" onChange={fieldsHandler} checked={user?.cycle_notification_option === 1 || user?.cycle_notification_option === 3}/>
                  <div>{t('notifications.success')}</div>
                </div>
              </div>
              {/*<div className="d-flex flex-justify-between maintenance">*/}
              {/*  <div>*/}
              {/*    {t('notifications.maintenance')}*/}
              {/*  </div>*/}
              {/*  {user.maintenance ? (*/}
              {/*      <div onClick={() => toggleHandler(maintenanceToggle, setMaintenanceToggle)}>*/}
              {/*        {t('notifications.enable')}*/}
              {/*        <ToggleOnIcon className="on"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*    :*/}
              {/*    (*/}
              {/*      <div onClick={() => toggleHandler(maintenanceToggle, setMaintenanceToggle)}>*/}
              {/*        {t('notifications.disable')}*/}
              {/*        <ToggleOffIcon className="off"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*  }*/}
              {/*</div>*/}
              {/*<div className="d-flex flex-justify-between upcoming-maintenance">*/}
              {/*  <div>*/}
              {/*    {t('notifications.maintenance')}*/}
              {/*  </div>*/}
              {/*  {user.upcoming_maintenance ? (*/}
              {/*      <div onClick={() => toggleHandler(upcomingMaintenanceToggle, setUpcomingMaintenanceToggle)}>*/}
              {/*        {t('notifications.enable')}*/}
              {/*        <ToggleOnIcon className="on"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*    :*/}
              {/*    (*/}
              {/*      <div onClick={() => toggleHandler(upcomingMaintenanceToggle, setUpcomingMaintenanceToggle)}>*/}
              {/*        {t('notifications.disable')}*/}
              {/*        <ToggleOffIcon className="off"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*  }*/}
              {/*</div>*/}
              <div className="delete">
                {(!props.currentUserBranch || props.currentUserBranch !== user.branches_id) && (
                  <TrashCan onClick={(e) => {
                      if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
                        return
                      }

                      e.stopPropagation()
                      deleteUser(user.id)
                    }}
                  />
                )}
              </div>
            </div>
            ))
            :
            ''
          }
        </div>
        :
        <Fragment>
          <div className="table">
            <div className="send-to d-flex flex-column">
              <div
                onClick={() => changeSort()}
                className="cursor-pointer"
              >
                {t('notifications.send-to')}
                <ArrowLeftIcon
                  className="down"/>
                <ArrowLeftIcon className="up"/>
              </div>
              <div className="table-header">
                <input type="email" name="email" placeholder={t('notifications.enter_email')} onChange={fieldsHandler}
                       value={newUserNotification?.email || ''}/>
              </div>
            </div>
            <div className="cycle-summary-notification d-flex flex-column">
              <div>{t('notifications.cycle-summary')}</div>
              <div className="table-header d-flex">
                <input type="checkbox" name="fault" onChange={fieldsHandler} checked={newUserNotification?.fault}/>
                <div>{t('notifications.fault')}</div>
                <input type="checkbox" name="success" onChange={fieldsHandler} checked={newUserNotification?.success}/>
                <div>{t('notifications.success')}</div>
              </div>
            </div>
            {/*<div className="maintenance-notification">*/}
            {/*  <div>{t('notifications.maintenance')}</div>*/}
            {/*  <div className="table-header">*/}
            {/*    {maintenanceToggle ? (*/}
            {/*        <div onClick={() => toggleHandler(maintenanceToggle, setMaintenanceToggle)}>*/}
            {/*          {t('notifications.enable')}*/}
            {/*          <ToggleOnIcon className="on"/>*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*      :*/}
            {/*      (*/}
            {/*        <div onClick={() => toggleHandler(maintenanceToggle, setMaintenanceToggle)}>*/}
            {/*          {t('notifications.disable')}*/}
            {/*          <ToggleOffIcon className="off"/>*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="upcoming-maintenance-notification">*/}
            {/*  <div>{t('notifications.upcoming-maintenance')}</div>*/}
            {/*  <div className="table-header">*/}
            {/*    {upcomingMaintenanceToggle ? (*/}
            {/*        <div onClick={() => toggleHandler(upcomingMaintenanceToggle, setUpcomingMaintenanceToggle)}>*/}
            {/*          {t('notifications.enable')}*/}
            {/*          <ToggleOnIcon className="on"/>*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*      :*/}
            {/*      (*/}
            {/*        <div onClick={() => toggleHandler(upcomingMaintenanceToggle, setUpcomingMaintenanceToggle)}>*/}
            {/*          {t('notifications.disable')}*/}
            {/*          <ToggleOffIcon className="off"/>*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="actions">
              <div>{t('notifications.actions')}</div>
              <div className="table-header">
                <button onClick={addUser} disabled={newUserNotification.email ? false : true}>{t('notifications.add')}</button>
              </div>
            </div>
          </div>
          {usersNotifications && usersNotifications.length ? arrangeArr(usersNotifications).map((user, index) => (
            <div className="table" key={index}>
              <div className="table-body">{user.email}</div>
              <div className="table-body d-flex flex-align-center">
                <input type="checkbox" name="fault" checked={user.cycle_notification_option === 1 || user.cycle_notification_option === 2}
                       onChange={() => updateUser(user, 'fault')}/>
                <div>{t('notifications.fault')}</div>
                <input type="checkbox" name="success" checked={user.cycle_notification_option === 1 || user.cycle_notification_option === 3} onChange={() => updateUser(user, 'success')}/>
                <div>{t('notifications.success')}</div>
              </div>
              {/*<div>*/}
              {/*  {user.maintenance ? (*/}
              {/*      <div className="table-body">*/}
              {/*        {t('notifications.enable')}*/}
              {/*        <ToggleOnIcon className="on"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*    :*/}
              {/*    (*/}
              {/*      <div className="table-body">*/}
              {/*        {t('notifications.disable')}*/}
              {/*        <ToggleOffIcon className="off"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*  }*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  {user.upcoming_maintenance ? (*/}
              {/*      <div className="table-body">*/}
              {/*        {t('notifications.enable')}*/}
              {/*        <ToggleOnIcon className="on"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*    :*/}
              {/*    (*/}
              {/*      <div className="table-body">*/}
              {/*        {t('notifications.disable')}*/}
              {/*        <ToggleOffIcon className="off"/>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*  }*/}
              {/*</div>*/}
              <div className="table-body d-flex">
                {(!props.currentUserBranch || props.currentUserBranch !== user.branches_id) && (
                  <TrashCan
                    className="delete-icon"
                    onClick={(e) => {
                      if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
                        return
                      }

                      e.stopPropagation()
                      deleteUser(user.id)
                    }}
                  />
                )}
              </div>
            </div>
          )) : ''}
        </Fragment>
      }
      {showSpinner &&
      <div className={"spinner-wrapper"}>
        <LoadingSpinner/>
      </div>
      }
    </div>
  )
}
function mapStateToProps({authedUser, userPermissions, userCompany }) {
  return {
    authedUser,
    userPermissions,
    currentUserBranch: userCompany?.branchId,
    currentUserBranchAdmin: userCompany?.companyAdmin
  }
}

export default connect(mapStateToProps)(NotificaitonSettings)
