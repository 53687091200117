import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import ShippingAddress from "./ShippingAddress"
import SimpleButton from "../common/SimpleButton"
import { CloseCircleBlackIcon, ColtenePlaceholderProduct, TrashCan } from "../../icons"
import { formatStoreProductPrice } from "../../utils/filters/filters"
import { setInBasket } from "../../actions/colteneStore"
import "./store-basket.scss"

const StoreBasket = (props) => {
  const { t } = useTranslation()
  const { basket, checkoutLink, isLoading, toggle, onToggle } = props
  const [isOpen, setIsOpen] = useState(!isLoading && toggle)
  const [showHeaderBorder, setShowHeaderBorder] = useState(false)
  const [showShippingAddress, setShowShippingAddress] = useState(false)
  const pageContentRef = useRef()

  useEffect(() => {
    if (checkoutLink?.length > 0) {
      window.location.replace(checkoutLink)
    }
  }, [checkoutLink])

  useEffect(() => {
    if (!isLoading && toggle) {
      setTimeout(() => {
        setIsOpen(true)
      }, 60)
    }
  }, [isLoading, toggle])

  const handleOnClose = () => {
    setIsOpen(false)
    onToggle()
  }

  const onScroll = () => {
    if (pageContentRef.current) {
      const { scrollTop } = pageContentRef.current

      setShowHeaderBorder(scrollTop > 0)
    }
  }

  const getPrice = (prices) => {
    const price = prices.find((plan) => plan.isSelectedPlan) || prices[0]

    if (!price) {
      return t("coltene_store.cart.price_not_available")
    }

    return formatStoreProductPrice(price.amount, price.currencyCode, price.recurrenceInterval, price.recurrenceIntervalCount)
  }

  const getAmount = (prices) => {
    const prodAmount = prices.find((plan) => plan.isSelectedPlan) || prices[0]
    return prodAmount.amount || 0
  }

  const count = Object.keys(basket)
    .map((pId) => basket[pId].quantity)
    .reduce((a, b) => a + b, 0)

  const total = Object.keys(basket)
    .map((p) => basket[p].quantity * getAmount(basket[p].prices))
    .reduce((a, b) => a + b, 0)
    .toFixed(2)

  const buildPaymentPlan = (product) => {
    const selectedPlan = product.prices.find((plan) => plan.isSelectedPlan)

    return (
      <div className="product-plan">
        <div className="input-value">
          {t([`coltene_store.create_product.${selectedPlan.recurrenceInterval}_plan`,  selectedPlan.recurrenceInterval || ""])}
        </div>
      </div>
    )
  }

  return (
    <>
      {!isLoading && toggle &&
        <div className="basket-modal-page">
          <div className="basket-modal-overflow" onClick={() => handleOnClose()} />
          <div className={"basket-modal-wrapper" + (isOpen ? " open" : " hide") }>
            <div className={`basket-header ${showHeaderBorder ? "header-border" : ""}`}>
              <div className="title">{t("coltene_store.cart.my_cart")}</div>
              <div className="items-number">{count}</div>
              <div className="back-button">
                <CloseCircleBlackIcon onClick={() => handleOnClose()} />
              </div>
            </div>
            <div className="basket-body" onScroll={() => onScroll()} ref={pageContentRef}>
              {Object.keys(basket).map((p, index) =>
                <div 
                  key={`product-${index}`}
                  className="basket-product-wrapper"
                >
                  <div className="product-image">
                    {basket[p].imageUrl ? <img alt="complex" src={basket[p].imageUrl} /> : <ColtenePlaceholderProduct />}
                  </div>
                  <div className="product-details">
                    <div className="product-name">{basket[p].title}</div>
                    {basket[p].subtitle && 
                      <div className="product-description">
                        {basket[p].subtitle}
                      </div>
                    }
                    <div className={"product-price" + (basket[p].isDigital ? " with-order-2": "")}>
                      {getPrice(basket[p].prices)}
                    </div>
                    {basket[p].quantity > 0 && !basket[p].isDigital && (
                      <div className="product-quantity">
                        <div className="quantity-change-button minus" onClick={() => props.actions.setInBasket(basket[p], basket[p].quantity - 1)}>
                          -
                        </div>
                        <div className="quantity">{basket[p].quantity}</div>
                        <div className="quantity-change-button" onClick={() => props.actions.setInBasket(basket[p], basket[p].quantity + 1)}>
                          +
                        </div>
                      </div>
                    )}
                    {basket[p].quantity > 0 && basket[p].isDigital && (
                      buildPaymentPlan(basket[p])
                    )}
                  </div>
                  <div 
                    className="remove-button"
                    onClick={() => props.actions.setInBasket(basket[p], 0)}
                  >
                    <TrashCan />
                  </div>
                </div>)
              }
              {Object.keys(basket).length === 0 && 
                <div className="no-products">
                  {t("coltene_store.cart.no_products")}
                </div>
              }
            </div>
            <div className="basket-footer">
              <div className="cart-total">
                <div className="total-label">
                  {t("coltene_store.cart.total").toUpperCase()}
                </div>
                <div className="number-label">
                  {formatStoreProductPrice(total, basket[Object.keys(basket)[0]]?.prices[0].currencyCode)}
                </div>
              </div>
              <div className="actions">
                <SimpleButton 
                  onClick={() => handleOnClose()}
                  className="cancel-button"
                >
                  {t("coltene_store.cart.continue_shopping")}
                </SimpleButton>
                <SimpleButton
                  className="submit-button"
                  disabled={Object.keys(basket).length === 0}
                  onClick={() => {
                    handleOnClose()
                    setShowShippingAddress(true)
                  }}
                >
                  {t("coltene_store.cart.checkout")}
                </SimpleButton>
              </div>
              {/* <div className="extras">
                {t("coltene_store.cart.text_under_checkout")}
              </div> */}
            </div>
          </div>
        </div>
      }

      {showShippingAddress && 
        <ShippingAddress
          isOpen={showShippingAddress}
          handleVisibility={setShowShippingAddress}
        />
      }
    </>
  )
}

function stateToProps({ colteneStore }) {

  const rawBasket = colteneStore?.basket || {};
  const filteredBasket = Object.fromEntries(
    Object.entries(rawBasket).filter(([key, value]) => value.quantity !== 0)
  );
  
  return {
    basket: filteredBasket || {},
    isLoading: colteneStore?.isLoading,
    checkoutLink: colteneStore?.checkoutLink,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setInBasket
      },
    dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(StoreBasket)
