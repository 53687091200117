import React, { useRef, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Auth } from "aws-amplify"
import useOnClickOutside from "./useOnClickOutside"
import { handleUserLogout } from "../../actions/root"
import { useAppContext } from "../../libs/contextLib"
import { Pages } from "../../utils/pages"
import { keyboardEvents } from "../../utils/keyboardEvents"
import { hasNotificationsPermission, hasStorePermission, checkPermission, bellNotificationsPermissions, myOrganizationPermission } from "../../utils/permissionValidation"
import "./account-menu.scss"

const AccountMenu = (props) => {
  const { setAuthenticated, supportsColteneStore } = useAppContext()
  const { t } = useTranslation()
  const ref = useRef()
  const [userFirstName, setUserFirstName] = useState(null)
  const [userLastName, setUserLastName] = useState(null)
  const [userRole, setUserRole] = useState(null)
  const [userAvatar, setUserAvatar] = useState(null)
  const [userPermissions, setUserPermissions] = useState([])

  useEffect(() => {
    setUserFirstName(props.authedUser?.firstname)
    setUserLastName(props.authedUser?.lastname)
    setUserRole(props.authedUser?.userGroup?.toLowerCase())
    setUserAvatar(props.userAvatar)
  }, [props.authedUser])

  useEffect(() => {
    const permissionArr = props.userPermissions ? (Array.isArray(props.userPermissions) ? props.userPermissions : Object.values(props.userPermissions)) : []
    setUserPermissions(permissionArr)
  }, [props.userPermissions])

  const handleLogout = async (e) => {
    const { dispatch } = props
    Auth.signOut()
      .then(() => setAuthenticated(false))
      .then(() => {
        props.toggleShowOnlineAccess(false)
        props.closeNavDisplay()
        props.disableRemoteSession()
        dispatch(handleUserLogout())
      })
    props.setHideAccountMenu(true)
  }

  useOnClickOutside(ref, () => {
    props.setHideAccountMenu(true)
  })

  return (
    <div ref={ref} className="account-menu-container d-flex flex-column">
      <div className="avatar-wrapper">
        <div className="avatar-img">
          {userAvatar ? (
            <img className="image" src={userAvatar} alt="user-avatar" />
          ) : (
            <div className="avatar-initials">
              <span>{`${userFirstName?.charAt(0)}${userLastName?.charAt(0)}`}</span>
            </div>
          )}
        </div>
        <div className="avatar-data">
          <span className="name">
            {userFirstName || ''} {userLastName || ''}
          </span>
          <span className="role">{t([`register.${userRole?.split("-")[0]}`, ''])}</span>
        </div>
      </div>
      <Link to={Pages.accountDetails.route} onClick={() => props.setHideAccountMenu(true)}>
        <div className="nav-option-wrapper with-margin-top">
          {t("nav.my-account")}
        </div>
      </Link>
      {supportsColteneStore && hasStorePermission(userPermissions) && (
        <>
          <Link to={Pages.paymentMethods.route} onClick={() => props.setHideAccountMenu(true)}>
            <div className="nav-option-wrapper">
              {t("account.payment_methods")}
            </div>
          </Link>
          <Link to={Pages.userSubscriptions.route} onClick={() => props.setHideAccountMenu(true)}>
            <div className="nav-option-wrapper">
              {t("account.my_subscriptions")}
            </div>
          </Link>
          <Link to={Pages.userOrders.route} onClick={() => props.setHideAccountMenu(true)}>
            <div className="nav-option-wrapper">
              {t("account.my_orders")}
            </div>
          </Link>
        </>
      )}
      {hasNotificationsPermission(userPermissions) && checkPermission(userPermissions, bellNotificationsPermissions) && (
        <Link to={Pages.notifications.route} onClick={() => props.setHideAccountMenu(true)}>
          <div className="nav-option-wrapper">
            {t("account.notifications")}
          </div>
        </Link>
      )}
      {checkPermission(userPermissions, myOrganizationPermission) && (
        <Link to={Pages.userCompany.route} onClick={() => props.setHideAccountMenu(true)}>
          <div className="nav-option-wrapper">
            {t("account.my_organization")}
          </div>
        </Link>
      )}
      <div className="separator with-margin-top" />
      <div 
        tabIndex={0}
        role="button"
        className="nav-option-wrapper with-margin-top" 
        onClick={handleLogout}
        onKeyDown={(event) => {
          if (event.key === keyboardEvents.ENTER) {
            handleLogout()
          }
        }}
      >
        <span className="signout-btn">{t("access.signout")}</span>
      </div>
    </div>
  )
}

function mapStateToProps({ authedUser, userPermissions }) {
  return {
    authedUser,
    userPermissions,
  }
}

export default connect(mapStateToProps)(AccountMenu)
