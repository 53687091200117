import React, { Fragment, useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ConfirmationModal from '../common/ConfirmationModal'
import { SelectMenu } from "../common/SelectMenu"
import { toast } from "../common/Toast"
import {
  MenuKebabVerticalIcon,
  CaretDownIcon,
  MenuKebabVerticalBackgroundIcon,
  PendingIcon,
  DisabledIcon,
  InformationIcon,
} from "../../icons"
import { loadRequests, changeRequestStatus, clearMembersMessages, loadCurrentCompany } from "../../actions/members"
import { loadCompanyRoles } from "../../actions/companies"
import { organizationRequestsWritePermission } from "../../utils/permissionValidation"
import { convertTimeWithTAndZ } from "../../utils/filters/date"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"

const orderingFields = [
  {
    field: "email",
    order: "asc",
    key: "email_asc",
  },
  {
    field: "email",
    order: "desc",
    key: "email_desc",
  },
  {
    field: "lastModifyAt",
    order: "asc",
    key: "lastModifyAt_asc",
  },
  {
    field: "lastModifyAt",
    order: "desc",
    key: "lastModifyAt_desc",
  },
  {
    field: "requestType",
    order: "asc",
    key: "requestType_asc",
  },
  {
    field: "requestType",
    order: "desc",
    key: "requestType_desc",
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc",
  }
]

const Requests = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [requestsTableData, setRequestsTableData] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [statusesFilter, setStatusesFilter] = useState([])
  const [searchParam, setSearchParam] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] = useState(false)
  const [requestIdToAccept, setRequestIdToAccept] = useState(null)
  const [currentRoleName, setCurrentRoleName] = useState('')
  const [newRoleIdToAccept, setNewRoleIdToAccept] = useState(null)
  const [roleOptions, setRoleOptions] = useState([])
  const [expandedRequest, setExpandedRequest] = useState(0)
  const requestsStatuses = ["invited", "requested", "expired"]

  useLayoutEffect(() => {
    if (!props.companyId) {
      props.actions.loadCurrentCompany()
    }
  }, [props.actions])

  useEffect(() => {
    if (!props.companyId) {
      return
    }

    if (props.companyRoles.length === 0) {
      props.actions.loadCompanyRoles(props.companyId)
    }
  }, [props.companyId])

  useEffect(() => {
    if (props.companyRoles.length > 0) {
      const mappedRoles = props.companyRoles.map((role) => ({
        key: role.id,
        value: role.name.replace(/Dealer |Dentist /g, ""),
      }))

      setRoleOptions(mappedRoles)
    }
  }, [props.companyRoles])

  useEffect(() => {
    const tableData = props.requests.map((request) => {
      return {
        identifier: request.id,
        email: request.email,
        date: convertTimeWithTAndZ(request.lastModifyAt),
        requestType: request.requestType,
        status: request.status,
        actions: [
          ...(request.status === "requested" ? [
            {
              label: t("labels.approve"),
              onActionClick: (e) => { e.stopPropagation(); openAcceptConfirmationModal(request.id, request.role?.name) },
              requiredPermission: organizationRequestsWritePermission,
            },
            {
              label: t("labels.reject"),
              onActionClick: (e) => { e.stopPropagation(); handleChangeRequestStatus(request.id, "reject") },
              requiredPermission: organizationRequestsWritePermission,
            }
          ] : []),
          ...(request.status === "expired" ? [{
            label: t("labels.resend"),
            onActionClick: (e) => { e.stopPropagation(); handleChangeRequestStatus(request.id, "resend") },
            requiredPermission: organizationRequestsWritePermission,
          }] : []),
          ...(request.status === "invited" || request.status === "expired" ? [{
            label: request.status === "invited" ? t("labels.cancel") : t("labels.remove"),
            onActionClick: (e) => { e.stopPropagation(); handleChangeRequestStatus(request.id, "cancel") },
            requiredPermission: organizationRequestsWritePermission,
          }] : []),
        ]
      }
    })

    setRequestsTableData(tableData)
  }, [props.requests])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearMembersMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearMembersMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadRequests(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters && props.companyId) {
          setSelectedPage(1)
          handleLoadRequests(true)
        }

        setShouldApplyFilters(true)
      },
      searchParam ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [props.companyId, searchParam, statusesFilter, orderBy])

  const handleLoadRequests = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    props.actions.loadRequests(props.companyId, queryParams, withReset)
      .finally(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParam(value)
    } else if (value?.length === 0 && searchParam?.length > 0) {
      setShowSpinner(true)
      setSearchParam(value)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setStatusesFilter([])
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParam) {
      queryParams = { ...queryParams, query: searchParam }
    }

    if (statusesFilter.length > 0) {
      queryParams = { ...queryParams, status: statusesFilter }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`dealer-portal.${orderBy.key}`) : t("events.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`dealer-portal.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const openAcceptConfirmationModal = (id, roleName) => {
    setRequestIdToAccept(id)
    setCurrentRoleName(roleName)
    setShowAcceptConfirmationModal(true)
  }

  const handleChangeRequestStatus = (requestId, action) => {
    if (!props.companyId) {
      return
    }

    props.actions.changeRequestStatus(props.companyId, requestId, { 
      action: action,
      ...(action === "accept" && newRoleIdToAccept && { roleId: newRoleIdToAccept })
    })
  }
 
  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  const renderRequestStatus = (status) => {
    switch (status) {
      case requestsStatuses[0]:
        return (
          <div className="status-pending">
            <PendingIcon />
            <p>{t("status.invited")}</p>
          </div>
        )
      case requestsStatuses[1]:
        return (
          <div className="status-pending">
            <PendingIcon />
            <p>{t("status.requested")}</p>
          </div>
        )
      case requestsStatuses[2]:
        return (
          <div className="status-inactive">
            <DisabledIcon />
            <p>{t("status.expired")}</p>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Fragment>
      <div className="requests-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={statusesFilter.length > 0}
            counterBadge={statusesFilter.length}
          />
        </div>
        {isMobile && requestsTableData.length > 0 &&
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        }
        {!isMobile && requestsTableData.length > 0 && 
          <GenericTable
            data={requestsTableData}
            headers={[
              {
                title: t("dealer-portal.date"),
                orderKey: "lastModifyAt",
              },
              {
                title: t("dealer-portal.email"),
                orderKey: "email",
              },
              {
                title: t("dealer-portal.request_type"),
                orderKey: "requestType",
              },
              {
                title: t("dealer-portal.request_status"),
                orderKey: "status",
              },
              {
                title: "",
              },
            ]}
            keys={[
              'date',
              'email',
              'requestType',
              'status',
              'action',
            ]}
            keyRenderer={{
              requestType: (item) => {
                return (
                  <>
                    {t(`dealer-portal.${item.requestType}`)}
                  </>
                )
              },
              status: (item) => {
                return (
                  <div className="status-wrapper">
                    {renderRequestStatus(item.status)}
                  </div>
                )
              },
              action: (item) => {
                return (
                  <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                    {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === item["identifier"] &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={item.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                )
              }
            }}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        }
        {isMobile && requestsTableData.map((request, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedRequest(index)}
          >
            <div className={"card-item" + (expandedRequest !== index ? " align-center" : "")}>
              <div className="card-item-title">{t("dealer-portal.email")}</div>
              <div className={"card-item-body" + (expandedRequest !== index ? " align-center" : "")}>
                <div>{request.email}</div>
                <div 
                  className="card-actions"
                  onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                >
                  {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === index &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={request.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              </div>
            </div>
            {expandedRequest === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t('dealer-portal.date')}</div>
                  <div className="card-item-body">{request.date}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.request_type")}</div>
                  <div className="card-item-body">{t(`dealer-portal.${request.requestType}`)}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.request_status")}</div>
                  <div className="card-item-body card-item-body-status">
                    {renderRequestStatus(request.status)}
                  </div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && requestsTableData.length === 0 && (
          <EmptyPageResults
            title={t("dealer-portal.no_requests_found")}
            subtitle={t("dealer-portal.no_requests_found_subtitle")}
          />
        )}
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t("buttons.load_more")}
            </SimpleButton>
          </div>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("dealer-portal.request_status"),
              type: FilterType.multiSelect,
              dataset: requestsStatuses,
              input: statusesFilter,
              output: (filterStatuses) => {
                setStatusesFilter(filterStatuses)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showAcceptConfirmationModal && (
        <ConfirmationModal
          className="accept-member-confirmation"
          onToggle={() => {
            setNewRoleIdToAccept(null)
            setShowAcceptConfirmationModal(false)
          }}
          message={t("dealer-portal.accept_member_confirmation")}
          onCancel={()=> {
            setNewRoleIdToAccept(null)
            setShowAcceptConfirmationModal(false)
          }}
          onAccept={() => {
            handleChangeRequestStatus(requestIdToAccept, "accept")
            setShowAcceptConfirmationModal(false)
          }}
          acceptButtonText={t("labels.confirm")}
          requiredPermission={organizationRequestsWritePermission}
        >
          <div className="member-confirmation-wrapper">
            <div className="disclaimer">
              <p>
                {t("dealer-portal.accept_member_disclaimer")}
              </p>
            </div>
            <div className="title">{t("dealer-portal.current_role")}</div>
            <input
              type="text" 
              className={"input-item"}
              value={currentRoleName?.split("-")[0] || ""}
              disabled
            />
            <div className="title">
              {`${t("dealer-portal.new_role")} (${t("labels.optional")})`}
              <InformationIcon onClick={() => props.showRoleDetails()}/>
            </div>
            <SelectMenu
              options={roleOptions}
              onChange={(option) => setNewRoleIdToAccept(option.key)}
              placeholder={t("dealer-portal.select_new_role")}
            />
          </div>
        </ConfirmationModal>
      )}
    </Fragment>
  )
}

function stateToProps({ members, companies }) {
  return {
    companyId: members?.companyId,
    requests: members?.requests || [],
    isLoading: members?.isLoading || companies?.isLoading,
    canLoadMore: members?.canLoadMoreRequests,
    errorMessage: members?.errorMessage,
    successMessage: members?.successMessage,
    companyRoles: companies?.roles || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadCurrentCompany,
        loadRequests,
        loadCompanyRoles,
        changeRequestStatus,
        clearMembersMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Requests)
