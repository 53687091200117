import React, { useState } from "react";
import TemperatureIcon from "../../icons/temperature.svg";
import PressureIcon from "../../icons/pressure.svg";
import HoldTimeIcon from "../../icons/hold_time.svg";
import ArrowLeft from "../../icons/arrow_left.svg";
import ArrowRight from "../../icons/arrow_right.svg";
import { useTranslation } from "react-i18next";
import CycleDetailsModal from "../common/CycleDetailsModal";
import { toast } from "../common/Toast"
import { getProductCyclePrintout } from "../../utils/requests/productsAPI";
import { getCycleDetails } from "../../utils/requests/trackingAPI";
import { AddNoteIcon } from "../../icons";
import { useAppContext } from "../../libs/contextLib";
import { convertDateWithTandZ } from "../../utils/functions";

const Table = (props) => {
  const { t } = useTranslation()
  const { showFeatureDev } = useAppContext()
  const {
    reports,
    userProducts,
    totalReports,
    currentPage,
    apertureSerials,
    canAccessInstrumentTracking,
    isLoading,
    handleOpenAddNote,
    onChangeCurrentPage,
    setShowSpinner,
  } = props
  const [cycleDetails, setCycleDetails] = useState({})
  const [cycleDetailsDeviceData, setCycleDetailsDeviceData] = useState({})
  const [showCycleDetails, setShowCycleDetails] = useState(false)
  const reportsPerPage = 20
  const pageNumbers = []
  const isG4PlusAPIEnabled = process.env.REACT_APP_G4PLUS_API_TOGGLE === "on"

  for (let i = 1; i <= Math.ceil(totalReports / reportsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const pageNumbersToRender = [];

    const maxPages = 10;
    const totalPages = pageNumbers.length;
    const selectedPage = Number(currentPage);

    let rangeStart = selectedPage <= 2 ? 1 : selectedPage - 1;
    let rangeEnd = selectedPage <= 2
      ? totalPages < maxPages
        ? totalPages
        : maxPages
      : selectedPage + 8;

    if (totalPages - selectedPage < 7) {
      rangeStart = Math.max(1, totalPages - maxPages + 1);
      rangeEnd = totalPages;
    }

    if (rangeStart > 1) {
      pageNumbersToRender.push(
        <span key="start">
          ...
        </span>
      );
    }
    
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageNumbersToRender.push(
        <span
          key={i}
          className={selectedPage !== i ? "page-number" : "page-number-active"}
          id={i}
          onClick={handleClick}
        >
          {i}
        </span>
      );
    }

    if (rangeEnd < totalPages) {
      pageNumbersToRender.push(
        <span key="end">
          ...
        </span>
      );
    }

    return pageNumbersToRender;
  }

  function handleClick(event) {
    onChangeCurrentPage(event.target.id);
  }

  const openCyclePrintout = (item) => {
    if (isLoading) {
      return
    }
    
    setShowSpinner(true)

    if (!item?.printout_file_name?.length > 0 && !item?.records_uuid?.length > 0) {
      setShowSpinner(false)
      return
    }

    setCycleDetailsDeviceData({
      model: item?.model,
      cycleNumber: item?.cycle_number,
      cycleStartTime: item?.date,
      deviceSerialNumber: item?.machine_id,
    })
    
    if (showFeatureDev && item?.records_uuid && isG4PlusAPIEnabled) {
      getCycleDetails(item?.records_uuid, item?.machine_id)
        .then((res) => {
          setCycleDetails(res)
        
          setShowCycleDetails(true)
        })
        .finally(() => {
          setShowSpinner(false)
        })
    } else {
      if (apertureSerials?.includes(item?.machine_id) && !canAccessInstrumentTracking) {
        toast.error(t("error.forbidden"))

        return
      }
      
      getProductCyclePrintout(item?.printout_file_name)
        .then((res) => {
          const data = res.data
          if (!data || !data.serial_number || !data.model) {
            toast.error(t("report-modal.no_data"))

            return
          }

          setCycleDetails(data)
          setShowCycleDetails(true)
        })
        .catch((error) => {
          toast.error(t(`error.${error.response.data.code}`));
        })
        .finally(() => {
          setShowSpinner(false)
        })
    }
  }
  
  function normalizeValue(value) {
    if (value === null) {
      return '';
    }
    
    const lowercaseValue = value?.toLowerCase();
    if (lowercaseValue === 'n/a' || lowercaseValue === 'na') {
      return 'N/A';
    }
    return value;
  }

  const handleNewNote = (item) => {
    handleOpenAddNote(
      item?.machine_id, 
      item?.cycle_number, 
      item?.is_g4_plus, 
      item?.printout_file_name, 
      (item.date ? item.date : item?.isoDate), 
      item?.id,
      item?.model_id,
      item?.ped_source
    )
  }

  return (
    <div>
      <div>
      {reports?.map((r) => {
          if (r.date == null) {
            return {
              ...r,
              date: convertDateWithTandZ(r.isoDate).replace("  ", " "),
            }
          }
          return r
        })
        .sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        .map((item, index) => {
        return (
          <div key={index} className="reports-wrapper">
            <div className="table-mobile-view">
              <div className="table-head">
                <div className="table-date">
                  {showFeatureDev ? (item?.date) : ((item?.date)?.split(" ")[0])}
                </div>
                <div className="table-dots">
                  {userProducts[item.machine_id]?.association_active && (
                    <AddNoteIcon 
                      onClick={() => handleNewNote(item)} 
                    />)
                  }
                </div>
              </div>
              <div className="table-head">
                <span className="table-dots">
                  {item?.model}
                  {item?.machine_id || item?.deviceNickname}
                </span>
                <div onClick={() => openCyclePrintout(item)}>
                  <span
                    className={
                      "table-date cycle-number" + (!item?.printout_file_name?.length > 0 && !item?.records_uuid?.length > 0 ? " cycle-number-disabled" : "")
                    }
                  >
                    C# {item?.cycle_number}
                  </span>
                </div>
              </div>
              <hr />
              <div className="table-values-out">
                <b>{t("reports.cycle_parameters")}: </b>
                {item?.cycle_data?.cycle_name}
              </div>
              <div className="table-head parameters-wrapper">
                <span className="table-values">
                  <img src={HoldTimeIcon} alt="hold-time" /> 
                  {(item?.cycle_data?.hold_time === null  || !(parseFloat(item?.cycle_data?.hold_time) !== 0)) 
                    ? "N/A" 
                    : `${(item?.cycle_data?.hold_time)}${t(`reports.${item?.cycle_data?.hold_time_unit || "min"}`)}`
                  }
                </span>
                <span className="table-values">
                  <img src={TemperatureIcon} alt="temperature" />{" "}
                  {(item?.cycle_data?.hold_temperature === null || !(parseFloat(item?.cycle_data?.hold_temperature) !== 0))
                    ? "N/A"
                    : item?.cycle_data?.hold_temperature + "\u00B0" + (item?.cycle_data?.hold_temperature_unit ? item?.cycle_data?.hold_temperature_unit : "")}
                </span>
                {!item?.model.toLowerCase().includes("hydrim") && <span className="table-values">
                  <img src={PressureIcon} alt="pressure" />{" "}
                  {(item?.cycle_data?.hold_pressure === null || !(parseFloat(item?.cycle_data?.hold_pressure) !== 0))
                    ? "N/A"
                    : item?.cycle_data?.hold_pressure + (item?.cycle_data?.hold_pressure_unit ? item?.cycle_data?.hold_pressure_unit : "")}
                </span>}
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.contents")}</b>
                </span>
                <span className="right capitalize">
                  <div>
                    {Array.isArray(item?.general_contents_count) && item?.general_contents_count?.length > 0
                      ? item?.general_contents_count?.map((generalContent, index) => (
                          <ul className="max-width-cell" key={`${generalContent.contents}_${index}`} style={{ padding: 0, margin: 0 }}>
                            {generalContent.contents}: {generalContent.contents_count}
                          </ul>
                        ))
                      : item?.general_contents?.map((generalContent, index) => (
                          <ul className="max-width-cell" key={`${generalContent.contents}_${index}`} style={{ padding: 0, margin: 0 }}>
                            {generalContent}
                          </ul>
                        ))}
                  </div>
                </span>
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.printout_verification")}</b>
                </span>
                <span className="right capitalize">{item?.screen_readout === true ? t("labels.accept") :
                  item?.screen_readout === false ? t("labels.reject") :
                  item?.screen_readout === "" ? "" : ""}
                </span>
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.ci_pcd")}</b>
                </span>
                <span className="right capitalize">{normalizeValue(item?.ci_pcd)}</span>
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.external_ci")}</b>
                </span>
                <span className="right capitalize">{normalizeValue(item.hasOwnProperty("chemical_indicator") ? item?.chemical_indicator : item?.ci_external)}</span>
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.bi_test")}</b>
                </span>
                <span className="right capitalize">{normalizeValue(item?.bi_test)}</span>
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.test_cycles")}</b>
                </span>
                <span className="right capitalize">
                  {Object.keys(item?.test_cycle || {}).length === 0
                      ? null 
                      : Object.values(item.test_cycle).every(result => result === "") 
                        ? null 
                        : Object.values(item.test_cycle).some(result => result === "pass")
                          ? "pass" 
                          : Object.values(item.test_cycle).some(result => result === "fail")
                            ? "fail" 
                            : "N/A" 
                    }
                </span>
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.user_id")}</b>
                </span>
                <span className="right capitalize">
                  User Begin: {item?.initial?.user_begin}
                  <br /> User End: {item?.initial?.user_end}
                </span>
              </div>
              <div className="table-values-out">
                <span className="left">
                  <b>{t("reports.notes")}</b>
                </span>
                <span className="right capitalize">{item?.notes?.join(", ")}</span>
              </div>
            </div>
          </div>
        )
      })}
      </div>
      {reports?.length !== 0 &&
        <div className="page-numbers">
          <img src={ArrowLeft} alt="arrow-left" />
          <div
            className="prev-next"
            onClick={() => {
              onChangeCurrentPage(1);
            }}
          >
            First
          </div>
          <div className="num">{renderPageNumbers()}</div>
          <div
            className="prev-next"
            onClick={() => {
              onChangeCurrentPage(pageNumbers.length);
            }}
          >
            Last
          </div>
          <img src={ArrowRight} alt="arrow-right" />
        </div>
      }
      {showCycleDetails &&
        <CycleDetailsModal
          deviceData={cycleDetailsDeviceData}
          cycleDetails={cycleDetails}
          closeModal={() => {
            setShowCycleDetails(false)
            setCycleDetails({})
            setCycleDetailsDeviceData({})
          }}
        />
      }
    </div>
  );
};

export default Table;
