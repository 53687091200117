import { getGooglePlace, getGooglePlaceDetails } from "../utils/requests/searchAPI"
import {
  getStoreCheckoutLink,
  getStoreProducts,
  getUserOrders,
  getUserSubscriptions,
  getStoreProductById,
  getShippingAddress,
  getUserOrderById,
  getUserSubscriptionById,
  getUserOrderDocuments,
  getUserOrderDocumentS3Location,
  deleteUserSubscription,
  putSubscription,
} from "../utils/requests/productsAPI"
export const LOAD_STORE_PRODUCTS = "LOAD_STORE_PRODUCTS"
export const LOAD_STORE_PRODUCTS_PENDING = "LOAD_STORE_PRODUCTS_PENDING"
export const LOAD_STORE_PRODUCTS_FULFILLED = "LOAD_STORE_PRODUCTS_FULFILLED"
export const LOAD_STORE_PRODUCTS_REJECTED = "LOAD_STORE_PRODUCTS_REJECTED"

export const LOAD_STORE_NEW_PRODUCTS = "LOAD_STORE_NEW_PRODUCTS"
export const LOAD_STORE_NEW_PRODUCTS_PENDING = "LOAD_STORE_NEW_PRODUCTS_PENDING"
export const LOAD_STORE_NEW_PRODUCTS_FULFILLED = "LOAD_STORE_NEW_PRODUCTS_FULFILLED"
export const LOAD_STORE_NEW_PRODUCTS_REJECTED = "LOAD_STORE_NEW_PRODUCTS_REJECTED"

export const LOAD_SHIPPING_ADDRESS = "LOAD_SHIPPING_ADDRESS"
export const LOAD_SHIPPING_ADDRESS_PENDING = "LOAD_SHIPPING_ADDRESS_PENDING"
export const LOAD_SHIPPING_ADDRESS_FULFILLED = "LOAD_SHIPPING_ADDRESS_FULFILLED"
export const LOAD_SHIPPING_ADDRESS_REJECTED = "LOAD_SHIPPING_ADDRESS_REJECTED"

export const LOAD_GOOGLE_PLACE = "LOAD_GOOGLE_PLACE"
export const LOAD_GOOGLE_PLACE_PENDING = "LOAD_GOOGLE_PLACE_PENDING"
export const LOAD_GOOGLE_PLACE_FULFILLED = "LOAD_GOOGLE_PLACE_FULFILLED"
export const LOAD_GOOGLE_PLACE_REJECTED = "LOAD_GOOGLE_PLACE_REJECTED"

export const LOAD_GOOGLE_PLACE_DETAILS = "LOAD_GOOGLE_PLACE_DETAILS"
export const LOAD_GOOGLE_PLACE_DETAILS_PENDING = "LOAD_GOOGLE_PLACE_DETAILS_PENDING"
export const LOAD_GOOGLE_PLACE_DETAILS_FULFILLED = "LOAD_GOOGLE_PLACE_DETAILS_FULFILLED"
export const LOAD_GOOGLE_PLACE_DETAILS_REJECTED = "LOAD_GOOGLE_PLACE_DETAILS_REJECTED"

export const LOAD_USER_ORDERS = "LOAD_USER_ORDERS"
export const LOAD_USER_ORDERS_PENDING = "LOAD_USER_ORDERS_PENDING"
export const LOAD_USER_ORDERS_FULFILLED = "LOAD_USER_ORDERS_FULFILLED"
export const LOAD_USER_ORDERS_REJECTED = "LOAD_USER_ORDERS_REJECTED"

export const LOAD_NEW_USER_ORDERS = "LOAD_NEW_USER_ORDERS"
export const LOAD_NEW_USER_ORDERS_PENDING = "LOAD_NEW_USER_ORDERS_PENDING"
export const LOAD_NEW_USER_ORDERS_FULFILLED = "LOAD_NEW_USER_ORDERS_FULFILLED"
export const LOAD_NEW_USER_ORDERS_REJECTED = "LOAD_NEW_USER_ORDERS_REJECTED"

export const LOAD_USER_ORDER_BY_ID = "LOAD_USER_ORDER_BY_ID"
export const LOAD_USER_ORDER_BY_ID_PENDING = "LOAD_USER_ORDER_BY_ID_PENDING"
export const LOAD_USER_ORDER_BY_ID_FULFILLED = "LOAD_USER_ORDER_BY_ID_FULFILLED"
export const LOAD_USER_ORDER_BY_ID_REJECTED = "LOAD_USER_ORDER_BY_ID_REJECTED"

export const LOAD_USER_ORDER_DOCUMENTS = "LOAD_USER_ORDER_DOCUMENTS"
export const LOAD_USER_ORDER_DOCUMENTS_PENDING = "LOAD_USER_ORDER_DOCUMENTS_PENDING"
export const LOAD_USER_ORDER_DOCUMENTS_FULFILLED = "LOAD_USER_ORDER_DOCUMENTS_FULFILLED"
export const LOAD_USER_ORDER_DOCUMENTS_REJECTED = "LOAD_USER_ORDER_DOCUMENTS_REJECTED"

export const LOAD_USER_ORDER_DOCUMENT_S3_LOCATION = "LOAD_USER_ORDER_DOCUMENT_S3_LOCATION"
export const LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_PENDING = "LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_PENDING"
export const LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_FULFILLED = "LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_FULFILLED"
export const LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_REJECTED = "LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_REJECTED"

export const LOAD_USER_SUBSCRIPTIONS = "LOAD_USER_SUBSCRIPTIONS"
export const LOAD_USER_SUBSCRIPTIONS_PENDING = "LOAD_USER_SUBSCRIPTIONS_PENDING"
export const LOAD_USER_SUBSCRIPTIONS_FULFILLED = "LOAD_USER_SUBSCRIPTIONS_FULFILLED"
export const LOAD_USER_SUBSCRIPTIONS_REJECTED = "LOAD_USER_SUBSCRIPTIONS_REJECTED"

export const LOAD_USER_SUBSCRIPTION_BY_ID = "LOAD_USER_SUBSCRIPTION_BY_ID"
export const LOAD_USER_SUBSCRIPTION_BY_ID_PENDING = "LOAD_USER_SUBSCRIPTION_BY_ID_PENDING"
export const LOAD_USER_SUBSCRIPTION_BY_ID_FULFILLED = "LOAD_USER_SUBSCRIPTION_BY_ID_FULFILLED"
export const LOAD_USER_SUBSCRIPTION_BY_ID_REJECTED = "LOAD_USER_SUBSCRIPTION_BY_ID_REJECTED"

export const SET_IN_BASKET = "SET_IN_BASKET"
export const CLEAR_BASKET = "CLEAR_BASKET"

export const SET_CHECKOUT_LINK = "SET_CHECKOUT_LINK"
export const SET_CHECKOUT_LINK_PENDING = "SET_CHECKOUT_LINK_PENDING"
export const SET_CHECKOUT_LINK_FULFILLED = "SET_CHECKOUT_LINK_FULFILLED"
export const SET_CHECKOUT_LINK_REJECTED = "SET_CHECKOUT_LINK_REJECTED"

export const SET_CHECKOUT_SESSION = "SET_CHECKOUT_SESSION"

export const LOAD_PRODUCT_DETAILS = "LOAD_PRODUCT_DETAILS"
export const LOAD_PRODUCT_DETAILS_PENDING = "LOAD_PRODUCT_DETAILS_PENDING"
export const LOAD_PRODUCT_DETAILS_FULFILLED = "LOAD_PRODUCT_DETAILS_FULFILLED"
export const LOAD_PRODUCT_DETAILS_REJECTED = "LOAD_PRODUCT_DETAILS_REJECTED"

export const LOAD_USER_SUBSCRIPTION_DETAILS = "LOAD_USER_SUBSCRIPTION_DETAILS"
export const LOAD_USER_SUBSCRIPTION_DETAILS_PENDING = "LOAD_USER_SUBSCRIPTION_DETAILS_PENDING"
export const LOAD_USER_SUBSCRIPTION_DETAILS_FULFILLED = "LOAD_USER_SUBSCRIPTION_DETAILS_FULFILLED"
export const LOAD_USER_SUBSCRIPTION_DETAILS_REJECTED = "LOAD_USER_SUBSCRIPTION_DETAILS_REJECTED"

export const DELETE_USER_SUBSCRIPTION = "DELETE_USER_SUBSCRIPTION"
export const DELETE_USER_SUBSCRIPTION_PENDING = "DELETE_USER_SUBSCRIPTION_PENDING"
export const DELETE_USER_SUBSCRIPTION_FULFILLED = "DELETE_USER_SUBSCRIPTION_FULFILLED"
export const DELETE_USER_SUBSCRIPTION_REJECTED = "DELETE_USER_SUBSCRIPTION_REJECTED"

export const RENEW_SUBSCRIPTION = "RENEW_SUBSCRIPTION"
export const RENEW_SUBSCRIPTION_PENDING = "RENEW_SUBSCRIPTION_PENDING"
export const RENEW_SUBSCRIPTION_FULFILLED = "RENEW_SUBSCRIPTION_FULFILLED"
export const RENEW_SUBSCRIPTION_REJECTED = "RENEW_SUBSCRIPTION_REJECTED"

export const LOAD_STORE_PRODUCT_BY_ID = "LOAD_STORE_PRODUCT_BY_ID"
export const LOAD_STORE_PRODUCT_BY_ID_PENDING = "LOAD_STORE_PRODUCT_BY_ID_PENDING"
export const LOAD_STORE_PRODUCT_BY_ID_FULFILLED = "LOAD_STORE_PRODUCT_BY_ID_FULFILLED"
export const LOAD_STORE_PRODUCT_BY_ID_REJECTED = "LOAD_STORE_PRODUCT_BY_ID_REJECTED"

export const UPDATE_REFUND_ORDER = "UPDATE_REFUND_ORDER"

export const CLEAR_STORE_MESSAGES = "CLEAR_STORE_MESSAGES"
export const CLEAR_STORE_PRODUCTS = "CLEAR_STORE_PRODUCTS"

export function loadStoreProducts(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_STORE_NEW_PRODUCTS : LOAD_STORE_PRODUCTS,
    payload: getStoreProducts(queryParams),
  }
}

export function loadShippingAddress() {
  return {
    type: LOAD_SHIPPING_ADDRESS,
    payload: getShippingAddress(),
  }
}

export function loadGooglePlace(queryParams) {
  return {
    type: LOAD_GOOGLE_PLACE,
    payload: getGooglePlace(queryParams),
  }
}

export function loadGooglePlaceDetails(queryParams) {
  return {
    type: LOAD_GOOGLE_PLACE_DETAILS,
    payload: getGooglePlaceDetails(queryParams),
  }
}

export function loadStoreProductById(id) {
  return {
    type: LOAD_STORE_PRODUCT_BY_ID,
    payload: getStoreProductById(id),
  }
}

export function loadUserOrders(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_USER_ORDERS : LOAD_USER_ORDERS,
    payload: getUserOrders(queryParams),
  }
}

export function loadUserOrderById(id) {
  return {
    type: LOAD_USER_ORDER_BY_ID,
    payload: getUserOrderById(id),
  }
}

export function loadUserOrderDocuments(id) {
  return {
    type: LOAD_USER_ORDER_DOCUMENTS,
    payload: getUserOrderDocuments(id),
  }
}

export function loadUserOrderDocumentS3Location(orderId, documentId) {
  return {
    type: LOAD_USER_ORDER_DOCUMENT_S3_LOCATION,
    payload: getUserOrderDocumentS3Location(orderId, documentId),
  }
}

export function loadUserSubscriptions(queryParams) {
  return {
    type: LOAD_USER_SUBSCRIPTIONS,
    payload: getUserSubscriptions(queryParams),
  }
}

export function loadUserSubscriptionById(id) {
  return {
    type: LOAD_USER_SUBSCRIPTION_BY_ID,
    payload: getUserSubscriptionById(id),
  }
}

export function deleteSubscription(id, isTestDriveSubscription) {
  return {
    type: DELETE_USER_SUBSCRIPTION,
    payload: deleteUserSubscription(id),
    meta: { id, isTestDriveSubscription },
  }
}

export function renewSubscription(id, bodyParams){
  return{
    type: RENEW_SUBSCRIPTION,
    payload: putSubscription(id, bodyParams),
    meta: { id: id },
  }
}

export function setInBasket(product, quantity) {
  return {
    type: SET_IN_BASKET,
    payload: { ...product, quantity: quantity },
  }
}

export function clearBasket() {
  return {
    type: CLEAR_BASKET,
  }
}

export function handleClearBasket() {
  return (dispatch) => {
    return dispatch(clearBasket())
  }
}

export function generateCheckoutLink(basket, shippingAddress, saveAddress, billingSameAsShipping) {
  const body = Object.keys(basket).map((key) => {
    return {
      priceId: basket[key].prices.length > 1 ? basket[key].prices.find((price) => price.isSelectedPlan).id : basket[key].prices[0].id,
      quantity: basket[key].quantity,
    }
  })
  return {
    type: SET_CHECKOUT_LINK,
    payload: getStoreCheckoutLink({
      items: body,
      shippingAddress: shippingAddress,
      saveShippingAddress: saveAddress,
      useShippingAddressForBillingAddress: billingSameAsShipping,
      successUrl: window.location.origin + "/coltene-store?order_status=successful",
      cancelUrl: window.location.origin + "/coltene-store",
    }),
  }
}

export function loadCheckoutSession(session) {
  return {
    type: SET_CHECKOUT_SESSION,
    payload: session,
  }
}

export function requestOrderRefund(id) {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_REFUND_ORDER,
      id,
    })
  }
}

export function clearStoreMessages() {
  return {
    type: CLEAR_STORE_MESSAGES,
  }
}
