import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useHistory } from 'react-router-dom'
import jsPDF from "jspdf"
import html2canvas from 'html2canvas'
import CycleDetailsGraph from "./CycleDetailsGraph"
import LoadingSpinner from "../LoadingSpinner"
import SimpleButton from "../SimpleButton"
import PageModal from "../PageModal"
import EmptyPageResults from "../EmptyPageResults"
import { toast } from "../Toast"
import { getProductCycleDetails, getProductCycles } from "../../../utils/requests/productsAPI"
import { getCycleDetails, getMQTTCycles } from '../../../utils/requests/trackingAPI'
import { getImageByModel } from "../../../utils/functions"
import { convertTimeWithTAndZ, convertToSimpleDateWithFullTime } from "../../../utils/filters/date"
import { hasEndUserSiteAccess } from "../../../utils/permissionValidation"
import { useWindowSize } from "../../../libs/hooks"
import { useAppContext } from "../../../libs/contextLib"
import { DownloadIcon } from "../../../icons"
import './cycle-details-modal.scss'

function CycleDetailsModal(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const { showFeatureDev } = useAppContext()
  const isMobile = useWindowSize()[0] <= 768
  const { isG4Plus, closeModal, hasCycleNavigation, isInstrumentTrackingCycleData, selectedModel, handleExternalNavigationToTroubleshooting } = props
  const [deviceData, setDeviceData] = useState(props.deviceData)
  const [cycleDetails, setCycleDetails] = useState(props.cycleDetails)
  const [hasChartData, setHasChartData] = useState(false)
  const [hasSummaryData, setHasSummaryData] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [cycles, setCycles] = useState([])
  const [currentCycleIndex, setCurrentCycleIndex] = useState(0)
  const [navigateMonths, setNavigateMonths] = useState(0)
  const isEndUser = hasEndUserSiteAccess(props.userPermissions)
  const modelId = Object.values(props.productTypes).map(productType => productType.models).flat().find(modelName => modelName.name === props.deviceData.model)?.id
  const isG4PlusAPIEnabled = process.env.REACT_APP_G4PLUS_API_TOGGLE === "on"

  useEffect(() => {
    if (hasCycleNavigation) {
      setShowSpinner(true)

      let fetchCyclesPromise

      if (showFeatureDev && isG4Plus && isG4PlusAPIEnabled) {
        fetchCyclesPromise = getMQTTCycles(deviceData.deviceSerialNumber, {
          "cycleStartDate": getStartDate().replace(/-/g, ""),
          "cycleEndDate": getEndDate().replace(/-/g, ""),
        })
      } else {
        fetchCyclesPromise = getProductCycles(deviceData.deviceSerialNumber, {
          "start_date": getStartDate(),
          "end_date": getEndDate(),
        })
      }

      fetchCyclesPromise
        .then((res) => {
          setShowSpinner(false)

          const orderedCycles = orderCycles(res)

          setCycles(orderedCycles)

          for (let idx = 0; idx < orderedCycles.length; idx++) {
            if ((deviceData.cid && orderedCycles[idx].cid === deviceData.cid) || (!deviceData.cid && orderedCycles[idx].cycleNumber === deviceData.cycleNumber)) {
              setCurrentCycleIndex(idx)
              break
            }
          }
        })
        .catch((error) => {
          setShowSpinner(false)

          toast.error(t([`error.${error?.response?.data?.code}`, "error.something_wrong"]))
        })
    }
  },[])

  useEffect(() => {
    let hasTempChamberValues = false
    let hasPressureValues = false
    let hasSummaryObject = false

    if (cycleDetails) {
      hasTempChamberValues = cycleDetails.chart?.temperature_chamber?.length > 0 || cycleDetails?.chart?.temp?.length > 0
      hasPressureValues = cycleDetails.chart?.pressure?.length > 0 || cycleDetails?.chart?.press?.length > 0
      hasSummaryObject = (cycleDetails.summary_object && Object.keys(cycleDetails.summary_object).length > 0) || cycleDetails.summary?.length > 0
    }

    if (hasTempChamberValues || hasPressureValues) {
      setHasChartData(true)
    }
      
    if (hasSummaryObject) {
      setHasSummaryData(true)
    }
  }, [cycleDetails])

  const cycleDetailsHeaderContent = () => {
    return (
      <>
        <button className="download-cycle-details-wrapper" onClick={() => handlePrintReport()}>
          <DownloadIcon/>
          {t("download.download")}
        </button>
      </>
    )
  }

  const cycleDetailsFooterContent = () => {
    return (
      <>
        {hasCycleNavigation &&
          <div className="cycles-navigation-wrapper">
            <SimpleButton
              className="cancel-button"
              onClick={() => fetchSpecificSelectedCycle(currentCycleIndex - 1)}
            >
              {t('previous')} {t('printouts.cycle-title')}
            </SimpleButton>
            <SimpleButton
              className="submit-button"
              onClick={() => fetchSpecificSelectedCycle(currentCycleIndex + 1)}
              disabled={!canFetchNextCycle()}
            >
              {t('register-next')} {t('printouts.cycle-title')}
            </SimpleButton>
          </div>
        }
      </>
    )
  }

  const orderCycles = (cycles) => {
    const orderedCycles = cycles.data?.sort((a, b) => {
      const dateA = new Date(a.start.split(' ')[0])
      const dateB = new Date(b.start.split(' ')[0])
      
      const dateComparison = dateA - dateB
      if (dateComparison !== 0) {
        return dateComparison
      }
    
      return a.cycle_number - b.cycle_number
    }) || cycles.sort((a, b) => {
      const dateA = new Date(a.cycleStartDateTime.split(' ')[0]);
      const dateB = new Date(b.cycleStartDateTime.split(' ')[0]);
      
      const dateComparison = dateA - dateB;
      if (dateComparison !== 0) {
        return dateComparison;
      }
    
      return a.cycleNumber - b.cycleNumber
    })

    return orderedCycles
  }

  const chartData = () => {
    const temp = cycleDetails?.chart?.temperature_chamber?.map(it => Number(it))  || cycleDetails?.chart?.temp?.map(it => Number(it))

    const chartTemp2 = cycleDetails?.chart?.temp2 || []
    const temp2 = chartTemp2?.map(it => Number(it)) || []

    const selectedCyclePressures = cycleDetails?.chart?.press || cycleDetails?.chart?.pressure
    const pressures = selectedCyclePressures?.map(it => Number(it)) || []

    let graphData = []
    const lastVal = pressures[pressures.length - 1]
    const tempLastVal = temp2[temp2.length - 1]

    temp?.forEach((item, index) => {
      graphData.push({
        temp: item,
        temp2: temp2.length > 0 ? (temp2[index] !== undefined ? temp2[index] : tempLastVal)  : null,
        pressure: pressures.length > 0 ? (pressures[index] !== undefined ? pressures[index] : lastVal) : null
      })
    })

    return graphData
  }

  const getCycleStatus = (status) => {
    let mappedStatus = "in_progress"

    if (status?.toLowerCase()?.includes('complete') || status?.toLowerCase() === 'cycle_stop') {
      mappedStatus = "completed"
    }

    if (status?.toLowerCase() === 'cycle_power_interrupt') {
      mappedStatus = "cycle_interrupted"
    }

    if (status?.toLowerCase()?.includes('fault') || status?.toLowerCase() === 'cycle_aborted' || status?.toLowerCase() === 'door_open') {
      mappedStatus = "error"
    }

    return mappedStatus
  }

  const handlePrintReport = () => {
    html2canvas(document.querySelector("#cycle-details-modal-content")).then(canvas => {
      canvas.className = "instrument-tracking-download-circle"
      document.body.appendChild(canvas)
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF("p", "mm", "a4")

      const width = pdf.internal.pageSize.getWidth()
      const height = pdf.internal.pageSize.getHeight()
      pdf.addImage(imgData, 'PNG', 0, 0, width, height * 0.75)
      pdf.save(`${deviceData.model.replace(/\s+/g, '_')}_${deviceData.cycleNumber}_${deviceData.cycleStartTime}.pdf`)
    })
  }

  const handleCloseModal = () => {
    closeModal()
  }

  const buildDate = (year, month, isStartDate) => {
    if (month === 2) {
      return `${year}-${month < 10 ? `0${month}`: `${month}`}-${isStartDate ? "01" : year % 4 !== 0 ? "28" : "29"}`
    } 
    if (month === 4 || month === 6 || month === 9 || month === 11 ) {
      return `${year}-${month < 10 ? `0${month}`: `${month}`}-${isStartDate ? "01" : "30"}`
    }
 
    return `${year}-${month < 10 ? `0${month}`: `${month}`}-${isStartDate ? "01" : "31"}`
  }

  const getEndDate = () => {
    const date = deviceData.cycleStartTime?.split(" ")[0]
    const month = parseInt(date.split("-")[1])
    const year = parseInt(date.split("-")[0])

    return buildDate(year, month, false)
  }

  const getStartDate = () => {
    const date = deviceData.cycleStartTime?.split(" ")[0]
    const month = parseInt(date.split("-")[1])
    const year = parseInt(date.split("-")[0])
    
    return buildDate(year, month, true)
  }

  const changeMonth = (monthNumber, date, isStartDate) => {
    let year = (parseInt(date.split("-")[0]))
    let month = (parseInt(date.split("-")[1]) + parseInt(`${monthNumber}`))

     if (month > 12) {
      year = year + Math.floor(month / 12)
      month = month % 12
    }

    if (month < 1) {
      const yearsToSubtract = Math.ceil(Math.abs(month) / 12) || 1
      year = year - yearsToSubtract
      month = 12 - (Math.abs(month) % 12)

      if (month === 0) {
        month = 12
      }
    }
    
    return buildDate(year, month, isStartDate)
  }

  const fetchSpecificSelectedCycle = async (value) => {
    setShowSpinner(true)
    try {
      let cycleIndex = value
      let orderedCycles = cycles
      let shouldNavigateMonths = false

      if (value < 0 || value > cycles.length - 1) {
        let fetchCyclesPromise

        const startDate = changeMonth(value < 0 ? navigateMonths - 1 : navigateMonths + 1, deviceData.cycleStartTime, true)
        const endDate = changeMonth(value < 0 ? navigateMonths - 1 : navigateMonths + 1, deviceData.cycleStartTime, false)

        if (showFeatureDev && isG4Plus && isG4PlusAPIEnabled) {
          fetchCyclesPromise = getMQTTCycles(deviceData.deviceSerialNumber, {
            "cycleStartDate": startDate.replace(/-/g, ""),
            "cycleEndDate": endDate.replace(/-/g, ""),
          })
        } else {
          fetchCyclesPromise = getProductCycles(deviceData.deviceSerialNumber, {
            "start_date": startDate,
            "end_date": endDate,
          })
        }

        const cyclesResponse = await fetchCyclesPromise

        orderedCycles = orderCycles(cyclesResponse)

        cycleIndex = value < 0 ? orderedCycles.length - 1 : 0
        setNavigateMonths(prev => value < 0 ? prev - 1 : prev + 1)
        
        if (orderedCycles.length === 0) {
          shouldNavigateMonths = true
        }
      }

      if (orderedCycles.length === 0) {
        setShowSpinner(false)
        
        return
      }

      if (!shouldNavigateMonths) {
        setNavigateMonths(0)
      }

      let fetchCycleDetailsPromise

      if (showFeatureDev && isG4Plus && isG4PlusAPIEnabled) {
        fetchCycleDetailsPromise = getCycleDetails(orderedCycles[cycleIndex].recordsUuid, deviceData.deviceSerialNumber)
      } else {
        fetchCycleDetailsPromise = getProductCycleDetails(deviceData.deviceSerialNumber, orderedCycles[cycleIndex].cid, { model_id: modelId })
      }

      const cycleResponse = await fetchCycleDetailsPromise

      setDeviceData({
        ...deviceData,
        cycleFault: orderedCycles[cycleIndex].cycle_fault || orderedCycles[cycleIndex].cycleFault,
        cycleNumber: orderedCycles[cycleIndex].cycle_number || orderedCycles[cycleIndex].cycleNumber,
        cycleStartTime: orderedCycles[cycleIndex].start || orderedCycles[cycleIndex].cycleStartDateTime,
      })

      setCycleDetails(cycleResponse.data || cycleResponse || {})
      
      setCycles(orderedCycles)

      setCurrentCycleIndex(cycleIndex)

      setShowSpinner(false)
    } catch {
      setShowSpinner(false)
    }
  }

  const canFetchNextCycle = () => {
    if (currentCycleIndex < cycles.length - 1) {
      return true
    }

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1

    const searchedYear = Number(deviceData.cycleStartTime?.split("-")[0])
    const searchedMonth = Number(deviceData.cycleStartTime?.split("-")[1])

    if (searchedYear < currentYear) {
      return true
    }

    return searchedMonth < currentMonth
  }

  const navigateToTroubleshoot = () => {
    if (handleExternalNavigationToTroubleshooting) {
      handleExternalNavigationToTroubleshooting()
    }

    history.push({
      pathname: `/troubleshoot`,
      state: {
        model: selectedModel || deviceData.model,
        cycleFault: deviceData.cycleFault < 10 ? `0${deviceData.cycleFault}` : `${deviceData.cycleFault}`,
        withBackNavigation: true,
      }
    })
  }

  return (
    <PageModal
      toggle
      className={"cycle-details-modal" + (!hasCycleNavigation ? " without-footer" : "")}
      onToggle={() => handleCloseModal()}
      headerContent={cycleDetailsHeaderContent()}
      footerContent={cycleDetailsFooterContent()}
    >
      {!props.isLoading && cycleDetails &&
        <div 
          className="cycle-details-modal-content" 
          id="cycle-details-modal-content"
        >
          {!isMobile &&
            <div className="device-info-wrapper">
              <img
                src={getImageByModel(selectedModel || deviceData.model)}
                className="product-image"
                alt={selectedModel}
              />
        
              <div className="product-identification-wrapper">
                <h1 className="product-name">{deviceData.model?.toLowerCase()?.includes('aperture') ? t(`products.ic_track`) : deviceData.model}</h1>
                <p className="product-serial-number">{t('report-modal.serial_number')}: {deviceData.deviceSerialNumber}</p>
                <div className="cycle-time-wrapper">
                  <div className="product-date d-flex flex-column">
                    <h3>{t("report-modal.date")}</h3>
                    {deviceData && deviceData.cycleStartTime?.includes("T") ? (
                      <p>{convertTimeWithTAndZ(deviceData.cycleStartTime).split(" ")[0]}</p>
                    ) : (
                      <p>{convertToSimpleDateWithFullTime(deviceData.cycleStartTime).split(" ")[0]}</p>
                    )}
                  </div>
                  <div className="vertical-separator"/>
                  <div className="product-time d-flex flex-column">
                    <h3>{t("report-modal.time")}</h3>
                    {deviceData.cycleStartTime?.includes("T") ? (
                      <p>
                        {convertTimeWithTAndZ(deviceData.cycleStartTime).split(" ")[1]}
                      </p>
                    ) : (
                      <>
                        {deviceData.cycleStartTime.split(" ")[1] !== "00:00:00" && (
                          <p>
                            {convertToSimpleDateWithFullTime(deviceData.cycleStartTime).split(" ")[1]}
                          </p>
                        )}
                      </>)
                    }
                  </div>
                  <div className="vertical-separator"/>
                  <div className="product-cycle-number d-flex flex-column">
                    <h3>{t('report-modal.cycle_number')}</h3>
                    <p>{deviceData.cycleNumber}</p>
                  </div>
                  {/* {isInstrumentTrackingCycleData && cycleDetails.status?.length > 0 &&
                    <>
                      <div className="vertical-separator"/>
                      <div className="cycle-status d-flex flex-column">
                        <h3>{t("tracking.status")}</h3>
                        <p>{t([`tracking.${getCycleStatus(cycleDetails.status)}`, ""])}</p>
                      </div>
                    </>
                  } */}
                 {isEndUser && deviceData.cycleFault && (
                    <SimpleButton
                      className="action-button troubleshoot-button"
                      onClick={() => navigateToTroubleshoot()}
                    >
                      {t('report-modal.troubleshoot')}
                    </SimpleButton>
                  )}
                </div>
              </div>
            </div>
          }
          {isMobile &&
            <div className="device-info-wrapper">
              <div className="image-mobile-wrapper">
                <img 
                  className="product-image" 
                  src={getImageByModel(selectedModel || deviceData.model)}
                  alt={selectedModel}
                />
                <div className="product-name-wrapper">
                  <h1 className="product-name">{deviceData.model?.toLowerCase()?.includes('aperture') ? t(`products.ic_track`) : deviceData.model}</h1>
                  <p className="product-serial-number">{t('report-modal.serial_number')}: {deviceData.deviceSerialNumber}</p>
                </div>
              </div>          
              <div className="product-details-mobile-wrapper">
                <div className="product-date-mobile">
                  <h3>{t('report-modal.date')}</h3>
                  <p>{convertToSimpleDateWithFullTime(new Date(deviceData.cycleStartTime))?.split(" ")[0]}</p>
                </div>
                <div className="product-time-mobile">
                  <h3>{t('report-modal.time')}</h3>
                  {deviceData.cycleStartTime?.includes("T") ? (
                    <p>
                      {convertTimeWithTAndZ(deviceData.cycleStartTime).split(" ")[1]}
                    </p>
                  ) : (
                    <>
                      {deviceData.cycleStartTime.split(" ")[1] !== "00:00:00" && (
                        <p>
                          {convertToSimpleDateWithFullTime(deviceData.cycleStartTime).split(" ")[1]}
                        </p>
                      )}
                    </>)
                  }
                </div>
              </div>
              <div className="product-details-mobile-wrapper with-margin-top">
                <div className="product-cycle-number">
                  <h3>{t('report-modal.cycle_number')}</h3>
                  <p>{deviceData.cycleNumber}</p>
                </div>
                {/* {isInstrumentTrackingCycleData && cycleDetails.status?.length > 0 &&
                  <>
                    <div className="cycle-status">
                      <h3>{t("tracking.status")}</h3>
                      <p>{t([`tracking.${getCycleStatus(cycleDetails.status)}`, ""])}</p>
                    </div>
                  </>
                } */}
                {isEndUser && deviceData.cycleFault && (
                  <SimpleButton
                    className="action-button troubleshoot-button"
                    onClick={() => navigateToTroubleshoot()}
                  >
                    {t('report-modal.troubleshoot')}
                  </SimpleButton>
                )}
              </div>
            </div>
          }

          <div className={"report-graph" + ((hasChartData || hasSummaryData) ? "" : " no-data")}>
            {hasChartData || hasSummaryData ?
              <>
                {cycleDetails.chart && (
                  <CycleDetailsGraph
                    data={chartData()}
                    temperatureUnit={cycleDetails.temperature_unit}
                    pressureUnit={cycleDetails.pressure_unit}
                  />
                )}
              </>
            :
              <EmptyPageResults
                className="no-cycle-data"
                title={t('report-modal.no_data')}
              />
            }
          </div>

          <div className={`extra-info ${cycleDetails.summary_object ? 'summary-object' : ''}`}>
            {(hasChartData || hasSummaryData) &&
              <>
                {cycleDetails.summary_object && Object.keys(cycleDetails.summary_object).length > 0 ? 
                  <div>
                    <div><strong>{ t('summary.machine_model') }: </strong> {cycleDetails.summary_object.machine_model}</div>
                    <div><strong>{ t('summary.serial_number') }: </strong> {deviceData.deviceSerialNumber}</div>
                    <div><strong>{ t('summary.fw_version') }: </strong> {cycleDetails.summary_object.fw_version}</div>
                    <div><strong>{ t('summary.current_cycle') }: </strong> { cycleDetails.summary_object.current_cycle}</div>
                    <div><strong>{ t('summary.cycle_counter') }: </strong> { cycleDetails.summary_object.cycle_counter}</div>
                    <div><strong>{ t('summary.program') }: </strong> { cycleDetails.summary_object.program}</div>
                    <div><strong>{ t('summary.temperature') }: </strong> { cycleDetails.summary_object.temperature}</div>
                    <div><strong>{ t('summary.pressure') }: </strong> { cycleDetails.summary_object.pressure}</div>
                    <div><strong>{ t('summary.process_time') }: </strong> { cycleDetails.summary_object.process_time}</div>
                    <div><strong>{ t('summary.stand_by') }: </strong> { cycleDetails.summary_object.stand_by}</div>
                    <div><strong>{ t('summary.pre_vacuum') }: </strong> { cycleDetails.summary_object['pre-vacuum']}</div>
                    <div><strong>{ t('summary.drying_time') }: </strong> { cycleDetails.summary_object.drying_time}</div>
                    <div><strong>{ t('summary.water_measure') }: </strong> { cycleDetails.summary_object.water_measure}</div>
                    <div><strong>{ t('summary.cycle_start') }: </strong> { cycleDetails.summary_object.cycle_start}</div>
                    <div className="title"><strong>{ t('summary.time_temperature_pressure') }: </strong>
                      {
                        cycleDetails.summary_object.time_temperature_pressure &&
                        cycleDetails.summary_object.time_temperature_pressure.map((item, index) => (
                            <div key={index}>{ item.time } &nbsp; {item.tempe} &nbsp; {item.pressure}</div>
                        ))
                      }
                    </div>
                    <div>
                      <strong className="green">{ t('summary.max_temp') }: </strong>
                      {
                          cycleDetails.summary_object?.max_temp &&
                          (` ${cycleDetails.summary_object.max_temp?.time} ${cycleDetails.summary_object.max_temp?.temp}`)
                      }
                    </div>
                    <div>
                      <strong className="blue">{ t('summary.min_temp') }: </strong>
                      {
                          cycleDetails.summary_object.min_temp &&
                          (` ${cycleDetails.summary_object.min_temp?.time} ${cycleDetails.summary_object.min_temp?.temp}`)
                      }
                    </div>
                    <div><strong>{ t('summary.dry_pulse') }: </strong> {cycleDetails.summary_object.dry_pulse}</div>
                    <div><strong>{ t('summary.cycle_end') }: </strong> {cycleDetails.summary_object.cycle_end}</div>
                    <div><strong>{ t('summary.cycle_result') }: </strong> {cycleDetails.summary_object.cycle_result}</div>
                    <div><strong>{ t('summary.end_operator') }: </strong> {cycleDetails.summary_object.end_operator}</div>
                    <div><strong>{ t('summary.cycle_error') }: </strong> {cycleDetails.summary_object.cycle_error}</div>
                  </div>
              :
                cycleDetails.summary && (cycleDetails.summary.map((item, index) => 
                  <div key={index} className="summary-bold">
                    {item}
                  </div>)
                )
              }
            </>
          }
          </div>
        </div>
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </PageModal>
  )
}

function mapStateToProps({ productTypes, userPermissions }) {
  return {
    productTypes,
    userPermissions,
  }
}

export default connect(mapStateToProps)(CycleDetailsModal)
